import axios from 'axios';
import { SimpleGrid, Flex, FormControl, FormLabel, Input, Button, useToast, Text, Checkbox } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import {  TimeStats } from '../../enums/UsageStats';

const Login = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const serverUrl = process.env.REACT_APP_API_URL;
    const [showTwoFactor, setShowTwoFactor] = useState(false);
    const [twoFactorCode, setTwoFactorCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rememberDevice, setRememberDevice] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const deviceToken = Cookies.get('deviceToken');
            const res = await axios.post(`${serverUrl}/login`, { 
                email, 
                password,
                deviceToken
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (res.data.user?.settings?.twoFactorEnabled && 
                res.data.user?.settings?.twoFactorPhone && 
                res.data.user?.settings?.twoFactorPhoneVerified &&
                !res.data.deviceVerified) {
                
                await axios.post(`${serverUrl}/api/verify-two-factor`, {
                    phone: res.data.user.settings.twoFactorPhone
                }, {
                    headers: {
                        'Authorization': `Bearer ${res.data.token}`
                    }
                });

                setShowTwoFactor(true);
                Cookies.set('jwtToken', res.data.token);
                setIsLoading(false);
                return;
            }

            Cookies.set('jwtToken', res.data.token);
            window.location.href = '/';
        } catch (error) {
            setIsLoading(false);
            if (error.response?.data?.info?.message) {
                toast({
                    title: "Error",
                    description: error.response.data.info.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                setError('An error occurred. Please try again.');
            }
            console.error(error);
        }
    };

    const verifyTwoFactor = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${serverUrl}/api/verify-two-factor-code`, {
                code: twoFactorCode,
                rememberDevice
            }, {
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            });

            if (rememberDevice && response.data.deviceToken) {
                Cookies.set('deviceToken', response.data.deviceToken, { expires: 30 });
            }

            // Log the login
            await axios.post(`${serverUrl}/api/usage/log`, {
                statPath: TimeStats.MONTHLY_LOGIN_COUNT,
                value: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            });

            window.location.href = '/';
        } catch (error) {
            setIsLoading(false);
            toast({
                title: "Error",
                description: error.response?.data?.error || "Invalid verification code",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleMicrosoftLogin = () => {
        window.location.href = `${serverUrl}/auth/microsoft`;
    };

    return (
        <SimpleGrid columns={2} spacing={0} width={"100%"} height={"100%"} data-simple className="ma-0 pa-0">
            <Flex justifyContent={"center"} alignItems={"center"}
                flexDirection={"column"} width={"100%"} height={"100%"}
                >
                   <div className="h-100" style={{
                    maxWidth: "450px"
                   }}>
                        <div style={{color: '#2B3674', fontSize: 36, fontWeight: '700', wordWrap: 'break-word'}}>Sign In</div>
                        <div style={{color: '#A3AED0', marginBottom: "4px", fontSize: 16, fontWeight: '400', wordWrap: 'break-word'}}>Enter your email and password to sign in.</div>
                        
                        {!showTwoFactor ? (
                            <form onSubmit={onSubmit}>
                                <FormControl className="mt-8" width={"100%"} isRequired={true}>
                                    <FormLabel>
                                        Email
                                    </FormLabel>
                                    <Input 
                                    onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl
                                isRequired={true}
                                className="mt-4" width={"100%"} marginBottom={"4"}>
                                    <FormLabel>
                                        Password
                                    </FormLabel>
                                    <Input type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>
                                <Button 
                                    width={"100%"} 
                                    className="mt-10" 
                                    background={"#00417D"} 
                                    color={"white"} 
                                    borderRadius={"16px"} 
                                    disabled={disableButtons || isLoading}
                                    type="submit"
                                >
                                    {isLoading ? "Signing in..." : "Sign In"}
                                </Button>
                            </form>
                        ) : (
                            <FormControl isRequired={true} className="mt-8">
                                <FormLabel>Verification Code</FormLabel>
                                <Input
                                    type="text" 
                                    value={twoFactorCode}
                                    onChange={(e) => setTwoFactorCode(e.target.value)}
                                    placeholder="Enter verification code"
                                />
                                <Button
                                    width={"100%"}
                                    mt={4}
                                    background={"#00417D"}
                                    color={"white"}
                                    borderRadius={"16px"}
                                    onClick={verifyTwoFactor}
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Verifying..." : "Verify Code"}
                                </Button>
                                <Checkbox 
                                    mt={4} 
                                    isChecked={rememberDevice}
                                    onChange={(e) => setRememberDevice(e.target.checked)}
                                >
                                    Remember this device for 30 days
                                </Checkbox>
                            </FormControl>
                        )}

                        <Button 
                            width={"100%"} 
                            className="mt-10" 
                            background={"#00417D"} 
                            color={"white"} 
                            borderRadius={"16px"} 
                            disabled={disableButtons}
                            onClick={handleMicrosoftLogin}
                            mt={2}
                        >
                            Sign In with Microsoft
                        </Button>
                        <Link to="/forgot-password">
                            <Button 
                                width={"100%"} 
                                mt={2}
                                className="mt-4" 
                                variant={"none"}
                            >
                                Forgot Password?
                            </Button>
                        </Link>
                        <Button 
                            width={"100%"} 
                            className="mt-4" 
                            variant={"none"}
                            onClick={() => navigate('/signup')}
                        >
                            Don&apos;t have an account?<Text as="span" color="#00417D" marginLeft="4px"> Sign up here</Text>
                        </Button>

                        <Text fontSize="sm" color="gray.500" textAlign="center" mt={8}>
                            By signing in, you agree to our{' '}
                            <Text as="a" href="https://www.datadasher.ai/terms/" color="blue.500" textDecoration="underline">
                                Terms
                            </Text>{' '}
                            and have read and acknowledge the{' '}
                            <Text as="a" href="https://www.datadasher.ai/policy/" color="blue.500" textDecoration="underline">
                                Privacy Policy
                            </Text>
                        </Text>
                   </div>
            </Flex>
            <Flex>
                <Image src="/images/login_bg.jpeg" alt="LOGO JPEG" width={"100%"} height={"100%"}/>
            </Flex>
        </SimpleGrid>
    )
};

export default Login;