import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box, Heading, Table, Tbody, Td, Th, Thead, Tr,
  Input, InputGroup, InputLeftElement, Link, Skeleton, Text,
  HStack, Button, useToast, Icon, Tooltip, Avatar, Tabs, TabList, TabPanels, TabPanel, Select, Menu, MenuButton, MenuList, MenuItem,
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Alert,
  AlertIcon,
  Progress,
  Code
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { ExternalLinkIcon, ChevronDownIcon, AddIcon, EditIcon } from '@chakra-ui/icons';
import { FaSearch, FaSync } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FeatureStats } from '../../../enums/UsageStats';

const Clients = () => {
  const toast = useToast();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const serverUrl = process.env.REACT_APP_API_URL;
  const wbUrl = 'https://app.crmworkspace.com/contacts/'
  const rtUrl = 'https://crm.redtailtechnology.com/contacts/'
  const [resyncLoading, setResyncLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [sortOrder, setSortOrder] = useState('recent');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handlePageChange = (value) => {
    setPage(value);
  };

  const openInCrm = async (id, crmSource) => {
    if (crmSource === 'Wealthbox') {
      window.open(`${wbUrl}${id}`, '_blank');
    }
    if (crmSource === 'RedTail') {
      window.open(`${rtUrl}${id}/`, '_blank');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsInitialLoading(true);
      try {
        const response = await axios.get(serverUrl + '/api/clients?page=' + page + '&pageSize=' + pageSize);
        setClients(response.data.clients);
        setSearchResults(response.data.clients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching calls:", error);
        toast({
          title: "Error fetching calls.",
          description: `There was an error fetching calls from the server: ${error.response ? error.response.data.details : error.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsInitialLoading(false);
      }
    };
    fetchData();
  }, [page, pageSize]);

  const handleChange = event => {
    setSearchTerm(event.target.value);
    setIsSearching(true);
  };

  const sortClients = (clients, order) => {
    switch (order) {
      case 'recent':
        return [...clients].sort((a, b) => {
          const aDate = a.emails?.[0]?.receivedAt || a.calls?.[0]?.startTime || new Date(0);
          const bDate = b.emails?.[0]?.receivedAt || b.calls?.[0]?.startTime || new Date(0);
          return new Date(bDate) - new Date(aDate);
        });
      case 'created':
        return [...clients].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case 'az':
        return [...clients].sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`));
      case 'za':
        return [...clients].sort((a, b) => `${b.firstName} ${b.lastName}`.localeCompare(`${a.firstName} ${a.lastName}`));
      default:
        return clients;
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      setIsSearching(true);
      try {
        if (searchTerm) {

          // Log the client search
          await axios.post(`${serverUrl}/api/usage/log`, {
            statPath: FeatureStats.CLIENTS_SEARCHED,
            value: 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          });
          
          // When searching, fetch all clients without pagination
          const response = await axios.get(`${serverUrl}/api/clients?search=${searchTerm}`);
          setSearchResults(sortClients(response.data.clients, sortOrder));
        } else {
          const response = await axios.get(`${serverUrl}/api/clients?page=${page}&pageSize=${pageSize}`);
          setSearchResults(sortClients(response.data.clients, sortOrder));
        }
      } catch (error) {
        console.error("Error searching clients:", error);
      } finally {
        setIsSearching(false);
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm, serverUrl, sortOrder]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const handleResync = async () => {
    setResyncLoading(true);
    console.log('handleResync - Initiating resync process');
    try {
      console.warn('handleResync - Resyncing all clients for the organization');
      const response = await axios.post(`${serverUrl}/api/clients/resync-all`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        // Log the CRM resync
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.CRM_RESYNCS,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });

        toast({
          title: "Resync Initiated",
          description: "All client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        setResyncLoading(true);
        // wait 10 seconds before fetching jobs
        setTimeout(fetchJobs, 10000);
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      console.log('Clients Component: handleResync - Resync process completed');
    }
  };

  const fetchJobs = async (bypassToast = false) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await axios.get(`${serverUrl}/api/jobs?type=Resync&status=active`, { headers: { Authorization: `Bearer ${token}` }, withCredentials: true });
      console.log('Jobs:', response.data);
      setResyncLoading(response.data.length > 0);
      if (response.data.length > 0) {
        setTimeout(fetchJobs, 10000); // Poll every 10 seconds until the length is 0
      } else {
        setResyncLoading(false);
        if (!bypassToast) {
          toast({
            title: "Resync Completed",
            description: "All client data resync has been completed. Refresh the page to see the latest data.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs(true);
  }, []);

  const navigate = useNavigate();
  const handleAddClient = () => {
    navigate('/clients/new');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadResults(null);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const csvData = e.target.result;
          const response = await axios.post(
            `${serverUrl}/api/clients/upload-csv`,
            { csvData },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Cookies.get('jwtToken')}`
              }
            }
          );

          setUploadResults(response.data);
          
          // Refresh client list if any clients were successfully added
          if (response.data.successCount > 0) {
            const clientsResponse = await axios.get(serverUrl + '/api/clients?page=' + page + '&pageSize=' + pageSize);
            setClients(clientsResponse.data.clients);
            setSearchResults(clientsResponse.data.clients);
            setTotalPages(clientsResponse.data.totalPages);
          }

        } catch (error) {
          toast({
            title: 'Error uploading CSV',
            description: error.response?.data?.error || 'Failed to upload CSV file',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      };
      reader.readAsText(file);
    } catch (error) {
      toast({
        title: 'Error reading file',
        description: 'Failed to read the CSV file',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const CSV_TEMPLATE = `firstName,lastName,email,phone,prefix,middleName,suffix,nickname,maritalStatus,jobTitle,company,phoneSecondary,tags,background,householdRole,householdName,streetLine1,streetLine2,city,state,country,gender,preferredName,emailSecondary,citizenship,preferredMethodOfContact,preferredMeetingFrequency,investmentObjectives,financialGoals,annualIncome,wealthRange,netWorth,spouseName,spouseDetails,familyDetails
John,Doe,john@example.com,123-456-7890,Mr.,James,Jr.,Johnny,Married,CEO,Acme Inc,987-654-3210,"VIP,High Priority",Long-term client,Head,Doe Family,123 Main St,Suite 100,Anytown,CA,USA,Male,Johnny,john.doe@work.com,US,Email,Quarterly,"Wealth Growth,Preservation","Retirement,College Planning",150000,High Net Worth,2000000,Jane Doe,Works as doctor,2 children in college`;

  return (
    <Box bg="white" p={8} overflowY="auto">
      <HStack justify="space-between" mb={4}>
        <Heading color={'#00417D'} fontSize={'32px'}>Clients</Heading>
        <HStack spacing={4}>
          <Select
            size="sm"
            w="200px"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="recent">Most Recent</option>
            <option value="created">Recently Created</option>
            <option value="az">A-Z</option>
            <option value="za">Z-A</option>
          </Select>
          
          <Button
            onClick={handleResync}
            isLoading={resyncLoading}
            loadingText="Resyncing"
            size="sm"
            variant="outline"
            colorScheme="blue"
            leftIcon={
              <motion.div
                animate={{ rotate: resyncLoading ? 360 : 0 }}
                transition={{ duration: 1, repeat: resyncLoading ? Infinity : 0, ease: "linear" }}
              >
                <Icon as={FaSync} />
              </motion.div>
            }
          >
            Resync CRM
          </Button>

          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} colorScheme="blue" size="sm">
              Add Client
            </MenuButton>
            <MenuList>
              <MenuItem icon={<AddIcon />} onClick={handleAddClient}>
                Add Single Client
              </MenuItem>
              <MenuItem icon={<ExternalLinkIcon />} onClick={onOpen}>
                Upload CSV
              </MenuItem>
            </MenuList>
          </Menu>

          <InputGroup w={'auto'}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color={'#A1A1A1'} w={3} mb={'6px'} />
            </InputLeftElement>
            <Input
              placeholder="Search for a client"
              _placeholder={{
                color: '#A1A1A1',
                fontWeight: 700,
                textAlign: 'center'
              }}
              borderRadius={'6px'}
              size="sm"
              w='270px'
              value={searchTerm}
              onChange={handleChange}
            />
          </InputGroup>
        </HStack>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Clients CSV</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Text>
                Upload a CSV file containing client information. Download the template below for the correct format.
              </Text>
              
              <HStack>
                <Button
                  as="label"
                  htmlFor="csv-upload"
                  colorScheme="blue"
                  leftIcon={<ExternalLinkIcon />}
                  isLoading={isUploading}
                  cursor="pointer"
                >
                  Choose CSV File
                  <input
                    id="csv-upload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                  />
                </Button>

                <Button
                  leftIcon={<ExternalLinkIcon />}
                  onClick={() => {
                    const blob = new Blob([CSV_TEMPLATE], { type: 'text/csv' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'client-template.csv';
                    a.click();
                  }}
                >
                  Download Template
                </Button>
              </HStack>

              {uploadResults && (
                <VStack spacing={4} align="stretch">
                  <Progress
                    value={(uploadResults.successCount / uploadResults.totalProcessed) * 100}
                    colorScheme="blue"
                    size="sm"
                  />
                  
                  <Alert status={uploadResults.errorCount > 0 ? 'warning' : 'success'}>
                    <AlertIcon />
                    Processed {uploadResults.totalProcessed} records:
                    {uploadResults.successCount} successful,
                    {uploadResults.errorCount} failed
                  </Alert>

                </VStack>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isInitialLoading ? (
        <Box mt={12}>
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
          <Skeleton height="32px" width="200px" mb={4} />
        </Box>
      ) : (
        <Table variant="simple" mt={12}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Last Correspondence</Th>
              <Th textAlign="right">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {searchResults.map(client => (
              <Tr key={client.id}>
                <Td>
                  <HStack spacing={3}>
                    <Avatar 
                      name={client.firstName}
                      size="sm"
                      bg="#00417D"
                      color="white"
                      fontSize="16px"
                      fontWeight="700"
                    />
                    <Text>{client.firstName} {client.metadata?.preferredName ? `(${client.metadata?.preferredName})` : ''} {client.lastName}</Text>
                  </HStack>
                </Td>
                <Td>
                  {client.emails?.length > 0 ?
                    formatDate(client.emails[0].receivedAt) :
                    client.calls?.length > 0 ?
                      formatDate(client.calls[0].startTime) :
                      'No correspondence'
                  }
                </Td>
                <Td textAlign="right">
                <HStack justify="flex-end" spacing={2}>
                    <Button
                      as={RouterLink}
                      to={`/clients/${client.id}`}
                      colorScheme="blue"
                      size="sm"
                    >
                      View
                    </Button>
                    {(!client.crmSource && !client.crmId) && (  // Only show edit button for non-CRM clients
                      <Button
                        as={RouterLink}
                        to={`/clients/${client.id}/edit`}
                        leftIcon={<EditIcon />}
                        size="sm"
                        variant="outline"
                        colorScheme="blue"
                      >
                        Edit
                      </Button>
                    )}
                    {(client.crmId && client.crmSource) && (
                      <Tooltip label={`Open in ${client.crmSource}`}>
                        <Button
                         leftIcon={<ExternalLinkIcon />}
                         onClick={() => openInCrm(client.crmId, client.crmSource)}
                         size="sm"
                         variant="outline"
                         colorScheme="blue"
                         >
                          {client.crmSource}
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <HStack justify="flex-end" mt={4}>
        {page > 1 && <Button onClick={() => handlePageChange(page - 1)}>Previous</Button>}
        {page < totalPages && <Button onClick={() => handlePageChange(page + 1)}>Next</Button>}
        <Text>Page {page} of {totalPages}</Text>
      </HStack>
    </Box>
  );
};

export default Clients;