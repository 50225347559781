import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
// Import all the same components as AddClient
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Select,
  Collapse,
  useDisclosure,
  useToast,
  HStack,
  Textarea,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, ArrowBackIcon, InfoIcon, AddIcon, CloseIcon } from '@chakra-ui/icons';

const EditClient = () => {
  // Get client ID from URL params
  const { id } = useParams();
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [existingTags, setExistingTags] = useState([]);
  const [newTagInput, setNewTagInput] = useState('');
  const [newObjectiveInput, setNewObjectiveInput] = useState('');
  const [newGoalInput, setNewGoalInput] = useState('');
  const [loading, setLoading] = useState(true);

  // Use the same formData structure as AddClient
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    metadata: {
      prefix: '',
      middle: '',
      suffix: '',
      nickname: '',
      maritalStatus: '',
      jobTitle: '',
      company: '',
      phoneSecondary: '',
      tags: [],
      background: '',
      householdRole: '',
      householdName: '',
      streetLine1: '',
      streetLine2: '',
      city: '',
      state: '',
      country: '',
      gender: '',
      preferredName: '',
      emailSecondary: '',
      citizenship: '',
      preferredMethodOfContact: '',
      preferredMeetingFrequency: '',
      investmentObjectives: [],
      financialGoals: [],
      annualIncome: '',
      wealthRange: '',
      netWorth: '',
      spouseName: '',
      spouseDetails: '',
      familyDetails: '',
      specialDates: []
    }
  });

  const defaultObjectives = [
    "Wealth Growth",
    "Preservation",
    "Retirement Planning",
    "Estate Planning",
    "Tax Optimization",
    "College Savings",
    "Business Succession",
    "Legacy Planning"
  ];

  const defaultGoals = [
    "Retirement",
    "College Planning",
    "Home Purchase",
    "Philanthropy",
    "Business Sale",
    "Tax Sheltering",
    "Debt Reduction",
    "Emergency Fund",
    "Travel",
    "Estate Planning"
  ];

  // Fetch client data on component mount
  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/clients/${id}`);
        
        // Convert tags to array if it's a string
        const tags = response.data.metadata?.tags;
        const tagsArray = Array.isArray(tags) 
          ? tags 
          : typeof tags === 'string'
            ? tags.split(',').filter(tag => tag.trim())
            : [];
        delete response.data.emails;
        delete response.data.calls;
        setFormData({
          ...response.data,
          metadata: {
            ...response.data.metadata,
            tags: tagsArray,
            specialDates: response.data.metadata?.specialDates || []
          }
        });
       
        setLoading(false);
      } catch (error) {
        toast({
          title: 'Error fetching client data',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        navigate('/clients');
      }
    };

    fetchClientData();
  }, [id]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/client-tags`);
        setExistingTags(response.data.tags);
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast({
          title: 'Error fetching tags',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchTags();
  }, []);

  const formatMoneyValue = (value) => {
    if (!value) return '';
    const numericValue = value.toString().replace(/[^0-9.]/g, '');
    const parts = numericValue.split('.');
    const formatted = parts[0] + (parts.length > 1 ? '.' + parts[1].slice(0, 2) : '');
    return `$${formatted}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'metadata.annualIncome' || name === 'metadata.netWorth') {
      if (!value || value === '$') {
        const [parent, child] = name.split('.');
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: ''
          }
        }));
        return;
      }

      const numericValue = value.replace(/[^0-9.]/g, '');
      
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        setFormData(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: numericValue
          }
        }));
      }
      return;
    }

    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSpecialDateChange = (index, field, value) => {
    setFormData(prev => {
      const updatedDates = [...prev.metadata.specialDates];
      updatedDates[index] = {
        ...updatedDates[index],
        [field]: value
      };
      return {
        ...prev,
        metadata: {
          ...prev.metadata,
          specialDates: updatedDates
        }
      };
    });
  };

  const addSpecialDate = () => {
    setFormData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        specialDates: [...prev.metadata.specialDates, { month: '', day: '', year: '', type: '' }]
      }
    }));
  };

  const removeSpecialDate = (index) => {
    setFormData(prev => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        specialDates: prev.metadata.specialDates.filter((_, i) => i !== index)
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`${serverUrl}/api/clients/${id}`, formData);
      toast({
        title: 'Client updated.',
        description: "We've updated the client for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      navigate(`/clients/${id}`);
    } catch (error) {
      toast({
        title: 'Error updating client.',
        description: error.response?.data?.error || 'An error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box bg="white" p={8}>
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <Box bg="white" p={8} overflowY="auto">
      <HStack justify="space-between" mb={4}>
        <Button 
          onClick={() => navigate(-1)} 
          colorScheme="blue" 
          variant="outline" 
          size="sm"
        >
          Back
        </Button>
      </HStack>

      <HStack spacing={4}>
        <Heading color="#00417D" fontSize="32px">Edit Client</Heading>
      </HStack>

      <form onSubmit={handleSubmit}>
        <VStack spacing={6} align="stretch" mt={12}>
          {/* Basic Information - 2 columns */}
          <HStack spacing={8} align="flex-start">
            <FormControl isRequired flex="1">
              <FormLabel fontWeight={600}>First Name</FormLabel>
              <Input 
                name="firstName" 
                value={formData.firstName} 
                onChange={handleChange}
                size="sm"
                borderRadius="6px"
              />
            </FormControl>
            
            <FormControl isRequired flex="1">
              <FormLabel fontWeight={600}>Last Name</FormLabel>
              <Input 
                name="lastName" 
                value={formData.lastName} 
                onChange={handleChange}
                size="sm"
                borderRadius="6px"
              />
            </FormControl>
          </HStack>

          <HStack spacing={8} align="flex-start">
            <FormControl isRequired flex="1">
              <FormLabel fontWeight={600}>Email</FormLabel>
              <Input 
                name="email" 
                type="email" 
                value={formData.email} 
                onChange={handleChange}
                size="sm"
                borderRadius="6px"
              />
            </FormControl>

            <FormControl flex="1">
              <FormLabel fontWeight={600}>Phone</FormLabel>
              <Input 
                name="phone" 
                value={formData.phone} 
                onChange={handleChange}
                size="sm"
                borderRadius="6px"
              />
            </FormControl>
          </HStack>

          <Button 
            onClick={onToggle} 
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            size="sm"
            variant="outline"
            colorScheme="blue"
            w="fit-content"
          >
            {isOpen ? 'Hide Additional Fields' : 'Show Additional Fields'}
          </Button>

          <Collapse in={isOpen} animateOpacity>
            <VStack spacing={6} align="stretch">
              {/* Name Details - 4 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Prefix</FormLabel>
                  <Input name="metadata.prefix" value={formData.metadata.prefix} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Middle Name</FormLabel>
                  <Input name="metadata.middle" value={formData.metadata.middle} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Suffix</FormLabel>
                  <Input name="metadata.suffix" value={formData.metadata.suffix} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Preferred Name</FormLabel>
                  <Input name="metadata.preferredName" value={formData.metadata.preferredName} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>
              </HStack>

              {/* Personal Details - 3 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Marital Status</FormLabel>
                  <Select name="metadata.maritalStatus" value={formData.metadata.maritalStatus} onChange={handleChange} size="sm" borderRadius="6px">
                    <option value="">Select status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Life Partner">Life Partner</option>
                    <option value="Separated">Separated</option>
                    <option value="Unknown">Unknown</option>
                  </Select>
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Gender</FormLabel>
                  <Select name="metadata.gender" value={formData.metadata.gender} onChange={handleChange} size="sm" borderRadius="6px">
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Non-binary">Non-binary</option>
                    <option value="Unspecified">Unspecified</option>
                  </Select>
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Citizenship</FormLabel>
                  <Input name="metadata.citizenship" value={formData.metadata.citizenship} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>
              </HStack>

              {/* Contact Details - 3 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Secondary Phone</FormLabel>
                  <Input name="metadata.phoneSecondary" value={formData.metadata.phoneSecondary} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Secondary Email</FormLabel>
                  <Input name="metadata.emailSecondary" value={formData.metadata.emailSecondary} onChange={handleChange} type="email" size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Preferred Contact Method</FormLabel>
                  <Select name="metadata.preferredMethodOfContact" value={formData.metadata.preferredMethodOfContact} onChange={handleChange} size="sm" borderRadius="6px">
                    <option value="">Select preference</option>
                    <option value="Phone">Phone</option>
                    <option value="Email">Email</option>
                    <option value="Text">Text</option>
                    <option value="In-Person Meeting">In-Person Meeting</option>
                  </Select>
                </FormControl>
              </HStack>

              {/* Professional Details - 3 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Job Title</FormLabel>
                  <Input name="metadata.jobTitle" value={formData.metadata.jobTitle} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Company</FormLabel>
                  <Input name="metadata.company" value={formData.metadata.company} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Tags</FormLabel>
                  <Select
                    name="metadata.tags"
                    value=""
                    onChange={(e) => {
                      const newTag = e.target.value;
                      if (newTag && !formData.metadata.tags.includes(newTag)) {
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            tags: [...prev.metadata.tags, newTag]
                          }
                        }));
                      }
                    }}
                    placeholder="Select or type tags"
                    size="sm"
                    borderRadius="6px"
                  >
                    <option value="">Select a tag</option>
                    {existingTags.map(tag => (
                      <option key={tag} value={tag}>{tag}</option>
                    ))}
                  </Select>
                  
                  {/* Display selected tags */}
                  <HStack mt={2} spacing={2} flexWrap="wrap">
                    {formData.metadata.tags.map((tag, index) => (
                      <Button
                        key={index}
                        size="sm"
                        variant="outline"
                        rightIcon={<CloseIcon w={2} h={2} />}
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            metadata: {
                              ...prev.metadata,
                              tags: prev.metadata.tags.filter((_, i) => i !== index)
                            }
                          }));
                        }}
                      >
                        {tag}
                      </Button>
                    ))}
                  </HStack>

                  {/* Add custom tag input */}
                  <HStack mt={2}>
                    <Input
                      placeholder="Add custom tag"
                      size="sm"
                      value={newTagInput}
                      onChange={(e) => setNewTagInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && newTagInput && !formData.metadata.tags.includes(newTagInput)) {
                          setFormData(prev => ({
                            ...prev,
                            metadata: {
                              ...prev.metadata,
                              tags: [...prev.metadata.tags, newTagInput]
                            }
                          }));
                          setNewTagInput('');
                        }
                      }}
                    />
                    <Button
                      size="sm"
                      onClick={() => {
                        if (newTagInput && !formData.metadata.tags.includes(newTagInput)) {
                          setFormData(prev => ({
                            ...prev,
                            metadata: {
                              ...prev.metadata,
                              tags: [...prev.metadata.tags, newTagInput]
                            }
                          }));
                          setNewTagInput('');
                        }
                      }}
                    >
                      Add
                    </Button>
                  </HStack>
                </FormControl>
              </HStack>

              {/* Household Details - 2 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Household Role</FormLabel>
                  <Select name="metadata.householdRole" value={formData.metadata.householdRole} onChange={handleChange} size="sm" borderRadius="6px">
                    <option value="">Select role</option>
                    <option value="Head">Head</option>
                    <option value="Spouse">Spouse</option>
                    <option value="Partner">Partner</option>
                    <option value="Child">Child</option>
                    <option value="Grandchild">Grandchild</option>
                    <option value="Grandparent">Grandparent</option>
                    <option value="Sibling">Sibling</option>
                    <option value="Other Dependent">Other Dependent</option>
                  </Select>
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Household Name</FormLabel>
                  <Input name="metadata.householdName" value={formData.metadata.householdName} onChange={handleChange} size="sm" borderRadius="6px" />
                </FormControl>
              </HStack>

              {/* Address - 2 columns */}
              <HStack spacing={8} align="flex-start">
                <VStack spacing={4} flex="1">
                  <FormControl>
                    <FormLabel fontWeight={600}>Street Address Line 1</FormLabel>
                    <Input name="metadata.streetLine1" value={formData.metadata.streetLine1} onChange={handleChange} size="sm" borderRadius="6px" />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight={600}>Street Address Line 2</FormLabel>
                    <Input name="metadata.streetLine2" value={formData.metadata.streetLine2} onChange={handleChange} size="sm" borderRadius="6px" />
                  </FormControl>
                </VStack>

                <VStack spacing={4} flex="1">
                  <HStack spacing={4}>
                    <FormControl flex="1">
                      <FormLabel fontWeight={600}>City</FormLabel>
                      <Input name="metadata.city" value={formData.metadata.city} onChange={handleChange} size="sm" borderRadius="6px" />
                    </FormControl>

                    <FormControl flex="1">
                      <FormLabel fontWeight={600}>State</FormLabel>
                      <Input name="metadata.state" value={formData.metadata.state} onChange={handleChange} size="sm" borderRadius="6px" />
                    </FormControl>
                  </HStack>

                  <FormControl>
                    <FormLabel fontWeight={600}>Country</FormLabel>
                    <Input name="metadata.country" value={formData.metadata.country} onChange={handleChange} size="sm" borderRadius="6px" />
                  </FormControl>
                </VStack>
              </HStack>

              {/* Special Dates Section */}
              <VStack align="stretch" spacing={4}>
                <FormControl>
                  <FormLabel fontWeight={600}>Special Dates</FormLabel>
                  <VStack align="stretch" spacing={2}>
                    {formData.metadata.specialDates.map((date, index) => (
                      <HStack key={index} spacing={4} align="flex-end">
                        <FormControl flex="1">
                          <FormLabel fontSize="sm">Month</FormLabel>
                          <Input
                            size="sm"
                            value={date.month}
                            onChange={(e) => handleSpecialDateChange(index, 'month', e.target.value)}
                            placeholder="MM"
                            maxLength={2}
                            borderRadius="6px"
                          />
                        </FormControl>
                        <FormControl flex="1">
                          <FormLabel fontSize="sm">Day</FormLabel>
                          <Input
                            size="sm"
                            value={date.day}
                            onChange={(e) => handleSpecialDateChange(index, 'day', e.target.value)}
                            placeholder="DD"
                            maxLength={2}
                            borderRadius="6px"
                          />
                        </FormControl>
                        <FormControl flex="1">
                          <FormLabel fontSize="sm">Year</FormLabel>
                          <Input
                            size="sm"
                            value={date.year}
                            onChange={(e) => handleSpecialDateChange(index, 'year', e.target.value)}
                            placeholder="YYYY"
                            maxLength={4}
                            borderRadius="6px"
                          />
                        </FormControl>
                        <FormControl flex="2">
                          <FormLabel fontSize="sm">Type</FormLabel>
                          <Select
                            size="sm"
                            value={date.type}
                            onChange={(e) => handleSpecialDateChange(index, 'type', e.target.value)}
                            borderRadius="6px"
                          >
                            <option value="">Select type</option>
                            <option value="Birthdate">Birthdate</option>
                            <option value="Anniversary">Anniversary</option>
                            <option value="Client since">Client since</option>
                            <option value="Date of death">Date of death</option>
                          </Select>
                        </FormControl>
                        <Button
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                          onClick={() => removeSpecialDate(index)}
                        >
                          Remove
                        </Button>
                      </HStack>
                    ))}
                    <Button
                      size="sm"
                      colorScheme="blue"
                      variant="ghost"
                      onClick={addSpecialDate}
                      leftIcon={<AddIcon />}
                      w="fit-content"
                    >
                      Add Special Date
                    </Button>
                  </VStack>
                </FormControl>
              </VStack>

              {/* Background - Full width */}
              <FormControl>
                <FormLabel fontWeight={600}>Background</FormLabel>
                <Textarea 
                  name="metadata.background" 
                  value={formData.metadata.background} 
                  onChange={handleChange}
                  rows={4}
                  size="sm"
                  borderRadius="6px"
                />
              </FormControl>

              {/* Financial Information - 3 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Annual Income</FormLabel>
                  <Input 
                    name="metadata.annualIncome" 
                    value={formData.metadata.annualIncome ? formatMoneyValue(formData.metadata.annualIncome) : ''} 
                    onChange={handleChange}
                    size="sm" 
                    borderRadius="6px"
                  />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Net Worth</FormLabel>
                  <Input 
                    name="metadata.netWorth" 
                    value={formData.metadata.netWorth ? formatMoneyValue(formData.metadata.netWorth) : ''} 
                    onChange={handleChange}
                    size="sm" 
                    borderRadius="6px"
                  />
                </FormControl>

                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Wealth Range</FormLabel>
                  <Select 
                    name="metadata.wealthRange" 
                    value={formData.metadata.wealthRange} 
                    onChange={handleChange}
                    size="sm" 
                    borderRadius="6px"
                  >
                    <option value="">Select range</option>
                    <option value="High Net Worth">High Net Worth</option>
                    <option value="Very High Net Worth">Very High Net Worth</option>
                    <option value="Ultra High Net Worth">Ultra High Net Worth</option>
                    <option value="Mass Affluent">Mass Affluent</option>
                  </Select>
                </FormControl>
              </HStack>

              {/* Investment Details - 2 columns */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Investment Objectives</FormLabel>
                  <Select
                    name="metadata.investmentObjectives"
                    value=""
                    onChange={(e) => {
                      const newObjective = e.target.value;
                      if (newObjective && !formData.metadata.investmentObjectives) {
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            investmentObjectives: [newObjective]
                          }
                        }));
                      } else if (newObjective && !formData.metadata.investmentObjectives.includes(newObjective)) {
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            investmentObjectives: [...prev.metadata.investmentObjectives, newObjective]
                          }
                        }));
                      }
                    }}
                    placeholder="Select objectives"
                    size="sm"
                    borderRadius="6px"
                  >
                    {defaultObjectives.map(objective => (
                      <option key={objective} value={objective}>{objective}</option>
                    ))}
                  </Select>

                  {/* Display selected objectives */}
                  <HStack mt={2} spacing={2} flexWrap="wrap">
                    {Array.isArray(formData.metadata.investmentObjectives) && 
                      formData.metadata.investmentObjectives.map((objective, index) => (
                        <Button
                          key={index}
                          size="sm"
                          variant="outline"
                          rightIcon={<CloseIcon w={2} h={2} />}
                          onClick={() => {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                investmentObjectives: prev.metadata.investmentObjectives.filter((_, i) => i !== index)
                              }
                            }));
                          }}
                        >
                          {objective}
                        </Button>
                    ))}
                  </HStack>

                  {/* Add custom objective input */}
                  <HStack mt={2}>
                    <Input
                      placeholder="Add custom objective"
                      size="sm"
                      value={newObjectiveInput}
                      onChange={(e) => setNewObjectiveInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && newObjectiveInput) {
                          if (!formData.metadata.investmentObjectives) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                investmentObjectives: [newObjectiveInput]
                              }
                            }));
                          } else if (!formData.metadata.investmentObjectives.includes(newObjectiveInput)) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                investmentObjectives: [...prev.metadata.investmentObjectives, newObjectiveInput]
                              }
                            }));
                          }
                          setNewObjectiveInput('');
                        }
                      }}
                    />
                    <Button
                      size="sm"
                      onClick={() => {
                        if (newObjectiveInput) {
                          if (!formData.metadata.investmentObjectives) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                investmentObjectives: [newObjectiveInput]
                              }
                            }));
                          } else if (!formData.metadata.investmentObjectives.includes(newObjectiveInput)) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                investmentObjectives: [...prev.metadata.investmentObjectives, newObjectiveInput]
                              }
                            }));
                          }
                          setNewObjectiveInput('');
                        }
                      }}
                    >
                      Add
                    </Button>
                  </HStack>
                </FormControl>
              </HStack>

              {/* Financial Goals */}
              <HStack spacing={8} align="flex-start">
                <FormControl flex="1">
                  <FormLabel fontWeight={600}>Financial Goals</FormLabel>
                  <Select
                    name="metadata.financialGoals"
                    value=""
                    onChange={(e) => {
                      const newGoal = e.target.value;
                      if (newGoal && !formData.metadata.financialGoals) {
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            financialGoals: [newGoal]
                          }
                        }));
                      } else if (newGoal && !formData.metadata.financialGoals.includes(newGoal)) {
                        setFormData(prev => ({
                          ...prev,
                          metadata: {
                            ...prev.metadata,
                            financialGoals: [...prev.metadata.financialGoals, newGoal]
                          }
                        }));
                      }
                    }}
                    placeholder="Select goals"
                    size="sm"
                    borderRadius="6px"
                  >
                    {defaultGoals.map(goal => (
                      <option key={goal} value={goal}>{goal}</option>
                    ))}
                  </Select>

                  {/* Display selected goals */}
                  <HStack mt={2} spacing={2} flexWrap="wrap">
                    {Array.isArray(formData.metadata.financialGoals) && 
                      formData.metadata.financialGoals.map((goal, index) => (
                        <Button
                          key={index}
                          size="sm"
                          variant="outline"
                          rightIcon={<CloseIcon w={2} h={2} />}
                          onClick={() => {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                financialGoals: prev.metadata.financialGoals.filter((_, i) => i !== index)
                              }
                            }));
                          }}
                        >
                          {goal}
                        </Button>
                    ))}
                  </HStack>

                  {/* Add custom goal input */}
                  <HStack mt={2}>
                    <Input
                      placeholder="Add custom goal"
                      size="sm"
                      value={newGoalInput}
                      onChange={(e) => setNewGoalInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && newGoalInput) {
                          if (!formData.metadata.financialGoals) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                financialGoals: [newGoalInput]
                              }
                            }));
                          } else if (!formData.metadata.financialGoals.includes(newGoalInput)) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                financialGoals: [...prev.metadata.financialGoals, newGoalInput]
                              }
                            }));
                          }
                          setNewGoalInput('');
                        }
                      }}
                    />
                    <Button
                      size="sm"
                      onClick={() => {
                        if (newGoalInput) {
                          if (!formData.metadata.financialGoals) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                financialGoals: [newGoalInput]
                              }
                            }));
                          } else if (!formData.metadata.financialGoals.includes(newGoalInput)) {
                            setFormData(prev => ({
                              ...prev,
                              metadata: {
                                ...prev.metadata,
                                financialGoals: [...prev.metadata.financialGoals, newGoalInput]
                              }
                            }));
                          }
                          setNewGoalInput('');
                        }
                      }}
                    >
                      Add
                    </Button>
                  </HStack>
                </FormControl>
              </HStack>

              {/* Meeting Preferences */}
              <FormControl>
                <FormLabel fontWeight={600}>Preferred Meeting Frequency</FormLabel>
                <Select 
                  name="metadata.preferredMeetingFrequency" 
                  value={formData.metadata.preferredMeetingFrequency} 
                  onChange={handleChange}
                  size="sm" 
                  borderRadius="6px"
                >
                  <option value="">Select frequency</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Semi-Annually">Semi-Annually</option>
                  <option value="Annually">Annually</option>
                  <option value="As Needed">As Needed</option>
                </Select>
              </FormControl>

              {/* Family Information - 2 columns */}
              <HStack spacing={8} align="flex-start">
                <VStack spacing={4} flex="1">
                  <FormControl>
                    <FormLabel fontWeight={600}>Spouse Name</FormLabel>
                    <Input 
                      name="metadata.spouseName" 
                      value={formData.metadata.spouseName} 
                      onChange={handleChange}
                      size="sm" 
                      borderRadius="6px"
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight={600}>Spouse Details</FormLabel>
                    <Textarea 
                      name="metadata.spouseDetails" 
                      value={formData.metadata.spouseDetails} 
                      onChange={handleChange}
                      size="sm" 
                      borderRadius="6px"
                    />
                  </FormControl>
                </VStack>

                <VStack spacing={4} flex="1">
                  <FormControl>
                    <FormLabel fontWeight={600}>Family Details</FormLabel>
                    <Textarea
                      name="metadata.familyDetails"
                      value={formData.metadata.familyDetails}
                      onChange={handleChange}
                      placeholder="Jane Doe (13), student&#13;&#10;Joe Smith (45), software developer"
                      size="sm"
                      borderRadius="6px"
                      rows={4}
                    />
                  </FormControl>
                </VStack>
              </HStack>
            </VStack>
          </Collapse>

          <HStack justify="space-between" mt={4}>
            <HStack color="gray.600" fontSize="sm">
              <InfoIcon />
              <Text>
                DataDasher will act as the CRM for this client. If you connect another CRM later with duplicate information, you may have to merge common fields.
              </Text>
            </HStack>
            <HStack>
              <Button 
                onClick={() => navigate(`/clients/${id}`)} 
                variant="ghost"
                size="sm"
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                colorScheme="blue"
                size="sm"
                isLoading={isSubmitting}
                loadingText="Updating"
                isDisabled={isSubmitting}
              >
                Update Client
              </Button>
            </HStack>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};

export default EditClient; 