import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Text, 
  Button, 
  HStack, 
  Icon, 
  Divider,
  IconButton,
  Collapse
} from '@chakra-ui/react';
import { 
  FaInfoCircle, 
  FaExclamationTriangle, 
  FaTimes 
} from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';

// Test data
// const TEST_ALERTS = [
//   {
//     id: 1,
//     text: "Scheduled maintenance tonight from 2-4am EST. Some features may be unavailable.",
//     variant: "informative",
//     actionLabel: "Learn More",
//     isDismissable: true,
//     priority: 2
//   },
//   {
//     id: 2,
//     text: "Critical system outage: Call recording feature currently unavailable",
//     variant: "negative",
//     actionLabel: "Check Status",
//     isDismissable: true,
//     priority: 1
//   },
//   {
//     id: 3,
//     text: "New feature available: AI-powered meeting summaries",
//     variant: "neutral",
//     actionLabel: "Try Now",
//     isDismissable: true,
//     priority: 3
//   }
// ];

const AlertBanner = () => {
  const [alerts, setAlerts] = useState([]);
  const [currentAlert, setCurrentAlert] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        // Simulate API call
        // const response = TEST_ALERTS;
        console.log('Fetching alerts from:', `${serverUrl}/api/alerts`);
        const response = await axios.get(`${serverUrl}/api/alerts`, {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });
        
        // console.log('Raw API response:', response);
        // console.log('Alert data:', response.data);
        
        const sortedAlerts = response.data.sort((a, b) => a.priority - b.priority);
        // console.log('Sorted alerts:', sortedAlerts);
        setAlerts(sortedAlerts);
        setCurrentAlert(sortedAlerts[0]);
      } catch (error) {
        console.error('Error fetching alerts:', error);
      }
    };

    fetchAlerts();
  }, []);

  const handleDismiss = () => {
    setIsVisible(false);
    // Remove current alert and show next highest priority alert if it exists
    const remainingAlerts = alerts.filter(alert => alert.id !== currentAlert.id);
    setAlerts(remainingAlerts);
    
    setTimeout(() => {
      if (remainingAlerts.length > 0) {
        setCurrentAlert(remainingAlerts[0]);
        setIsVisible(true);
      } else {
        setCurrentAlert(null);
      }
    }, 300); // Wait for collapse animation
  };

  const getVariantStyles = (variant) => {
    switch (variant) {
      case 'informative':
        return {
          bg: 'blue.50',
          color: 'blue.700',
          icon: FaInfoCircle,
          iconColor: 'blue.500'
        };
      case 'negative':
        return {
          bg: 'red.50',
          color: 'red.700',
          icon: FaExclamationTriangle,
          iconColor: 'red.500'
        };
      default: // neutral
        return {
          bg: 'gray.100',
          color: 'gray.700',
          icon: null,
          iconColor: null
        };
    }
  };

  if (!currentAlert || !isVisible) return null;

  const variantStyles = getVariantStyles(currentAlert.variant);

  return (
    <Collapse in={isVisible}>
      <Box
        w="100%"
        py={3}
        px={4}
        mb={5}
        bg={variantStyles.bg}
        color={variantStyles.color}
        borderRadius="xl"
        boxShadow="sm"
        _hover={{ boxShadow: 'md' }}
        transition="all 0.2s"
      >
        <HStack
          maxW="7xl"
          mx="auto"
          spacing={4}
          justify="space-between"
          align="center"
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
        >
          <HStack spacing={3} flex="1">
            {variantStyles.icon && (
              <Icon
                as={variantStyles.icon}
                boxSize={5}
                color={variantStyles.iconColor}
              />
            )}
            <Text fontSize="sm" fontWeight="medium">
              {currentAlert.text}
            </Text>
          </HStack>

          <HStack spacing={4}>
            {currentAlert.actionLabel && (
              <Button
                size="sm"
                variant="solid"
                colorScheme={currentAlert.variant === 'negative' ? 'red' : 'blue'}
                _hover={{
                  transform: 'translateY(-1px)',
                  boxShadow: 'md'
                }}
                transition="all 0.2s"
              >
                {currentAlert.actionLabel}
              </Button>
            )}

            {currentAlert.isDismissable && (
              <>
                <Divider 
                  orientation="vertical" 
                  h="20px"
                  borderWidth="1px"
                  borderColor={variantStyles.color}
                  opacity="0.3"
                />
                <IconButton
                  size="sm"
                  icon={<FaTimes />}
                  variant="ghost"
                  onClick={handleDismiss}
                  aria-label="Dismiss alert"
                  _hover={{
                    transform: 'translateY(-1px)',
                    bg: `${variantStyles.color}10`
                  }}
                  transition="all 0.2s"
                />
              </>
            )}
          </HStack>
        </HStack>
      </Box>
    </Collapse>
  );
};

AlertBanner.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['neutral', 'informative', 'negative']),
  actionLabel: PropTypes.string,
  isDismissable: PropTypes.bool,
  organizationId: PropTypes.arrayOf(PropTypes.string),
  priority: PropTypes.number
};

AlertBanner.defaultProps = {
  variant: 'negative',
  isDismissable: false,
  priority: 1
};

export default AlertBanner;
