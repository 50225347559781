import React, { useState, useEffect } from 'react';
import {
  VStack, Box, Text, Icon, Heading, HStack, Link, Button,
  IconButton, Menu, MenuButton, MenuList, MenuItem, Tabs, TabList, Tab, TabPanels, TabPanel, UnorderedList, ListItem, useToast
} from '@chakra-ui/react';
import { 
  FaInfoCircle, FaEnvelope, FaPhone, FaExternalLinkAlt, 
  FaExclamationCircle, FaSync, FaCheck as CheckIcon, 
  FaTimes as CloseIcon, FaEdit as EditIcon, FaChartLine
} from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../../../../contexts/UserContext';
import { FeatureStats } from '../../../../enums/UsageStats';


const formatCategoryName = (categoryKey) => {
  return categoryKey
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
};

const InsightCard = ({ category, subcategory, insight, confidence, actionItems, sourceType, sourceId, onViewSource }) => {
  const [assignedUsers, setAssignedUsers] = useState({});
  const { user } = useUser();
  const [userCrms] = useState(user?.organization?.crms[0]?.crmUsers || []);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;
  const handleAssign = async (itemId, user) => {
    try {
      await axios.post(
        `${serverUrl}/api/action-items/${itemId}/assign`,
        { userId: user.id },
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      setAssignedUsers(prev => ({
        ...prev,
        [itemId]: {
          id: user.id,
          name: user.name
        }
      }));

      toast({
        title: "Action item assigned",
        description: `Successfully assigned to ${user.name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error assigning action item:', error);
      toast({
        title: "Error",
        description: "Failed to assign action item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleApprove = (actionItem) => {
    // Add approval logic
    console.log('Approving action item:', actionItem);
  };

  const handleReject = (actionItem) => {
    // Add rejection logic
    console.log('Rejecting action item:', actionItem);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      borderColor="blue.100"
      bg="blue.50"
      p={3}
      mb={4}
    >
      <VStack align="stretch" spacing={3}>
        {/* Insight Header */}
        <HStack spacing={3}>
          <Icon as={FaInfoCircle} color="blue.500" />
          <Text color="gray.700" fontWeight="medium">
            {formatCategoryName(category)} | {subcategory}
          </Text>
        </HStack>

        {/* Insight Content */}
        <Text color="gray.600" pl={7}>
          {insight}
        </Text>

        {/* Action Items Section */}
        {actionItems && actionItems.length > 0 && (
          <Box
            pl={7}
            mt={2}
            p={3}
            bg="white"
            borderRadius="md"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack align="stretch" spacing={3}>
              <HStack justify="space-between">
                <Text color="gray.700" fontWeight="medium">
                  Suggested Actions
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {actionItems.length} item{actionItems.length !== 1 ? 's' : ''}
                </Text>
              </HStack>

              {actionItems.map((item, idx) => {
                const actionItem = typeof item === 'string' ? JSON.parse(item) : item;
                return (
                  <Box
                    key={idx}
                    p={3}
                    bg="gray.50"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="gray.200"
                  >
                    <VStack align="stretch" spacing={3}>
                      {/* Title and Actions Row */}
                      <HStack justify="space-between" align="flex-start">
                        <VStack align="start" spacing={1} flex={1}>
                          <Text 
                            fontSize="sm"
                            fontWeight="medium"
                            color="gray.700"
                          >
                            {actionItem.description || actionItem.functionParameters?.newValue?.name}
                          </Text>
                          
                          {(actionItem.functionParameters?.newValue?.description || actionItem.rationale) && (
                            <Text fontSize="xs" color="gray.600">
                              {actionItem.functionParameters?.newValue?.description || actionItem.rationale}
                            </Text>
                          )}
                        </VStack>
                        <HStack spacing={2}>
                          <Button
                            size="sm"
                            colorScheme="green"
                            variant="ghost"
                            leftIcon={<CheckIcon />}
                            onClick={() => handleApprove(actionItem)}
                            isDisabled={!assignedUsers[item.id]}
                          >
                            Approve
                          </Button>
                          <IconButton
                            size="sm"
                            icon={<CloseIcon />}
                            colorScheme="red"
                            variant="ghost"
                            onClick={() => handleReject(actionItem)}
                          />
                        </HStack>
                      </HStack>

                      {/* Assignment and Edit Row */}
                      <HStack spacing={4} align="center">
                        <Menu>
                          <MenuButton
                            as={Button}
                            size="sm"
                            variant="outline"
                            rightIcon={<ChevronDownIcon />}
                            colorScheme="blue"
                          >
                            {assignedUsers[item.id]?.name ? assignedUsers[item.id].name : 'Assign To'}
                          </MenuButton>
                          <MenuList>
                            {userCrms.map((crm) => (
                              <MenuItem 
                                key={crm.id}
                                onClick={() => handleAssign(item.id, crm)}
                              >
                                {crm.name}
                              </MenuItem>
                            ))}
                            {userCrms.length === 0 && (
                              <MenuItem isDisabled>No users available</MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                        
                        <IconButton
                          size="sm"
                          icon={<EditIcon />}
                          variant="ghost"
                          onClick={async () => {
                            // Log the edit first
                            await axios.post(`${serverUrl}/api/usage/log`, {
                              statPath: FeatureStats.ACTION_ITEMS_EDITED,
                              value: 1
                            }, {
                              headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                              }
                            });
                            console.log('Edit action item:', actionItem);
                          }}
                          aria-label="Edit details"
                        />
                      </HStack>
                    </VStack>
                  </Box>
                );
              })}
            </VStack>
          </Box>
        )}

        {/* Source Link */}
        {sourceType && sourceId && (
          <HStack spacing={2} pl={7}>
            <Icon 
              as={sourceType === 'email' ? FaEnvelope : FaPhone} 
              color="gray.500" 
              boxSize={3}
            />
            <Link
              fontSize="sm"
              color="blue.500"
              onClick={() => onViewSource(sourceType, sourceId)}
              cursor="pointer"
            >
              View in {sourceType === 'email' ? 'Email' : 'Call'}
              <Icon as={FaExternalLinkAlt} boxSize={3} ml={1} />
            </Link>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

const AggregateInsightCard = ({ category, subcategory, insight, confidence, actionItems, metadata }) => {
  const [assignedUsers, setAssignedUsers] = useState({});
  const { user } = useUser();
  const [userCrms] = useState(user?.organization?.crms[0]?.crmUsers || []);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  const handleAssign = async (itemId, user) => {
    try {
      await axios.post(
        `${serverUrl}/api/action-items/${itemId}/assign`,
        { userId: user.id },
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      setAssignedUsers(prev => ({
        ...prev,
        [itemId]: {
          id: user.id,
          name: user.name
        }
      }));

      toast({
        title: "Action item assigned",
        description: `Successfully assigned to ${user.name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error assigning action item:', error);
      toast({
        title: "Error",
        description: "Failed to assign action item",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleApprove = (actionItem) => {
    console.log('Approving action item:', actionItem);
  };

  const handleReject = (actionItem) => {
    console.log('Rejecting action item:', actionItem);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      borderColor="purple.100"
      bg="purple.50"
      p={3}
      mb={4}
    >
      <VStack align="stretch" spacing={3}>
        {/* Insight Header */}
        <HStack spacing={3}>
          <Icon as={FaChartLine} color="purple.500" />
          <Text color="gray.700" fontWeight="medium">
            {formatCategoryName(category)} | {subcategory}
          </Text>
        </HStack>

        {/* Insight Content */}
        <Text color="gray.600" pl={7}>
          {insight}
        </Text>

        {/* Trends and Patterns */}
        {metadata?.trends && (
          <Box pl={7} mt={2}>
            <Text fontWeight="medium" color="gray.700" mb={2}>
              Trends
            </Text>
            <UnorderedList>
              {metadata.trends.map((trend, idx) => (
                <ListItem key={idx} color="gray.600">
                  {trend}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}

        {/* Action Items Section */}
        {actionItems && actionItems.length > 0 && (
          <Box
            pl={7}
            mt={2}
            p={3}
            bg="white"
            borderRadius="md"
            borderWidth="1px"
            borderColor="gray.200"
          >
            <VStack align="stretch" spacing={3}>
              <HStack justify="space-between">
                <Text color="gray.700" fontWeight="medium">
                  Suggested Actions
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {actionItems.length} item{actionItems.length !== 1 ? 's' : ''}
                </Text>
              </HStack>

              {actionItems.map((item, idx) => {
                const actionItem = typeof item === 'string' ? JSON.parse(item) : item;
                return (
                  <Box
                    key={idx}
                    p={3}
                    bg="gray.50"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="gray.200"
                  >
                    <VStack align="stretch" spacing={3}>
                      {/* Title and Actions Row */}
                      <HStack justify="space-between" align="flex-start">
                        <VStack align="start" spacing={1} flex={1}>
                          <Text 
                            fontSize="sm"
                            fontWeight="medium"
                            color="gray.700"
                          >
                            {actionItem.description || actionItem.functionParameters?.newValue?.name}
                          </Text>
                          
                          {(actionItem.functionParameters?.newValue?.description || actionItem.rationale) && (
                            <Text fontSize="xs" color="gray.600">
                              {actionItem.functionParameters?.newValue?.description || actionItem.rationale}
                            </Text>
                          )}
                        </VStack>
                        <HStack spacing={2}>
                          <Button
                            size="sm"
                            colorScheme="green"
                            variant="ghost"
                            leftIcon={<CheckIcon />}
                            onClick={() => handleApprove(actionItem)}
                            isDisabled={!assignedUsers[item.id]}
                          >
                            Approve
                          </Button>
                          <IconButton
                            size="sm"
                            icon={<CloseIcon />}
                            colorScheme="red"
                            variant="ghost"
                            onClick={() => handleReject(actionItem)}
                          />
                        </HStack>
                      </HStack>

                      {/* Assignment and Edit Row */}
                      <HStack spacing={4} align="center">
                        <Menu>
                          <MenuButton
                            as={Button}
                            size="sm"
                            variant="outline"
                            rightIcon={<ChevronDownIcon />}
                            colorScheme="blue"
                          >
                            {assignedUsers[item.id]?.name ? assignedUsers[item.id].name : 'Assign To'}
                          </MenuButton>
                          <MenuList>
                            {userCrms.map((crm) => (
                              <MenuItem 
                                key={crm.id}
                                onClick={() => handleAssign(item.id, crm)}
                              >
                                {crm.name}
                              </MenuItem>
                            ))}
                            {userCrms.length === 0 && (
                              <MenuItem isDisabled>No users available</MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                        
                        <IconButton
                          size="sm"
                          icon={<EditIcon />}
                          variant="ghost"
                          onClick={async () => {
                            // Log the edit first
                            await axios.post(`${serverUrl}/api/usage/log`, {
                              statPath: FeatureStats.ACTION_ITEMS_EDITED,
                              value: 1
                            }, {
                              headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                              }
                            });
                            console.log('Edit action item:', actionItem);
                          }}
                          aria-label="Edit details"
                        />
                      </HStack>
                    </VStack>
                  </Box>
                );
              })}
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

const ActionItemCard = ({ actionItem }) => {
  return (
    <Box
      p={3}
      bg="white"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.200"
    >
      <VStack align="stretch" spacing={2}>
        <Text fontSize="sm" fontWeight="medium" color="gray.700">
          {actionItem.description || actionItem.functionParameters?.newValue?.name}
        </Text>
        
        {(actionItem.functionParameters?.newValue?.description || actionItem.rationale) && (
          <Text fontSize="xs" color="gray.600">
            {actionItem.functionParameters?.newValue?.description || actionItem.rationale}
          </Text>
        )}
        
        <HStack justify="flex-end" spacing={2}>
          <Button
            size="xs"
            colorScheme="blue"
            variant="ghost"
            leftIcon={<CheckIcon />}
          >
            Approve
          </Button>
          <IconButton
            size="xs"
            icon={<CloseIcon />}
            colorScheme="red"
            variant="ghost"
            aria-label="Reject action item"
          />
        </HStack>
      </VStack>
    </Box>
  );
};

ActionItemCard.propTypes = {
  actionItem: PropTypes.shape({
    description: PropTypes.string,
    functionParameters: PropTypes.object,
    rationale: PropTypes.string
  }).isRequired
};

const InsightsPanel = ({ insights = [], onViewSource }) => {
  const [activeTab, setActiveTab] = useState('meeting');
  const [aggregateInsights, setAggregateInsights] = useState([]);
  const serverUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();
  const { user } = useUser();

  // Fetch aggregate insights when component mounts or when insights change
  useEffect(() => {
    if (insights[0]?.clientId) {
      fetchAggregateInsights(insights[0].clientId);
    }
  }, [insights[0]?.clientId]);

  
  // Check both conditions before showing insights
  if (!user?.betaFeaturesEnabled || !user?.settings?.aiInsightsEnabled) {
    return null;
  }
  const fetchAggregateInsights = async (clientId) => {
    try {
      const response = await axios.get(
        `${serverUrl}/api/clients/${clientId}/aggregate-insights`,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`,
          }
        }
      );
      setAggregateInsights(response.data.insights);
    } catch (error) {
      console.error('Error fetching aggregate insights:', error);
      toast({
        title: "Error",
        description: "Failed to fetch aggregate insights",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!insights?.length && !aggregateInsights?.length) {
    return (
      <Box p={4} bg="gray.50" borderRadius="lg" textAlign="center">
        <Icon as={FaExclamationCircle} color="gray.400" boxSize={6} mb={2} />
        <Text color="gray.500">No insights available yet</Text>
      </Box>
    );
  }

  return (
    <Box>
      <HStack justify="space-between" mb={4}>
        <Heading size="md">Client Insights</Heading>
      </HStack>
      
      <Tabs onChange={(index) => setActiveTab(index === 0 ? 'meeting' : 'aggregate')} mb={6}>
        <TabList>
          <Tab>Meeting Insights</Tab>
          <Tab>Aggregate Insights</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              {insights.map((insight, index) => (
                <InsightCard
                  key={index}
                  {...insight}
                  actionItems={insight.actionItems}
                  onViewSource={onViewSource}
                />
              ))}
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack align="stretch" spacing={4}>
              {aggregateInsights.map((insight, index) => (
                <AggregateInsightCard
                  key={index}
                  {...insight}
                  actionItems={insight.actionItems}
                />
              ))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

InsightCard.propTypes = {
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.string.isRequired,
  insight: PropTypes.string.isRequired,
  confidence: PropTypes.number,
  actionItems: PropTypes.array,
  sourceType: PropTypes.string,
  sourceId: PropTypes.string,
  onViewSource: PropTypes.func
};

AggregateInsightCard.propTypes = {
  category: PropTypes.string.isRequired,
  subcategory: PropTypes.string.isRequired,
  insight: PropTypes.string.isRequired,
  confidence: PropTypes.number,
  actionItems: PropTypes.array,
  metadata: PropTypes.object
};

InsightsPanel.propTypes = {
  insights: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string.isRequired,
    insight: PropTypes.string.isRequired,
    confidence: PropTypes.number,
    actionItems: PropTypes.array,
    sourceType: PropTypes.string,
    sourceId: PropTypes.string
  })),
  onViewSource: PropTypes.func
};

export default InsightsPanel; 