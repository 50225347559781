import React, { useState } from 'react';
import { Box, VStack, Text, Link, HStack, Image, Spacer, useToast, Button, useClipboard, IconButton } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { FiHome, FiUsers, FiPhone, FiSettings, FiLayers, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useUser } from '../../contexts/UserContext';

const Sidebar = () => {
  const { logout } = useAuth();
  const { user } = useUser();
  const toast = useToast();
  const { onCopy } = useClipboard(`${window.location.origin}/signup?orgId=${user?.organization?.id}`);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const linkStyles = {
    display: 'block',
    padding: '10px 0',
    width: '100%',
    textAlign: 'left',
    transition: 'all 0.2s ease-in-out',
    borderRadius: 'md',
    position: 'relative',
    _before: {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '3px',
      bg: 'transparent',
      transition: 'all 0.2s ease-in-out',
      borderRadius: '5px 0 0 5px',
    }
  };

  const iconStyles = {
    color: '#4A5568',
    strokeWidth: '1.5',
    transition: 'transform 0.2s ease-in-out',
    flexShrink: 0
  };
  
  const handleInviteClick = () => {
    onCopy();
    toast({
      title: "Invite link copied!",
      description: "Share this link with your colleagues to invite them to your organization.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box 
      as="nav" 
      p={4} 
      width={isCollapsed ? "80px" : "12%"}
      minWidth={isCollapsed ? "80px" : "200px"}
      height="100vh" 
      pt={'50px'} 
      bg="white" 
      position="sticky" 
      top="0"
      boxShadow="sm"
      borderRight="1px" 
      borderColor="gray.100"
      transition="all 0.3s ease"
    >
      <IconButton
        icon={isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
        position="absolute"
        right="-6px"
        top="16px"
        paddingRight="5px"
        transform="none"
        variant="ghost"
        size="lg"
        bg="transparent"
        boxShadow="none"
        onClick={() => setIsCollapsed(!isCollapsed)}
        zIndex={2}
        _hover={{ bg: 'transparent' }}
        color="gray.500"
        fontWeight="bold"
      />

      <Box display="flex" flexDirection="column" alignItems="center">
        <Link as={NavLink} to="/" _hover={{ opacity: 0.8 }}>
          <Image 
            src="/icons/Primary Logo.png" 
            alt="datadasher icon" 
            marginBottom={4} 
            mt={4} 
            maxW={isCollapsed ? "40px" : "90%"}
            height="auto" 
          />
        </Link>
        {!isCollapsed && (
          <Box px={4} mt={2} mb={4} textAlign="center">
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight="medium">
              {user?.firstName} {user?.lastName}
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }} color="gray.600">
              {user?.organization?.name}
            </Text>
          </Box>
        )}
      </Box>

      <VStack spacing={1} align="stretch">
        <Link as={NavLink}
              to="/"
              sx={linkStyles}
              _hover={{ 
                bg: 'gray.50',
                transform: 'translateX(2px)',
                _before: { bg: 'blue.200' }
              }}
              _activeLink={{ 
                bg: 'blue.50', 
                _before: { bg: 'blue.500' },
                fontWeight: 'medium'
              }}>
          <HStack px={4} justify={isCollapsed ? "center" : "flex-start"}>
            <FiHome size="20px" style={iconStyles} />
            {!isCollapsed && (
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Home</Text>
            )}
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/clients"
              sx={linkStyles}
              _hover={{ 
                bg: 'gray.50',
                transform: 'translateX(2px)',
                _before: { bg: 'blue.200' }
              }}
              _activeLink={{ 
                bg: 'blue.50', 
                _before: { bg: 'blue.500' },
                fontWeight: 'medium'
              }}>
          <HStack px={4} justify={isCollapsed ? "center" : "flex-start"}>
            <FiUsers size="20px" style={iconStyles} />
            {!isCollapsed && (
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Clients</Text>
            )}
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/meetings"
              sx={linkStyles}
              _hover={{ 
                bg: 'gray.50',
                transform: 'translateX(2px)',
                _before: { bg: 'blue.200' }
              }}
              _activeLink={{ 
                bg: 'blue.50', 
                _before: { bg: 'blue.500' },
                fontWeight: 'medium'
              }}>
          <HStack px={4} justify={isCollapsed ? "center" : "flex-start"}>
            <FiPhone size="20px" style={iconStyles} />
            {!isCollapsed && (
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Meetings</Text>
            )}
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/settings"
              sx={linkStyles}
              _hover={{ 
                bg: 'gray.50',
                transform: 'translateX(2px)',
                _before: { bg: 'blue.200' }
              }}
              _activeLink={{ 
                bg: 'blue.50', 
                _before: { bg: 'blue.500' },
                fontWeight: 'medium'
              }}>
          <HStack px={4} justify={isCollapsed ? "center" : "flex-start"}>
            <FiSettings size="20px" style={iconStyles} />
            {!isCollapsed && (
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Settings</Text>
            )}
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/integrations"
              sx={linkStyles}
              _hover={{ 
                bg: 'gray.50',
                transform: 'translateX(2px)',
                _before: { bg: 'blue.200' }
              }}
              _activeLink={{ 
                bg: 'blue.50', 
                _before: { bg: 'blue.500' },
                fontWeight: 'medium'
              }}>
          <HStack px={4} justify={isCollapsed ? "center" : "flex-start"}>
            <FiLayers size="20px" style={iconStyles} />
            {!isCollapsed && (
              <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Integrations</Text>
            )}
          </HStack>
        </Link>

        <Spacer />
        
        {!isCollapsed && (
          <>
            <Link
              as="button"
              onClick={logout}
              sx={linkStyles}
              _hover={{ 
                bg: 'red.50', 
                color: 'red.500',
                transform: 'translateX(2px)',
                _before: { bg: 'red.200' }
              }}
            >
              <HStack px={4}>
                <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Logout</Text>
              </HStack>
            </Link>

            <Button
              onClick={handleInviteClick}
              size="sm"
              colorScheme="blue"
              variant="ghost"
              mt={2}
              fontSize={{ base: "xs", md: "sm" }}
            >
              Invite Colleagues
            </Button>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default Sidebar;