import React from 'react';
import { VStack, HStack, Box, Text, Input, Button, Link } from '@chakra-ui/react';
import { LinkIcon } from '@chakra-ui/icons';
import { FeatureStats } from '../../../../enums/UsageStats';
import axios from 'axios';
import Cookies from 'js-cookie';

const serverUrl = process.env.REACT_APP_API_URL;

const ChatPanel = ({ 
  chatHistory, 
  inputValue, 
  setInputValue, 
  handleAskQuestion, 
  loadingChat,
  handleViewEmail,
  formatDate 
}) => {


  return (
    <VStack spacing={4} align="stretch">
      {chatHistory.map((chat, index) => (
        <Box key={index} p={4} borderWidth={1} borderRadius="md">
          <Text fontWeight="bold">Q: {chat.question}</Text>
          <Text mt={2}>A: {chat.answer.answer}</Text>
          {chat.answer.sources?.map((source, sourceIndex) => (
            <Box key={sourceIndex} mt={2}>
              {source.source === 'vector' && source.vectorMetadata && (
                <HStack mt={2}>
                  <LinkIcon />
                  <Link
                    color="blue.500"
                    onClick={() => {
                      if (source.vectorMetadata.callId) {
                        const newTabUrl = `/transcripts/${source.vectorMetadata.callId}`;
                        window.open(newTabUrl, '_blank');
                      } else {
                        handleViewEmail(source.vectorMetadata.emailId);
                      }
                    }}
                  >
                    {source.vectorMetadata.subject || source.vectorMetadata.summary} - {
                      source.vectorMetadata.startTime ? 
                      formatDate(source.vectorMetadata.startTime) : 
                      formatDate(source.vectorMetadata.receivedAt)
                    }
                  </Link>
                </HStack>
              )}
              {source.source === 'metadata' && (
                <Text fontSize="xs" color="gray.500" mt={2}>
                  * The client&apos;s CRM data was used to answer this question.
                </Text>
              )}
            </Box>
          ))}
        </Box>
      ))}
      <HStack>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Ask about this client..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAskQuestion();
            }
          }}
        />
        <Button
          colorScheme="blue"
          onClick={handleAskQuestion}
          isLoading={loadingChat}
        >
          Ask
        </Button>
      </HStack>
    </VStack>
  );
};

export default ChatPanel; 