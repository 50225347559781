import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FeatureStats } from '../../../../enums/UsageStats';

export const useChat = (id, serverUrl, toast) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loadingChat, setLoadingChat] = useState(false);

  const handleAskQuestion = async () => {
    if (!inputValue.trim()) return;

    // Log the Ask About query
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.ASK_ABOUT_QUERIES,
      value: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });

    setLoadingChat(true);
    try {
      const response = await axios.post(`${serverUrl}/api/clients/${id}/ask-about`, {
        questions: [inputValue],
        answers: []
      });


      setChatHistory([...chatHistory, {
        question: inputValue,
        answer: response.data.completion
      }]);
      setInputValue('');
    } catch (error) {
      toast({
        title: "Error sending question",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingChat(false);
    }
  };

  return {
    chatHistory,
    inputValue,
    loadingChat,
    handleAskQuestion,
    setInputValue
  };
}; 