// Time-based stats paths
export const TimeStats = {
  PLATFORM_TOTAL: 'timeStats.platformTotal',
  PAGE_HOME: 'timeStats.pageHomeTime',
  PAGE_CLIENTS: 'timeStats.pageClientsTime',
  PAGE_MEETINGS: 'timeStats.pageMeetingsTime',
  PAGE_SETTINGS: 'timeStats.pageSettingsTime',
  PAGE_TRANSCRIPTS: 'timeStats.pageTranscriptsTime',
  LAST_LOGIN: 'timeStats.lastLogin',
  MONTHLY_LOGIN_COUNT: 'timeStats.monthlyLoginCount',
  LOGIN_TIMESTAMPS: 'timeStats.loginTimestamps' // Array of login timestamps
};

// Feature usage stats paths
export const FeatureStats = {
  ACTION_ITEMS_APPROVED: 'featureStats.actionItemsApproved',
  ACTION_ITEMS_EDITED: 'featureStats.actionItemsEdited',
  EMAILS_GENERATED: 'featureStats.emailsGenerated',
  CRM_RESYNCS: 'featureStats.crmResyncs',
  MEETINGS_WEB_APP: 'featureStats.meetingsWebApp',
  MEETINGS_MOBILE_APP: 'featureStats.meetingsMobileApp',
  MEETINGS_UPLOADED: 'featureStats.meetingsUploaded',
  CLIENTS_SEARCHED: 'featureStats.clientsSearched',
  CLIENTS_FAVORITED: 'featureStats.clientsFavorited',
  ASK_ABOUT_QUERIES: 'featureStats.askAboutQueriesGenerated',
  CORRESPONDENCE_ARCHIVED: 'featureStats.correspondenceArchived',
  TRANSCRIPT_VIEWS: 'featureStats.transcriptViews',
  WEB_CONFERENCE_MEETINGS: 'featureStats.webConferenceMeetings',
  SUMMARIES_EDITED: 'featureStats.summariesEdited',
  TRANSCRIPTION_TITLES_EDITED: 'featureStats.transcriptionTitlesEdited',
  TRANSCRIPTION_BODY_EDITED: 'featureStats.transcriptionBodyEdited',
  SPEAKER_NAMES_REASSIGNED: 'featureStats.speakerNamesReassigned',
}; 