import React from 'react';
import { Box, HStack, VStack, Switch, Button, Icon, Tooltip, useToast, Text, IconButton, Menu, MenuButton, MenuList, MenuItem, Heading, Spinner, Input, Badge } from '@chakra-ui/react';
import RowItem from './RowItem';
import ActionButton from './ActionButton';
import { FiCheckCircle, FiUser, FiExternalLink } from 'react-icons/fi';
import { CheckIcon, CloseIcon, CheckCircleIcon, ExternalLinkIcon, ViewOffIcon, ViewIcon, ChevronDownIcon, CalendarIcon, EditIcon } from '@chakra-ui/icons';
import { IoMdHappy, IoMdSad } from 'react-icons/io';
import { FaRegUser, FaRegEnvelope } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useUser } from '../contexts/UserContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { FeatureStats, ACTION_ITEMS_APPROVED, ACTION_ITEMS_EDITED } from '../enums/UsageStats';

const BlueBox = ({ actions, transcriptId, client, id, type, scrollRef, onArchive, correspondence, isDisabled, onActionUpdate }) => {
  const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/'
  const toast = useToast();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const serverUrl = process.env.REACT_APP_API_URL;
  const [actionItems, setActionItems] = useState(actions);
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const [stateTranscriptId, setStateTranscriptId] = useState(transcriptId);
  const [stateClient, setStateClient] = useState(client);
  const [stateId, setStateId] = useState(id);
  const [stateType, setStateType] = useState(type);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const { user } = useUser(); // Use the global user context
  const [userCrms, setUserCrms] = useState(user?.organization?.crms || []); // State to hold user CRMs
  const [assignedUsers, setAssignedUsers] = useState({}); // { actionItemId: userName }
  const [isLoadingArchived, setIsLoadingArchived] = useState(false);
  const [loadingItems, setLoadingItems] = useState({}); // Track loading state for individual items
  const [isApprovingAll, setIsApprovingAll] = useState(false); // Track loading state for approve all
  const [editableDates, setEditableDates] = useState({}); // Add this state
  const [editableNames, setEditableNames] = useState({});
  const [editableDescriptions, setEditableDescriptions] = useState({});
  const [isEditing, setIsEditing] = useState({}); // Track which fields are being edited
  const [editingId, setEditingId] = useState(null);

  const formatDateForCRM = (dateString) => {
    try {
      // Ensure we have a valid date string
      if (!dateString) return null;
      
      // Parse the date string and create a date object at noon UTC
      // This prevents any timezone issues when setting the time later
      const date = new Date(dateString + 'T12:00:00Z');
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.warn('Invalid date string provided:', dateString);
        return null;
      }
      
      // Create a new date object in the local timezone
      const localDate = new Date(date.toLocaleDateString());
      
      // Set to 6 PM in local timezone
      localDate.setHours(18, 0, 0, 0);
      
      // Convert to UTC ISO string for CRM
      const utcString = localDate.toISOString();
      
      console.log('Original date string:', dateString);
      console.log('Formatted local date:', localDate.toString());
      console.log('UTC string for CRM:', utcString);
      
      return utcString;
    } catch (error) {
      console.error('Error formatting date:', error);
      return null;
    }
  };

  
  useEffect(() => {
    setStateTranscriptId(transcriptId);
    setStateClient(client);
    setStateId(id);
    setStateType(type);
    setStateCorrespondence(correspondence);
  }, [transcriptId, client, id, type, onArchive]);

  useEffect(() => {
    // console.log(actions);
    setActionItems(actions);
  }, [actions]);

  const handleDateChange = async (itemId, newDate) => {
    // Log edit action for date changes
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.ACTION_ITEMS_EDITED,
      value: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });

    setEditableDates(prev => ({
      ...prev,
      [itemId]: newDate
    }));

    setActionItems(prevItems => prevItems.map(item => {
      if (item.id === itemId) {
        const formattedDate = formatDateForCRM(newDate);
        if (!formattedDate) return item; // Skip update if date is invalid

        if (!item.functionParameters) {
          return {
            ...item,
            functionParameters: {
              newValue: {
                dueDate: formattedDate,
                name: item.description,
                priority: 'medium'
              },
              objectType: 'Task'
            }
          };
        }
        if (!item.functionParameters.newValue) {
          return {
            ...item,
            functionParameters: {
              ...item.functionParameters,
              newValue: {
                dueDate: formattedDate,
                name: item.description,
                priority: 'medium'
              }
            }
          };
        }
        return {
          ...item,
          functionParameters: {
            ...item.functionParameters,
            newValue: {
              ...item.functionParameters.newValue,
              dueDate: formattedDate
            }
          }
        };
      }
      return item;
    }));
  };

  const getDefaultDueDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    // Return just the date portion for the input field
    return date.toISOString().split('T')[0];
  };

  

  const handleApprove = async (item) => {
    try {
      setLoadingItems(prev => ({ ...prev, [item.id]: true }));
      
      // Log the action item approval
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.ACTION_ITEMS_APPROVED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      // Include the updated due date if it exists
      const updatedFunctionParameters = item.functionParameters?.newValue 
        ? {
            ...item.functionParameters,
            newValue: {
              ...item.functionParameters.newValue,
              dueDate: editableDates[item.id] || item.functionParameters.newValue.dueDate
            }
          }
        : item.functionParameters;

      await axios.post(`${serverUrl}/api/actionItems/${item.id}/approve`, {
        assignedUserId: assignedUsers[item.id]?.id,
        functionParameters: updatedFunctionParameters
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
      
      // Update the item
      const updatedItem = { ...item, aiCompletionStatus: 'SUCCESS' };
      
      // Notify parent component of the update
      onActionUpdate?.({ 
        type: 'APPROVE',
        correspondenceId: id,
        correspondenceType: type,
        actionItem: updatedItem
      });
      
      return updatedItem;
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: "Error",
        description: `There was an error approving the action item: ${error.response?.data?.error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return item; // Return original item if there's an error
    } finally {
      setLoadingItems(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleArchive = async (item) => {
    try {
      await axios.post(`${serverUrl}/api/action-items/${item.id}/archive`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        },
      });

      // Notify parent component of the archive
      onActionUpdate?.({
        type: 'ARCHIVE',
        correspondenceId: id,
        correspondenceType: type,
        actionItem: item
      });

    } catch (error) {
      console.error('Error archiving action item:', error);
      toast({
        title: "Error",
        description: "Failed to archive action item",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleUnarchive = (item) => {
    console.log('Unarchive');
    axios.post(`${serverUrl}/api/action-items/${item.id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleShowArchived = async (showArchived) => {
    setIsLoadingArchived(true);
    try {
      if (type === 'call') {
        const response = await axios.get(`${serverUrl}/api/calls/${id}/action-items?showArchived=${showArchived}`, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        setActionItems(response.data);
      } else if (type === 'email') {
        const response = await axios.get(`${serverUrl}/api/emails/${id}/action-items?showArchived=${showArchived}`, {
          headers: {
            'Content-Type': 'application/json'
          },
        });
        setActionItems(response.data);
      }
    } catch (error) {
      console.error('Error fetching archived actions:', error);
    } finally {
      setIsLoadingArchived(false);
    }
  };
  const handleFeedback = (feedback) => {
    console.log('Feedback:', feedback);
    let data = {
      details: feedback,
    }

    axios.post(`${serverUrl}/api/feedback/${feedbackId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
      setShowFeedbackModal(false);
      toast({
        title: "Feedback Submitted",
        description: "Thank you for your feedback!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  }

  const handleResync = async () => {
    try {
      console.log('Resyncing client:', stateClient);

      // Log the resync
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.CRM_RESYNCS,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      const response = await axios.post(`${serverUrl}/api/clients/${stateClient[0].id}/resync`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      if (response.data.message) {
        toast({
          title: "Resync Initiated",
          description: "Client data resync has been initiated. This may take a few moments.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.data.message || "Failed to initiate resync");
      }
    } catch (error) {
      console.error("Error initiating resync:", error);
      toast({
        title: "Resync Failed",
        description: `Failed to initiate resync: ${error.message}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAssign = (actionItemId, user) => {
    setAssignedUsers(prev => ({
      ...prev,
      [actionItemId]: user
    }));
    
    toast({
      title: "Task Assigned",
      description: `Task assigned to ${user.name}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const areAllPendingItemsAssigned = () => {
    return actionItems
      ?.filter(item => item.aiAction && item.aiCompletionStatus === 'PENDING')
      .every(item => assignedUsers[item.id]);
  };

  const handleApproveAll = async () => {
    setIsApprovingAll(true);
    toast({
      title: "Approving All Action Items",
      description: `Now executing all action items...`,
      status: "info",
      duration: 1000,
      isClosable: true,
    });

    const pendingItems = actionItems
      .filter(item => item.aiAction && item.aiCompletionStatus === 'PENDING');

    try {
      // Set loading state for all pending items
      const loadingStates = pendingItems.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});
      setLoadingItems(loadingStates);

     // Log bulk approvals
    await axios.post(`${serverUrl}/api/usage/log`, {
      statPath: FeatureStats.ACTION_ITEMS_APPROVED,
      value: pendingItems.length
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    });

      // Use Promise.all to handle all approvals concurrently
      const updatedItems = await Promise.all(
        pendingItems.map(item => handleApprove(item))
      );

      // Notify parent component of bulk update
      onActionUpdate?.({
        type: 'APPROVE_ALL',
        correspondenceId: id,
        correspondenceType: type,
        actionItems: updatedItems
      });

      // Create a map of updated items for efficient lookup
      const updatedItemsMap = updatedItems.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      // Update the state with all items at once
      setActionItems(prevItems => 
        prevItems.map(item => 
          updatedItemsMap[item.id] ? updatedItemsMap[item.id] : item
        )
      );

      toast({
        title: "Success",
        description: "All action items have been approved",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error in bulk approval:', error);
      toast({
        title: "Error",
        description: "There was an error approving some items",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsApprovingAll(false);
      setLoadingItems({});
    }
  };

  const handleTextEdit = (itemId, field, value) => {
    if (field === 'name') {
      setEditableNames(prev => ({
        ...prev,
        [itemId]: value
      }));
    } else if (field === 'description') {
      setEditableDescriptions(prev => ({
        ...prev,
        [itemId]: value
      }));
    }

    setActionItems(prevItems => prevItems.map(item => {
      if (item.id === itemId) {
        if (!item.functionParameters) {
          return {
            ...item,
            functionParameters: {
              newValue: {
                name: field === 'name' ? value : item.description,
                description: field === 'description' ? value : undefined,
                dueDate: editableDates[itemId] || getDefaultDueDate(),
                priority: 'medium'
              },
              objectType: 'Task'
            }
          };
        }
        return {
          ...item,
          functionParameters: {
            ...item.functionParameters,
            newValue: {
              ...item.functionParameters.newValue,
              [field]: value
            }
          }
        };
      }
      return item;
    }));
  };

  const onEditToggle = async (field, itemId) => {
    const newIsEditing = !isEditing[field];
    setIsEditing(prev => ({
      ...prev,
      [field]: newIsEditing
    }));

    // Only log when finishing an edit
    if (!newIsEditing) {
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.ACTION_ITEMS_EDITED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        style={{
          opacity: isDisabled ? 0.7 : 1,
          filter: isDisabled ? 'grayscale(0.5)' : 'none',
        }}
      >
        <Box 
          mt={2} 
          bg={isDisabled ? '#E2E8ED' : '#C4D1DD'} 
          borderRadius={'8px'} 
          p={5}
          position="relative"
          transition="all 0.3s ease"
        >
          {/* Add disabled overlay if needed */}
          {isDisabled && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              borderRadius={'8px'}
              bg="blackAlpha.50"
              zIndex={1}
              cursor="not-allowed"
            />
          )}

          <HStack w={'100%'} justify="space-between" mb={4} align="center">
            <Heading size="md" color={isDisabled ? '#4A5568' : '#00345B'}>Action Items</Heading>
            <HStack>
              <Text color={isDisabled ? 'gray.500' : 'inherit'}>View Archived Actions</Text>
              <Switch
                isDisabled={isDisabled || isLoadingArchived}
                onChange={(e) => {
                  const showArchived = e.target.checked;
                  handleShowArchived(showArchived);
                  setShowArchived(showArchived);
                }}
                sx={{
                  '& > span': {
                    opacity: isDisabled || isLoadingArchived ? 0.7 : 1,
                    transition: 'opacity 0.2s'
                  }
                }}
              />
            </HStack>
          </HStack>

          {/* Update the action items container */}
          <VStack 
            w={'100%'} 
            align={'stretch'} 
            spacing={4}
            opacity={isDisabled ? 0.8 : 1}
            transition="opacity 0.3s ease"
          >
            {isLoadingArchived ? (
              <AnimatePresence mode="wait">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  style={{ width: '100%' }}
                >
                  {[1, 2, 3].map((index) => (
                    <Box
                      key={index}
                      w="100%"
                      bg="white"
                      p={3}
                      borderRadius="md"
                      boxShadow="sm"
                      mb={3}
                    >
                      <HStack w={'100%'} justify={'space-between'} align="center">
                        <HStack flex={1} spacing={4}>
                          <HStack spacing={2}>
                            <Skeleton height="24px" width="24px" borderRadius="md" />
                            <Skeleton height="24px" width="24px" borderRadius="md" />
                          </HStack>
                          <Skeleton height="20px" width="60%" />
                        </HStack>
                        <HStack spacing={3}>
                          <Skeleton height="32px" width="120px" borderRadius="md" />
                          <Skeleton height="32px" width="32px" borderRadius="md" />
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
                </motion.div>
              </AnimatePresence>
            ) : actionItems?.length === 0 ? (
              <Text>No action items found</Text>
            ) : (
              <AnimatePresence mode="wait">
                {actionItems?.map((item, index) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 20 }}
                    transition={{ duration: 0.2, delay: index * 0.1 }}
                    style={{ width: '100%' }}
                  >
                    <Box
                      w="100%"
                      bg={isDisabled ? "gray.50" : "white"}
                      p={3}
                      borderRadius="md"
                      boxShadow="sm"
                      _hover={{ boxShadow: isDisabled ? 'sm' : 'md' }}
                      transition="all 0.2s"
                      position="relative"
                    >
                      <VStack align="stretch" spacing={2}>
                        <HStack w={'100%'} justify={'space-between'} align="center">
                          <HStack flex={1} spacing={4}>
                            {(item.aiAction && !item.archived && 
                              (item.aiCompletionStatus === 'PENDING' || item.aiCompletionStatus === 'ERROR')) && (
                              <HStack spacing={2}>
                                <motion.div
                                  initial={{ scale: 0 }}
                                  animate={{ scale: 1 }}
                                  exit={{ scale: 0 }}
                                >
                                  <Tooltip 
                                    label={isDisabled ? 
                                      "Please assign a client first" : 
                                      (assignedUsers[item.id] ? "Execute Action" : "Assign task before executing")
                                    }
                                  >
                                    <IconButton
                                      aria-label="Execute Action Item"
                                      icon={loadingItems[item.id] ? <Spinner size="xs" /> : <CheckIcon />}
                                      colorScheme="green"
                                      size="xs"
                                      variant="ghost"
                                      isDisabled={isDisabled || !assignedUsers[item.id] || loadingItems[item.id]}
                                      onClick={() => handleApprove(item).then(updatedItem => {
                                        setActionItems(prevItems => prevItems.map(i => i.id === item.id ? updatedItem : i));
                                      })}
                                      _hover={{ transform: 'scale(1.1)', bg: 'green.50' }}
                                      transition="all 0.2s"
                                    />
                                  </Tooltip>
                                </motion.div>
                                <motion.div
                                  initial={{ scale: 0 }}
                                  animate={{ scale: 1 }}
                                  exit={{ scale: 0 }}
                                >
                                  <Tooltip label="Remove Action Item">
                                    <IconButton
                                      aria-label="Remove Action Item"
                                      icon={<CloseIcon />}
                                      colorScheme="red"
                                      variant="ghost"
                                      size="xs"
                                      onClick={async () => {

                                        const data = {
                                          details: '',
                                          sentiment: 'NEGATIVE',
                                          actionItemId: item.id,
                                        };
                                        axios.post(`${serverUrl}/api/feedback`, data, {
                                          headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                                          },
                                        }).then(res => {
                                          setFeedbackId(res.data.id);
                                          handleArchive(item);
                                          setActionItems(actionItems.filter(i => i.id !== item.id));
                                          toast({
                                            title: "Action Item Removed",
                                            description: (
                                              <Button variant="link" onClick={() => setShowFeedbackModal(true)}>
                                                Click to provide feedback
                                              </Button>
                                            ),
                                            status: "info",
                                            duration: 5000,
                                            isClosable: true,
                                          });
                                        });
                                      }}
                                      _hover={{ transform: 'scale(1.1)', bg: 'red.50' }}
                                      transition="all 0.2s"
                                    />
                                  </Tooltip>
                                </motion.div>
                              </HStack>
                            )}
                            <motion.div
                              animate={{
                                opacity: item.aiCompletionStatus === 'SUCCESS' ? 0.5 : 1,
                                textDecoration: item.aiCompletionStatus === 'SUCCESS' ? 'line-through' : 'none'
                              }}
                              transition={{ duration: 0.3 }}
                              style={{ flex: 1 }}
                            >
                              <HStack>
                                {isEditing[`${item.id}-name`] ? (
                                  <Input
                                    size="sm"
                                    value={
                                      editableNames[item.id] || 
                                      item.functionParameters?.newValue?.name || 
                                      item.description
                                    }
                                    onChange={(e) => handleTextEdit(item.id, 'name', e.target.value)}
                                    onBlur={() => onEditToggle('name', item.id)}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        onEditToggle('name', item.id);
                                      }
                                    }}
                                    autoFocus
                                    bg="white"
                                    isDisabled={isDisabled || item.aiCompletionStatus === 'SUCCESS'}
                                  />
                                ) : (
                                  <Text 
                                    fontWeight="medium"
                                    onClick={() => {
                                      if (!isDisabled && item.aiCompletionStatus !== 'SUCCESS') {
                                        onEditToggle('name', item.id);
                                      }
                                    }}
                                    cursor={isDisabled || item.aiCompletionStatus === 'SUCCESS' ? 'default' : 'pointer'}
                                    _hover={{
                                      bg: isDisabled || item.aiCompletionStatus === 'SUCCESS' ? 'transparent' : 'gray.50'
                                    }}
                                    p={1}
                                    borderRadius="md"
                                    position="relative"
                                    role="group"
                                  >
                                    <HStack spacing={2}>
                                      <Text>{item.functionParameters?.newValue?.name || item.description}</Text>
                                      {!isDisabled && item.aiCompletionStatus !== 'SUCCESS' && (
                                        <EditIcon 
                                          boxSize={3}
                                          color="gray.400"
                                          opacity={0}
                                          _groupHover={{ opacity: 1 }}
                                          transition="opacity 0.2s"
                                        />
                                      )}
                                    </HStack>
                                  </Text>
                                )}
                              </HStack>
                              
                              {(item.functionParameters?.newValue?.description || isEditing[`${item.id}-description`]) && (
                                <Box mt={1}>
                                  {isEditing[`${item.id}-description`] ? (
                                    <Input
                                      size="sm"
                                      value={
                                        editableDescriptions[item.id] || 
                                        item.functionParameters?.newValue?.description || 
                                        ''
                                      }
                                      onChange={(e) => handleTextEdit(item.id, 'description', e.target.value)}
                                      onBlur={() => onEditToggle('description', item.id)}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          onEditToggle('description', item.id);
                                        }
                                      }}
                                      placeholder="Add description..."
                                      autoFocus
                                      bg="white"
                                      isDisabled={isDisabled || item.aiCompletionStatus === 'SUCCESS'}
                                    />
                                  ) : (
                                    <Text 
                                      fontSize="sm" 
                                      color="gray.600"
                                      onClick={() => {
                                        if (!isDisabled && item.aiCompletionStatus !== 'SUCCESS') {
                                          onEditToggle('description', item.id);
                                        }
                                      }}
                                      cursor={isDisabled || item.aiCompletionStatus === 'SUCCESS' ? 'default' : 'pointer'}
                                      _hover={{
                                        bg: isDisabled || item.aiCompletionStatus === 'SUCCESS' ? 'transparent' : 'gray.50'
                                      }}
                                      p={1}
                                      borderRadius="md"
                                      position="relative"
                                      role="group"
                                    >
                                      <HStack spacing={2}>
                                        <Text>{item.functionParameters?.newValue?.description || 'Add description...'}</Text>
                                        {!isDisabled && item.aiCompletionStatus !== 'SUCCESS' && (
                                          <EditIcon 
                                            boxSize={3}
                                            color="gray.400"
                                            opacity={0}
                                            _groupHover={{ opacity: 1 }}
                                            transition="opacity 0.2s"
                                          />
                                        )}
                                      </HStack>
                                    </Text>
                                  )}
                                </Box>
                              )}
                              
                              {/*item.functionParameters?.newValue?.priority && (
                                <Badge 
                                  colorScheme={
                                    item.functionParameters.newValue.priority === 'high' ? 'red' :
                                    item.functionParameters.newValue.priority === 'medium' ? 'yellow' :
                                    'green'
                                  }
                                >
                                  {item.functionParameters.newValue.priority}
                                </Badge>
                              )}*/}
                            </motion.div>
                          </HStack>

                          <HStack spacing={3}>
                            <HStack 
                              bg="gray.50" 
                              p={1} 
                              borderRadius="md" 
                              spacing={1}
                            >
                              <CalendarIcon color="gray.500" />
                              <Text fontSize="xs" color="gray.600" fontWeight="medium">
                                Due:
                              </Text>
                              <Input
                                type="date"
                                size="sm"
                                width="auto"
                                value={
                                  editableDates[item.id] || 
                                  (item.functionParameters?.newValue?.dueDate && 
                                    (() => {
                                      try {
                                        const date = new Date(item.functionParameters.newValue.dueDate);
                                        return isNaN(date.getTime()) ? getDefaultDueDate() : date.toISOString().split('T')[0];
                                      } catch {
                                        return getDefaultDueDate();
                                      }
                                    })()
                                  ) || 
                                  getDefaultDueDate()
                                }
                                onChange={(e) => handleDateChange(item.id, e.target.value)}
                                isDisabled={isDisabled || item.aiCompletionStatus === 'SUCCESS'}
                                bg="white"
                              />
                            </HStack>
                            
                            {user?.organization?.settings?.manuallyAssignTasks != false && (
                              <Menu>
                                <Tooltip label={!userCrms[0]?.crmUsers?.length ? "Please sync your CRM to assign tasks" : ""}>
                                  <MenuButton
                                    as={Button}
                                    rightIcon={<ChevronDownIcon />}
                                    size="sm"
                                    variant="outline"
                                    colorScheme="blue"
                                    isDisabled={!userCrms[0]?.crmUsers?.length || item.aiCompletionStatus === 'SUCCESS'}
                                    opacity={item.aiCompletionStatus === 'SUCCESS' ? 0.7 : 1}
                                    _hover={{ bg: 'blue.50' }}
                                    bg="white"
                                  >
                                    <HStack spacing={1}>
                                      <Icon as={FaRegUser} color="gray.500" />
                                      <Text>
                                        {item.aiCompletionStatus === 'SUCCESS' ? 
                                    `✓ ${assignedUsers[item.id]?.name || userCrms[0]?.crmUsers?.find(u => u.id == item.assignedUserId)?.name || 'DataDasher'}` :
                                    assignedUsers[item.id] ? 
                                      ` ${assignedUsers[item.id].name}` : 
                                      '+ Assign'
                                  }
                                      </Text>
                                    </HStack>
                                  </MenuButton>
                                </Tooltip>
                                <MenuList>
                                  <AnimatePresence>
                                    {userCrms[0]?.crmUsers?.map(user => (
                                      <motion.div
                                        key={user.id}
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 10 }}
                                      >
                                        <MenuItem
                                          onClick={() => handleAssign(item.id, user)}
                                          icon={<Icon as={FaRegUser} />}
                                        >
                                          {user.name}
                                        </MenuItem>
                                      </motion.div>
                                    ))}
                                  </AnimatePresence>
                                </MenuList>
                              </Menu>
                            )}
                            
                            <motion.div
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.95 }}
                            >
                              <Tooltip label={showArchived ? "Unarchive" : "Archive"}>
                                <IconButton
                                  size="sm"
                                  icon={showArchived ? <ViewIcon /> : <ViewOffIcon />}
                                  variant="ghost"
                                  colorScheme="gray"
                                  onClick={async () => {
                                    if (showArchived) {
                                      handleUnarchive(item);
                                    } else {
                                      handleArchive(item);
                                    }
                                    setActionItems(actionItems.filter(i => i.id !== item.id));
                                  }}
                                  _hover={{ bg: 'gray.100' }}
                                />
                              </Tooltip>
                            </motion.div>
                          </HStack>
                        </HStack>
                      </VStack>
                    </Box>
                  </motion.div>
                ))}
              </AnimatePresence>
            )}
          </VStack>

          {/* Update the bottom buttons section */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <HStack mt={4} justify={'space-between'}>
              <HStack gap={2}>
                {actionItems?.some(item => item.aiAction && item.aiCompletionStatus === 'PENDING') && (
                  <motion.div whileHover={{ scale: !isDisabled && areAllPendingItemsAssigned() ? 1.05 : 1 }}>
                    <Tooltip 
                      label={
                        isDisabled ? "Please assign a client first" :
                        (!areAllPendingItemsAssigned() ? "Please assign all pending tasks before approving" : "")
                      }
                      isDisabled={!isDisabled && areAllPendingItemsAssigned()}
                    >
                      <Button
                        bg={isDisabled ? "gray.300" : "#9DB4CA"}
                        onClick={handleApproveAll}
                        isDisabled={isDisabled || !areAllPendingItemsAssigned() || isApprovingAll}
                        opacity={isDisabled || !areAllPendingItemsAssigned() || isApprovingAll ? 0.7 : 1}
                        _hover={{ 
                          bg: !isDisabled && areAllPendingItemsAssigned() ? "#8AA6B9" : isDisabled ? "gray.300" : "#9DB4CA",
                          transform: !isDisabled && areAllPendingItemsAssigned() ? "scale(1.02)" : "none"
                        }}
                        transition="all 0.2s"
                      >
                        <HStack>
                          {isApprovingAll ? (
                            <Spinner size="sm" color="white" />
                          ) : (
                            <CheckCircleIcon 
                              color={!isDisabled && areAllPendingItemsAssigned() ? 'green.500' : 'gray.500'} 
                            />
                          )}
                          <Text>{isApprovingAll ? "Approving..." : "Approve All"}</Text>
                        </HStack>
                      </Button>
                    </Tooltip>
                  </motion.div>
                )}
              </HStack>
              
              {/* Update feedback buttons */}
              <HStack gap={1}>
                <motion.div whileHover={{ scale: isDisabled ? 1 : 1.1 }} whileTap={{ scale: isDisabled ? 1 : 0.95 }}>
                  <Button
                    isDisabled={isDisabled}
                    onClick={async () => {
                      let data = {
                        [type === 'call' ? 'callId' : 'emailId']: id,
                        details: "",
                        sentiment: "POSITIVE"
                      }
                      let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                        headers: {
                          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                        }
                      });
                      setFeedbackId(res.data.id);
                      setShowFeedbackModal(true);
                    }}
                  >
                    <Icon as={IoMdHappy} color={isDisabled ? 'gray.400' : 'green'} />
                  </Button>
                </motion.div>
                <motion.div whileHover={{ scale: isDisabled ? 1 : 1.1 }} whileTap={{ scale: isDisabled ? 1 : 0.95 }}>
                  <Button
                    isDisabled={isDisabled}
                    onClick={async () => {
                      let data = {
                        [type === 'call' ? 'callId' : 'emailId']: id,
                        details: "",
                        sentiment: "NEGATIVE"
                      }
                      let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                        headers: {
                          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                        }
                      });
                      setFeedbackId(res.data.id);
                      setShowFeedbackModal(true);
                    }}
                  >
                    <Icon as={IoMdSad} color={isDisabled ? 'gray.400' : 'red'} />
                  </Button>
                </motion.div>
              </HStack>
            </HStack>
          </motion.div>
        </Box>
      </motion.div>
      <FeedbackModal 
        isOpen={showFeedbackModal} 
        onClose={() => setShowFeedbackModal(false)} 
        type={type} 
        id={feedbackId} 
        onSubmit={handleFeedback}
      />
    </>
  );
};

export default BlueBox;