import React, { useState, useEffect, useRef } from 'react';
import {
  VStack, Box, Heading, Text, Button, useToast, HStack, Table,
  Thead, Tbody, Tr, Th, Td, Input, Alert, AlertIcon, Tooltip, Container, Divider,
  Skeleton, SkeletonText
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { createOutlookDraft } from '../../../../utils/outlookUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FeatureStats } from '../../../../enums/UsageStats';


// Define the same styles from FollowUpPage
const styles = `
  .quill-wrapper {
    height: 500px;
    display: flex;
    flex-direction: column;
  }

  .quill-wrapper .quill-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  // ... rest of the styles from FollowUpPage.js ...
`;

const FollowUpEmail = ({ callData, summary, clientTodos, advisorTodos }) => {
  const [followUpEmail, setFollowUpEmail] = useState({
    subject: `Follow-up: ${callData.call.title}`,
    summary: '',
    actionItemsClient: '',
    actionItemsAdvisor: ''
  });
  const [originalEmail, setOriginalEmail] = useState({});
  const [clientInfo, setClientInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [discrepancies, setDiscrepancies] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const [isOutlookConnected, setIsOutlookConnected] = useState(false);
  const quillRef = useRef(null);
  const toast = useToast();
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Add styles to document head
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user settings
        const settingsResponse = await axios.get(`${serverUrl}/api/user-settings`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        setUserSettings(settingsResponse.data?.settings || {});
        const type = callData.call.type !== 'email' ? 'call' : 'email';
        // Fetch follow-up email - use the correct endpoint based on type
        const response = await axios.post(
          `${serverUrl}/api/follow-up/${type}/${callData.call.id}`,
          { useTemplate: settingsResponse.data?.settings?.useEmailTemplate },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          }
        );

        if (settingsResponse.data?.settings?.useEmailTemplate) {
          const template = settingsResponse.data?.settings?.emailTemplate || '';
          const today = new Date().toLocaleDateString();
          const filledTemplate = template
            .replace(/{{clientName}}/g, (response.data?.clientInfo?.name || '[Client Name]'))
            .replace(/{{todaysDate}}/g, (today || '[Date]'))
            .replace(/{{body}}/g, response.data?.followUpEmail.emailBody || '[Email Body]')
            .replace(/{{advisorName}}/g, `${response.data?.user?.firstName} ${response.data?.user?.lastName}`)
            .replace(/{{signatureImage}}/g, response.data?.settings?.signatureImage || '');
          
          setTemplateContent(filledTemplate);
        } else {
          setTemplateContent(response.data?.followUpEmail.emailBody || '');
        }

        setFollowUpEmail({
          subject: callData.call.type === 'email' ? 
            `Re: ${callData.call.title}` : 
            `Follow-up: ${callData.call.title}`,
          ...response.data?.followUpEmail
        });
        setOriginalEmail(response.data?.followUpEmail);
        setClientInfo(response.data?.clientInfo);
        setIsLoading(false);

        // Check for discrepancies - only for calls
        if (callData.call.type === 'call') {
          const discrepancyResponse = await axios.post(
            `${serverUrl}/api/call/check-discrepancies`,
            {
              id: callData.call.id,
              emailContent: response.data?.followUpEmail.emailBody
            },
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
              }
            }
          );
          setDiscrepancies(discrepancyResponse.data.discrepancies);
        }

        // Check user connections
        const connectionsResponse = await axios.get(`${serverUrl}/api/user-connections`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        });
        
        const hasOutlookConnection = connectionsResponse.data.some(
          connection => connection.name === 'Microsoft'
        );
        setIsOutlookConnected(hasOutlookConnection);
      } catch (err) {
        setError('Failed to generate follow-up email');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [callData.call.id, callData.call.type, serverUrl]);

  const handleTemplateChange = (content) => {
    setTemplateContent(content);
  };

  const renderEmailBodyInput = () => {
    return (
      <Box className="quill-container" width="100%">
        <ReactQuill
          ref={quillRef}
          value={templateContent}
          onChange={handleTemplateChange}
          modules={{
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link'],
              ['clean']
            ],
            clipboard: {
              matchVisual: false
            }
          }}
          formats={[
            'header',
            'bold', 'italic', 'underline', 'strike',
            'list', 'bullet',
            'link'
          ]}
          style={{ width: '100%', height: '100%', overflowY: 'auto' }}
        />
      </Box>
    );
  };

  const handleOpenInOutlook = async () => {
    try {
      // Log email generation
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.EMAILS_GENERATED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });

      const emailContent = userSettings?.useEmailTemplate ? 
        templateContent : 
        followUpEmail.emailBody;

      await axios.post(
        `${serverUrl}/api/follow-up/${callData.call.type}/${callData.call.id}/save`,
        {
          originalEmail,
          currentDraft: {
            ...followUpEmail,
            emailBody: emailContent
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          }
        }
      );

      const { messageData, webLink } = await createOutlookDraft(
        followUpEmail.subject,
        emailContent,
        true
      );

      window.open(webLink, '_blank');

      toast({
        title: 'Draft created and opened in Outlook',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error creating draft in Outlook:', error);
      toast({
        title: 'Failed to create draft in Outlook',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <VStack spacing={8} align="stretch">
        <Skeleton height="40px" width="400px" />
        <SkeletonText mt='4' noOfLines={3} spacing='4' skeletonHeight='4' />
        <Skeleton height="400px" />
      </VStack>
    );
  }

  if (error) {
    return (
      <Alert status="error" variant="left-accent">
        <AlertIcon />
        <Text>{error}</Text>
      </Alert>
    );
  }

  return (
    <VStack spacing={8} align="stretch" overflowY="auto" maxHeight="calc(100vh - 200px)">
      {discrepancies && (discrepancies.length > 0) && (
        <Alert status="warning" variant="left-accent" borderRadius="md">
          <AlertIcon />
          <Box>
            <Heading size="sm" mb={2}>Potential Discrepancies Detected</Heading>
            <Text>{discrepancies.map(discrepancy => <li>{discrepancy}</li>)}</Text>
          </Box>
        </Alert>
      )}

      <Box 
        bg="white" 
        p={6} 
        borderRadius="lg" 
        boxShadow="sm"
        border="1px"
        borderColor="gray.100"
      >
        <HStack justify="space-between">
          <Tooltip 
            label={!isOutlookConnected ? "Please connect your Outlook account in Integrations" : "Create and view email draft in Outlook"}
            placement="top"
            hasArrow
          >
            <Button
              leftIcon={<EmailIcon />}
              colorScheme="blue"
              onClick={handleOpenInOutlook}
              isDisabled={!isOutlookConnected}
            >
              Open in Outlook
            </Button>
          </Tooltip>
        </HStack>
        <Divider mb={4} />
        
        <VStack spacing={6} align="stretch">
          <Box>
            <Text mb={2} fontWeight="medium">Subject</Text>
            <Input
              value={followUpEmail.subject}
              onChange={(e) => setFollowUpEmail(prev => ({ ...prev, subject: e.target.value }))}
              size="md"
              bg="gray.50"
            />
          </Box>

          <Box>
            <Text mb={2} fontWeight="medium">Email Body</Text>
            <Box 
              bg="gray.50" 
              borderRadius="md" 
              className="preserve-whitespace quill-wrapper"
              width="100%"
              height="500px"
              overflow="hidden"
            >
              {renderEmailBodyInput()}
            </Box>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
};

export default FollowUpEmail; 