import React from 'react';
import { HStack, Heading, Button, Tooltip } from '@chakra-ui/react';
import { ExternalLinkIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
const ClientHeader = ({ client, onOpenInCrm }) => {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/clients/${client.id}/edit`);
  };
  return (
    <HStack justify="space-between">
      <Heading color="#00417D" size="lg">
        {client?.metadata?.prefix} {client?.firstName} {client?.metadata?.preferredName ? `(${client?.metadata?.preferredName})` : ''} {client?.metadata?.middle} {client?.lastName} {client?.metadata?.suffix}
      </Heading>
      {(!client?.crmId && !client?.crmSource) && (
        <Button
          leftIcon={<EditIcon />}
          onClick={handleEditClick}
          colorScheme="blue"
          size="sm"
        >
          Edit Information
        </Button>
      )}
      {client?.crmId && client?.crmSource && (
        <Tooltip label={`Open in ${client.crmSource}`}>
          <Button
            leftIcon={<ExternalLinkIcon />}
            onClick={() => onOpenInCrm(client.crmId, client.crmSource)}
            size="sm"
            variant="outline"
            colorScheme="blue"
          >
            Open in {client.crmSource}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

export default ClientHeader; 