import React, { useEffect, useState, useMemo, memo, useRef } from 'react';
import { 
  Box, Heading, Text, VStack, HStack, Flex, IconButton, Progress, Button, 
  Input, Textarea, InputGroup, InputLeftElement, useToast, Grid, OrderedList, ListItem, Popover, PopoverTrigger, PopoverContent, PopoverBody, Tabs, TabList, TabPanels, TabPanel, Tab,
  Table, Thead, Tbody, Tr, Th, Td,
  Accordion, AccordionItem, AccordionButton, AccordionPanel,
  AccordionIcon, Badge, Divider, SimpleGrid, Checkbox, Menu, MenuButton, MenuList, MenuItem, Tooltip,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Spinner, Icon,
  UnorderedList, Avatar, InputRightElement
} from '@chakra-ui/react';
import {
  ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon,
  ChevronDownIcon, RepeatIcon, SearchIcon, ChatIcon, CalendarIcon,
  EmailIcon, BellIcon, StarIcon, CheckIcon, CloseIcon, ArrowForwardIcon, 
  TimeIcon, WarningIcon, LinkIcon, AddIcon, ArrowUpIcon, EditIcon
} from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { 
  FaPlay, FaPause, FaEdit, FaSave, FaTimes, FaTasks, 
  FaLightbulb, FaUserFriends 
} from 'react-icons/fa';
import { useOrganizationUsers } from '../../../contexts/OrganizationUsersContext';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';

import { FeatureStats } from '../../../enums/UsageStats';
// import TranscriptChatSidebar from './components/TranscriptChatSidebar';
// import MeetingPrep from './components/MeetingPrep';
// import Insights from './components/Insights';
// import Tasks from './components/Tasks';
import FollowUpEmail from './components/FollowUpEmail';
// import DraggableChat from './components/DraggableChat';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { BsStopwatch } from 'react-icons/bs';
import { RiSparklingLine } from 'react-icons/ri';
import { useUser } from '../../../contexts/UserContext';

// Define Colors
const colors = {
  primary: "#00417D",
  secondary: "#0053BA",
  accent: "blue.200",
  background: "#F5F5F5",
  text: {
    primary: "#333333",
    secondary: "#555555",
  },
};

// Helper Functions
const formatTime = (seconds, format = 'duration') => {
  if (!isFinite(seconds) || isNaN(seconds)) return format === 'timestamp' ? '00:00' : '0 secs';
  
  if (format === 'timestamp') {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  
  // Original duration format
  if (seconds < 60) {
    return `${Math.ceil(seconds)} secs`;
  }
  const minutes = Math.ceil(seconds / 60);
  return `${minutes} min`;
};

// Helper function to parse timestamp string "hh:mm:ss" into total seconds
const parseTimestamp = (timestamp) => {
  const parts = timestamp.split(':').map(Number);
  if (parts.length !== 3 || parts.some(isNaN)) return 0;
  const [hours, minutes, seconds] = parts;
  return hours * 3600 + minutes * 60 + seconds;
};

const calculateSpeakerStats = (transcription) => {
  const speakerStats = {};

  try {
    transcription.forEach(entry => {
      const { speaker, start, end, words } = entry;
      // console.log(`Processing entry for speaker: ${speaker}, start: ${start}, end: ${end}, words: ${words.length}`);

      const duration = end - start;
      // console.log(`Calculated duration for speaker ${speaker}: ${duration}`);

      if (!speakerStats[speaker]) {
        speakerStats[speaker] = { totalDuration: 0, totalWords: 0 };
        // console.log(`Initialized stats for speaker ${speaker}`);
      }

      speakerStats[speaker].totalDuration += duration;
      speakerStats[speaker].totalWords += words.length; // Each word is counted
      // console.log(`Updated stats for speaker ${speaker}: totalDuration = ${speakerStats[speaker].totalDuration}, totalWords = ${speakerStats[speaker].totalWords}`);
    });

    const overallTotalDuration = Object.values(speakerStats).reduce((acc, { totalDuration }) => acc + totalDuration, 0);
    // console.log(`Overall total duration: ${overallTotalDuration}`);

    for (const speaker in speakerStats) {
      const { totalDuration, totalWords } = speakerStats[speaker];
      speakerStats[speaker].percentageTime = overallTotalDuration > 0 ? Math.round((totalDuration / overallTotalDuration) * 100) : 0;
      speakerStats[speaker].wpm = totalDuration > 0 ? Math.round((totalWords / totalDuration) * 60) : 0;
      // console.log(`Final stats for speaker ${speaker}: percentageTime = ${speakerStats[speaker].percentageTime}, wpm = ${speakerStats[speaker].wpm}`);
    }
  } catch (error) {
    console.error('Error calculating speaker stats:', error);
    // Optionally, you can return an empty object or a default value
    return {};
  }

  return speakerStats;
};

const getTranscriptionDuration = (transcription) => {
  if (!transcription || transcription.length === 0) return 0;
  return Math.max(...transcription.map(entry => entry.end));
};

// The original UTC time is stored in the title between || and ||.
// This function extracts the time and formats it in the user's local timezone, to avoid timezone confusion between the server and client.
const formatTitleWithDate = (title) => {
  if (!title) return '';
  
  // Check if title contains an ISO timestamp between ||
  const matches = title.match(/\|\|(.*?)\|\|/);
  if (!matches) return title;

  // Extract the parts
  const [fullMatch, isoTime] = matches;
  const baseTitle = title.replace(fullMatch, '').trim();
  
  // Format the date in user's local timezone
  const date = new Date(isoTime);
  const formattedDate = date.toLocaleString(undefined, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  return `${baseTitle} on ${formattedDate}`;
};

// Add this mock data object at the top of the file
const MOCK_DATA = {
  title: "ABC Wealth Management Meeting",
  summary: "Ryan reviewed Sherri's portfolio performance, highlighting a successful 2024 where the portfolio gained 8%, translating to $202,000 in profits. While stocks performed well, particularly high profitability U.S. equities, bonds lagged a bit but were stable. The investment mix had shifted towards more growth-oriented resources with a current allocation of 59% growth, 22% fixed income, and 19% non-correlated investments.\n\nRyan explained tax and cash management strategies for the year, ensuring that requirements for her distributions and Social Security benefit align with anticipated tax obligations. Sherri's annual required minimum distribution is projected to be about $57,000.",
  personal: {
    goalsValues: "Providing a structured and significant inheritance for grandchildren.",
    lifeEvents: "Extended stay due to a snowstorm; upcoming travel to Pennsylvania in February.",
    family: {
      emily: "Emily (17): Babysits and house sits, attends school mid-January.",
      grandchildren: "Grandchildren (6): Beneficiaries in estate planning, proposed trust with inheritance at age 30.",
      serena: "Serena (spouse): Involved in financial and family discussions."
    }
  },
  qa: [
    {
      question: "What amount should Sherri allocate for grandkids in her will?",
      answer: "Suggested $100,000 each."
    },
    {
      question: "At what age should grandkids have control over their inheritance?",
      answer: "Recommended age 30."
    },
    {
      question: "How did the portfolio perform in 2024?",
      answer: "Overall growth was 8%."
    },
    {
      question: "Are changes expected in portfolio allocations?",
      answer: "Fine-tuning possible, especially for international stocks."
    }
  ],
  recommendations: [
    {
      recommendation: "Maintain the proposed allocation of $100,000 in trusts for each grandchild, with oversight until age 30.",
      rationale: null
    },
    {
      recommendation: "Consider placing financial guardrails for trust distributions.",
      rationale: "Protects against premature full withdrawal."
    },
    {
      recommendation: "Broker a meeting with a legal advisor regarding updates to wills in early February.",
      rationale: "Ensures current and accurate estate plans"
    }
  ],
  advisorTodos: [
    "Confirm estate planning details and figures with Sherri.",
    "Schedule and prepare for the Pennsylvania lawyer meeting in February.",
    "Continue monitoring portfolio performance with potential adjustments to international stocks."
  ],
  clientTodos: [
    "Reach out and coordinate with a Pennsylvania attorney about will updates.",
    "Continue review and organization of tax documents and planners.",
    "Ensure Social Security and Schwab 1099 forms are received and organized."
  ],
  financialData: {
    netWorth: "$2.8 million total net worth, $1.4 million in non-IRA accounts at Schwab",
    liquidNetWorth: "$31,000 in savings/checking, plus portfolio holdings",
    investmentObjective: "Growth",
    riskTolerance: "Moderately conservative, leaning towards growth opportunities",
    liquidityNeeds: "Manageable, with cash position and Social Security sufficient",
    changesSituation: "Stable, but awareness of estate planning alterations as needed"
  }
};

const extractTimestampFromTitle = (title, audioUrl) => {
  if (!title) return null;
  
  // First try to get timestamp from title between || markers
  const markerMatches = title.match(/\|\|(.*?)\|\|/);
  if (markerMatches) {
    return new Date(markerMatches[1]);
  }

  // Then try to extract date from the audio URL
  if (audioUrl) {
    const urlDateMatch = audioUrl.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
    if (urlDateMatch) {
      return new Date(urlDateMatch[0]);
    }
  }

  // Finally try to parse date from title
  const dateMatch = title.match(/Meeting (\d{1,2}\/\d{1,2})/);
  if (dateMatch) {
    const [_, dateStr] = dateMatch;
    const [month, day] = dateStr.split('/').map(Number);
    const currentYear = new Date().getFullYear();
    return new Date(currentYear, month - 1, day);
  }

  return null;
};

// Add this helper function near the top of the file
const formatCategoryName = (categoryKey) => {
  return categoryKey
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ');
};

const Correspondence = () => {
  const { id } = useParams();
  const [callData, setCallData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [groupedTranscription, setGroupedTranscription] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isEditingSummary, setIsEditingSummary] = useState(false); 
  const [newSummary, setNewSummary] = useState(''); 
  const [newTopics, setNewTopics] = useState([]); 
  const [duration, setDuration] = useState(0); 
  const [transcriptionDuration, setTranscriptionDuration] = useState(0);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false); 
  const [editedTranscription, setEditedTranscription] = useState([]); 
  const [activeSpeakerEdit, setActiveSpeakerEdit] = useState(null);
  const { users, loading: usersLoading, userCrms } = useOrganizationUsers();
  const { clients, loading: clientsLoading } = useOrganizationClients();
  const serverUrl = process.env.REACT_APP_API_URL;
  const audioRef = React.useRef(null);
  const toast = useToast();
  const callDataRef = useRef();
  const [speakerSearchTerm, setSpeakerSearchTerm] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});
  const [assignedUsers, setAssignedUsers] = useState({});
  const [editableDates, setEditableDates] = useState({});
  const navigate = useNavigate();
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [actionItems, setActionItems] = useState([]);
  const [isLoadingActionItems, setIsLoadingActionItems] = useState(false);
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [isLeftExpanded, setIsLeftExpanded] = useState(false);
  const [isRightExpanded, setIsRightExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [pendingActionItems, setPendingActionItems] = useState(0);
  const [clientName, setClientName] = useState(null);
  const [isClientAssignModalOpen, setIsClientAssignModalOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(50); // percentage
  const [isDragging, setIsDragging] = useState(false);
  const [askAboutMessages, setAskAboutMessages] = useState([]);
  const [askAboutInput, setAskAboutInput] = useState('');
  const [isAskAboutLoading, setIsAskAboutLoading] = useState(false);
  const [streamingAskAboutMessage, setStreamingAskAboutMessage] = useState('');
  // Add these state variables near the top with other states
  const [aiInsights, setAiInsights] = useState([]);
  const [aggregateInsights, setAggregateInsights] = useState([]);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  // Add to your existing state declarations
  const [keyInfo, setKeyInfo] = useState(null);
  const { user } = useUser();
  const [userCrms2] = useState(user?.organization?.crms[0]?.crmUsers || []);


  // Keep the ref updated
  useEffect(() => {
    callDataRef.current = callData;
  }, [callData]);

  // Add this useEffect right after the state declarations
  useEffect(() => {
    const loadActionItems = async () => {
      if (!id) return;

      try {
        setIsLoadingActionItems(true);
        const response = await axios.get(
          `${serverUrl}/api/correspondence/${id}/action-items`,
          {
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        console.log("Action Items: ", response.data);
        setActionItems(response.data);
        
        // Calculate pending items count
        const pendingCount = response.data.filter(
          item => item.status !== 'APPROVED' && !item.archived
        ).length;
        setPendingActionItems(pendingCount);
      } catch (error) {
        console.error('Error fetching action items:', error);
        toast({
          title: "Error",
          description: "Failed to fetch action items",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingActionItems(false);
      }
    };

    loadActionItems();
  }, [id, serverUrl, toast]);

  // Add this effect to fetch insights when the call loads
  useEffect(() => {
    const fetchInsights = async () => {
      if (!id || !callData?.call || !callData?.call.transcription) return;
      
      try {
        setIsLoadingInsights(true);
        
        // Fetch meeting-specific insights
        const insightsResponse = await axios.get(
          `${serverUrl}/api/insights`, 
          {
            params: {
              sourceType: 'call',  // Matches what's stored in the database
              sourceId: id
            },
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        console.log("Meeting Insights: ", insightsResponse.data);
        // Fetch aggregate insights
        const aggregateResponse = await axios.get(
          `${serverUrl}/api/clients/${callData.call.clients[0]?.id}/aggregate-insights`,
          {
            headers: {
              'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
          }
        );
        
        setAiInsights(insightsResponse.data.insights || []);
        setAggregateInsights(aggregateResponse.data.insights || []);
        
      } catch (error) {
        console.error('Error fetching insights:', error);
        toast({
          title: "Error",
          description: "Failed to fetch AI insights",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingInsights(false);
      }
    };

    fetchInsights();
  }, [id, callData?.call, serverUrl, toast]);

  // Add to your useEffect that loads data
  useEffect(() => {
    const fetchKeyInfo = async () => {
        console.log('Fetching key info for client:', callData?.call?.clients?.[0]?.id);
        if (callData?.call?.clients?.[0]?.id) {
            try {
                const response = await axios.get(
                    `${serverUrl}/api/clients/${callData.call.clients[0].id}/key-info`,
                    {
                        headers: {
                            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                        }
                    }
                );
                setKeyInfo(response.data);
            } catch (error) {
                console.error('Error fetching key info:', error);
            }
        }
    };

    fetchKeyInfo();
  }, [callData?.call?.clients]);

  // Helper function to format person's name
  const formatPersonName = (person) => {
    if (!person) return '';
    return `${person.firstName || ''} ${person.lastName || ''}`.trim();
  };

  const handleSpeakerUpdate = async (newSpeaker, currentSpeaker, groupIndex = null, applyToAll = false) => {
    try {
      const currentCallData = callDataRef.current;
      
      console.log('handleSpeakerUpdate called with:', {
        newSpeaker,
        currentSpeaker,
        groupIndex,
        applyToAll,
        callData: currentCallData
      });

      if (!currentCallData?.call || !Array.isArray(currentCallData.call.transcription)) {
        console.error('Call data not properly structured:', currentCallData);
        return;
      }

      let updatedTranscription;
      if (applyToAll) {
        updatedTranscription = currentCallData.call.transcription.map(entry => 
          entry.speaker === currentSpeaker ? { ...entry, speaker: newSpeaker.name } : entry
        );
      } else {
        updatedTranscription = currentCallData.call.transcription.map((entry, index) => {
          if (index === groupIndex && entry.speaker === currentSpeaker) {
            return { ...entry, speaker: newSpeaker.name };
          }
          return entry;
        });
      }

      // console.log("Updated Transcription: ", JSON.stringify(updatedTranscription, null, 2));

      const response = await axios.put(
        `${serverUrl}/update-transcript/${currentCallData.call.id}`,
        { 
          transcript: updatedTranscription,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Log speaker name reassignment
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.SPEAKER_NAMES_REASSIGNED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      // Update the state after successful speaker update
      if (response.data?.call?.transcription) {
        setCallData(prev => ({
          ...prev,
          call: {
            ...prev.call,
            transcription: response.data.call.transcription,
          },
        }));
        
        setGroupedTranscription(groupTranscription(response.data.call.transcription));
        setActiveSpeakerEdit(null);
  
        toast({
          title: 'Speaker Updated',
          description: `Speaker name has been updated to ${newSpeaker.name}`,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error('Invalid response data');
      }
    } catch (error) {
      console.error('Error in handleSpeakerUpdate:', error);
      toast({
        title: 'Update Failed',
        description: 'Failed to update speaker name',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Replace the existing SpeakerSelectionContent component with this updated version
  const SpeakerSelectionContent = memo(({ person, speaker, groupIndex, handleSpeakerUpdate, setActiveSpeakerEdit }) => (
    <Box
      key={person.id}
      position="relative"
      transition="all 0.2s"
      borderRadius="md"
      width="100%"
      _hover={{
        bg: 'gray.50',
        transform: 'translateX(2px)'
      }}
    >
      <Flex
        p={3}
        align="center"
        justify="space-between"
        cursor="pointer"
        onClick={() => {
          handleSpeakerUpdate(person, speaker, groupIndex, false);
          setActiveSpeakerEdit(null);
        }}
        minWidth="0"
      >
        <HStack spacing={3} flex="1" minWidth="0">
          <Box
            bg={person.type === 'client' ? 'blue.100' : 'green.100'}
            p={2}
            borderRadius="full"
            color={person.type === 'client' ? 'blue.700' : 'green.700'}
            flexShrink={0}
          >
            {person.type === 'client' ? '👤' : '👔'}
          </Box>
          <VStack align="start" spacing={0} minWidth="0">
            <Text fontWeight="medium" noOfLines={1}>{person.name}</Text>
            <Text fontSize="xs" color="gray.500" textTransform="capitalize">
              {person.type}
            </Text>
          </VStack>
        </HStack>
        <Button
          size="sm"
          variant="ghost"
          colorScheme={person.type === 'client' ? 'blue' : 'green'}
          onClick={(e) => {
            e.stopPropagation();
            handleSpeakerUpdate(person, speaker, null, true);
            setActiveSpeakerEdit(null);
          }}
          ml={2}
          flexShrink={0}
        >
          Replace All
        </Button>
      </Flex>
    </Box>
  ));

  // Memoized lists and content
  const availableSpeakers = useMemo(() => {
    const formattedUsers = users.map(user => ({
      id: user.id,
      name: formatPersonName(user),
      type: 'user'
    }));

    const formattedClients = clients.map(client => ({
      id: client.id,
      name: formatPersonName(client),
      type: 'client'
    }));

    return [...formattedUsers, ...formattedClients]
    .filter(person => person.name)
    .sort((a, b) => a.name.localeCompare(b.name));
  }, [users, clients]);

  // Update the speakerSelectionContent function
  const speakerSelectionContent = useMemo(() => (speaker, groupIndex) => {
    const filteredSpeakers = availableSpeakers.filter(person =>
      person.name.toLowerCase().includes(speakerSearchTerm.toLowerCase())
    );

    return (
      <VStack align="stretch" width="100%" spacing={2} p={3}>
        <InputGroup size="md">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search speakers..."
            value={speakerSearchTerm}
            onChange={(e) => setSpeakerSearchTerm(e.target.value)}
            bg="white"
            borderWidth="2px"
            _focus={{
              borderColor: colors.secondary,
              boxShadow: 'none'
            }}
          />
        </InputGroup>
        
        {filteredSpeakers.length > 0 ? (
          <VStack 
            align="stretch" 
            spacing={1} 
            maxH="300px" 
            overflowY="auto"
            overflowX="hidden"
            width="100%"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: colors.primary,
                borderRadius: '24px',
              },
            }}
          >
            {filteredSpeakers.map((person) => (
              <SpeakerSelectionContent
                key={person.id}
                person={person}
                speaker={speaker}
                groupIndex={groupIndex}
                handleSpeakerUpdate={handleSpeakerUpdate}
                setActiveSpeakerEdit={setActiveSpeakerEdit}
              />
            ))}
          </VStack>
        ) : (
          <Box textAlign="center" py={4}>
            <Text color="gray.500">No speakers found</Text>
          </Box>
        )}
      </VStack>
    );
  }, [availableSpeakers, speakerSearchTerm, colors]);

  // Update the renderSpeakerName function
  const renderSpeakerName = (speaker, groupIndex) => {
    const speakerKey = `${speaker}-${groupIndex}`;
    
    return (
      <Popover
        isOpen={activeSpeakerEdit === speakerKey}
        onClose={() => {
          setActiveSpeakerEdit(null);
          setSpeakerSearchTerm('');
        }}
        placement="right-start"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button
            variant="ghost"
            size="sm"
            display="flex"
            alignItems="center"
            fontWeight="bold"
            color={colors.primary}
            onClick={() => setActiveSpeakerEdit(activeSpeakerEdit === speakerKey ? null : speakerKey)}
            leftIcon={<FaEdit size="12px" />}
            rightIcon={activeSpeakerEdit === speakerKey ? <ChevronUpIcon /> : <ChevronDownIcon />}
            _hover={{
              bg: 'blue.50'
            }}
          >
            {speaker}
          </Button>
        </PopoverTrigger>
        <PopoverContent width="350px" border="none" boxShadow="lg" borderRadius="xl">
          <PopoverBody p={0}>
            {speakerSelectionContent(speaker, groupIndex)}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = window.location.pathname.includes('/email/') ? 
          `${serverUrl}/api/emails/${id}` :
          `${serverUrl}/transcripts?id=${id}`;

        const response = await axios.get(endpoint, { 
          headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }, 
          withCredentials: true 
        });

        console.log("Response:", response.data);
        
        if (window.location.pathname.includes('/email/')) {
          // Format email data to match expected structure
          setCallData({
            call: {
              id: response.data.id,
              title: response.data.subject,
              summary: response.data.metadata.summary,
              clients: response.data.clients,
              type: 'email',
              metadata: response.data.metadata,
              startTime: new Date(response.data.receivedAt),
              topics: response.data.topics,
              questions: response.data.questions,
              clientTodos: response.data.clientTodos,
              advisorTodos: response.data.advisorTodos,
              user: response.data.user,
              toRecipients: response.data.toRecipients,
              body: response.data.body,
              from: response.data.metadata.from || response.data.from,
              transcriptQA: response.data.transcriptQA
            },
            audioUrl: null // Emails don't have audio
          });
          
          // Format email content as a transcript-like structure for display
          const emailContent = [{
            speaker: response.data.metadata.from?.emailAddress?.name || response.data.metadata.from?.emailAddress?.address || 'Sender',
            entries: [{
              text: response.data.metadata.body || response.data.body,
              start: 0,
              end: 0
            }]
          }];
          
          //setGroupedTranscription(emailContent);
          
        } else {
          // Handle call/transcript data as before
          setCallData(response.data);
          console.log("Call Data:", response.data.call);
          setNewTitle(formatTitleWithDate(response.data.call.title));
          setNewSummary(response.data.call?.summary ?? '');
          setNewTopics(response.data.call?.topics?.topics ?? []);
          
          const grouped = groupTranscription(response.data.call.transcription);
          setGroupedTranscription(grouped);
          setTranscriptionDuration(getTranscriptionDuration(response.data.call.transcription));
        }
        
        // Log transcript view
        await axios.post(`${serverUrl}/api/usage/log`, {
          statPath: FeatureStats.TRANSCRIPT_VIEWS,
          value: 1
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        });

        // Set client name
        if (response.data.call?.clients?.length > 0 || response.data.clients?.length > 0) {
          const clients = response.data.call?.clients || response.data.clients;
          const [firstClient] = clients;
          setClientName(`${firstClient.firstName} ${firstClient.lastName}`);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          title: 'Error',
          description: error.response?.data?.error || 'Failed to fetch data.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    };
    fetchData();
  }, [id, serverUrl, toast]);

  const groupTranscription = (transcription) => {
    // Since the new format already groups by speaker, we can return it as is
    return transcription.map(entry => ({
      speaker: entry.speaker,
      entries: [entry]
    }));
  };

  const handlePlayPause = () => {
    if (!audioRef.current) return;

    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (!audioRef.current) return; 

    const currentTime = audioRef.current.currentTime;
    const audioDuration = audioRef.current.duration;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioDuration) && audioDuration > 0 
      ? audioDuration 
      : transcriptionDuration;
    
    setCurrentTime(currentTime);
    
    if (effectiveDuration > 0) {
      const progress = (currentTime / effectiveDuration) * 100;
      setProgress(progress);
      setDuration(effectiveDuration);
    }
  };

  const handleSkip = (seconds) => {
    if (!audioRef.current) return; 
    audioRef.current.currentTime += seconds;
  };

  const jumpToTimestamp = (timestamp) => {
    if (!audioRef.current) return; 
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  // Update the filteredTranscription filter function
  const filteredTranscription = groupedTranscription.filter(group =>
    group.entries.some(entry =>
      (entry.text?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      (group.speaker?.toLowerCase() || '').includes(searchTerm.toLowerCase())
    )
  );

  if (!callData) {
    return <Box>Loading...</Box>;
  }

  const handleSeekClick = (e) => {
    if (!audioRef.current) return; // Safeguard
    const progressBar = e.target;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    
    // Use transcription duration as fallback
    const effectiveDuration = isFinite(audioRef.current.duration) && audioRef.current.duration > 0 
      ? audioRef.current.duration 
      : transcriptionDuration;
    
    const seekTime = (clickX / progressBar.clientWidth) * effectiveDuration;
    audioRef.current.currentTime = seekTime;
    setProgress((seekTime / effectiveDuration) * 100);
  };

  const getHighlightedAndLineBreakedText = (entry, groupIndex, entryIndex) => {
    // Only split on periods followed by capital letters
    const sentences = entry.text
      .split(/(?<=\.)\s+(?=[A-Z])/)
      .filter(sentence => sentence.trim());
    
    const formattedText = sentences.map((sentence, index) => (
      <React.Fragment key={index}>
        {index > 0 && index % 4 === 0 && <><br /><br /></>}
        {sentence.trim()}{' '}
      </React.Fragment>
    ));
    
    // Determine if the entire utterance should be highlighted
    const isHighlighted = currentTime >= entry.start && currentTime <= entry.end;
    const uniqueKey = `${groupIndex}-${entryIndex}-${entry.start}`;
    // console.log("Unique Key: ", uniqueKey);
  
    if (isHighlighted) {
      console.log(`Highlighted Utterance: "${entry.text}", Start: ${entry.start}, End: ${entry.end}, Current Time: ${currentTime}`);
    }
  
    return (
      <Text
        as="span"
        key={uniqueKey}
        bg={isHighlighted ? colors.accent : 'transparent'}
      >
        {formattedText}
      </Text>
    );
  };

  // Event Handlers for Title Editing
  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleCancelEditTitle = () => {
    setIsEditingTitle(false);
    setNewTitle(callData.call.title);
  };

  const handleSaveTitle = async () => {
    if (newTitle.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Title cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.put(
        `${serverUrl}/update-title/${callData.call.id}`,
        { title: newTitle },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtToken')}`,
          },
        }
      );

      // Log title edit
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.TRANSCRIPTION_TITLES_EDITED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          title: response.data.call.title,
        },
      }));
      setIsEditingTitle(false);
      toast({
        title: "Title Updated",
        description: "The call title has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating title:', error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the title.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Summary Editing
  const handleEditSummary = () => {
    setIsEditingSummary(true);
  };

  const handleCancelEditSummary = () => {
    setIsEditingSummary(false);
    setNewSummary(callData.call.summary ?? '');
  };

  const handleSaveSummary = async () => {
    if (newSummary.trim() === '') {
      toast({
        title: "Validation Error",
        description: "Summary cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const formattedSummary = newSummary.trim();

      const response = await axios.put(
        `${serverUrl}/update-summary/${callData.call.id}`,
        { summary: formattedSummary },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
        }
      );

      // Log summary edit
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.SUMMARIES_EDITED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      // Split the summary into list items by newline for display
      const summaryItems = formattedSummary.split('\n').filter(item => item.trim() !== '');

      setCallData((prev) => ({
        ...prev,
        call: {
          ...prev.call,
          summary: response.data.summary,
        },
      }));
      setNewSummary(summaryItems.join('\n')); // Ensure newSummary is newline-separated
      setIsEditingSummary(false);
      toast({
        title: "Summary Updated",
        description: "The summary has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating summary:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the summary.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Event Handlers for Transcription Editing
  const handleEditTranscription = () => {
    setEditedTranscription(groupedTranscription.map(group => ({
      ...group,
      editedText: group.entries.map(entry => entry.text).join(' ')
    })));
    setIsEditingTranscription(true);
  };

  const handleCancelEditTranscription = () => {
    setIsEditingTranscription(false);
    setEditedTranscription([]);
  };

  const handleTranscriptionChange = (index, newText) => {
    const updatedTranscription = [...editedTranscription];
    updatedTranscription[index].editedText = newText;
    setEditedTranscription(updatedTranscription);
  };

  const handleSaveTranscription = async () => {
    try {
      // Prepare the updated transcription array
      const updatedTranscriptionArray = editedTranscription.flatMap((group, groupIndex) => {
        // Split the edited text into words
        const editedWords = group.editedText.split(/\s+/).filter(text => text);

        // Get the original words for this group to preserve timings
        const originalGroup = callData.call.transcription[groupIndex];
        const originalWords = originalGroup.words || [];

        // Map edited words to original word timestamps
        const words = editedWords.map((text, wordIndex) => {
          const originalWord = originalWords[wordIndex];
          if (originalWord) {
            return {
              text,
              speaker: group.speaker,
              start: originalWord.start,
              end: originalWord.end,
              confidence: originalWord.confidence
            };
          } else {
            // Fallback: Assign approximate timings if original word is unavailable
            const lastEnd = originalWords[wordIndex - 1]?.end || group.start;
            return {
              text,
              speaker: group.speaker,
              start: lastEnd,
              end: lastEnd, // Assign a default duration of 0 seconds
              confidence: 1.0 // Assuming full confidence for added words
            };
          }
        });

        return [{
          speaker: group.speaker,
          start: originalGroup.start,
          end: originalGroup.end,
          text: editedWords.join(' '),
          words: words
        }];
      });
      // Calculate and log the request payload size
      const requestPayload = { transcript: updatedTranscriptionArray };
      const payloadSize = new Blob([JSON.stringify(requestPayload)]).size;
      console.log(`Request payload size: ${(payloadSize / 1024 / 1024).toFixed(2)} MB`);

      // If payload is too large, show warning
      if (payloadSize > 10 * 1024 * 1024) { // 10MB threshold
        toast({
          title: 'Warning: Large Payload',
          description: `Request size (${(payloadSize / 1024 / 1024).toFixed(2)} MB) may be too large for the server.`,
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }

      // Proceed with the request
      const response = await axios.put(
        `${serverUrl}/update-transcript/${callData.call.id}`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtToken")}`,
          },
          maxContentLength: Infinity, 
          maxBodyLength: Infinity, 
        }
      );

      // Update the callData and groupedTranscription with the new transcription
      // Log transcription body edit
      await axios.post(`${serverUrl}/api/usage/log`, {
        statPath: FeatureStats.TRANSCRIPTION_BODY_EDITED,
        value: 1
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      });

      setCallData(prev => ({
        ...prev,
        call: {
          ...prev.call,
          transcription: response.data.call.transcription,
        },
      }));

      setGroupedTranscription(groupTranscription(response.data.call.transcription));
      setIsEditingTranscription(false);
      setEditedTranscription([]);
      toast({
        title: 'Transcription Updated',
        description: 'The transcription has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating transcription:', error);
      // Add more detailed error logging
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      }
      
      toast({
        title: 'Update Failed',
        description: error.response?.data?.message || 'There was an error updating the transcription. The payload might be too large.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const speakerStats = calculateSpeakerStats(callData.call.transcription);

  const handleSendFollowUp = () => {
    setIsFollowUpModalOpen(true);
  };

  const handleViewTasks = () => {
    setIsTasksModalOpen(true);
  };

  const handleApprove = async (item) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: true }));
      
      const dueDate = editableDates[item.id] 
        ? new Date(editableDates[item.id]).toISOString()
        : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString();

      const actionItemData = {
        assignedTo: assignedUsers[item.id]?.id,
        dueDate: dueDate,
        description: item.description,
        functionParameters: {
          ...item.functionParameters,
          newValue: {
            ...item.functionParameters?.newValue,
            description: item.functionParameters?.newValue?.description
          }
        }
      };

      const response = await axios.post(
        `${serverUrl}/api/actionItems/${item.id}/approve`,
        actionItemData,
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        setActionItems(prev => 
          prev.map(actionItem => 
            actionItem.id === item.id 
              ? { ...actionItem, status: 'APPROVED' }
              : actionItem
          )
        );
        
        // Decrease pending count
        setPendingActionItems(prev => Math.max(0, prev - 1));

        toast({
          title: "Action Item Approved",
          description: "The action item has been successfully approved.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error approving action item:', error);
      toast({
        title: "Error",
        description: "Failed to approve the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleReject = async (item) => {
    try {
      setLoadingStates(prev => ({ ...prev, [item.id]: true }));
      const response = await axios.post(
        `${serverUrl}/api/action-items/${item.id}/archive`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.status === 200) {
        setActionItems(prev => prev.filter(actionItem => actionItem.id !== item.id));
        
        // Decrease pending count if item wasn't already approved
        if (item.status !== 'APPROVED') {
          setPendingActionItems(prev => Math.max(0, prev - 1));
        }

        toast({
          title: "Action Item Rejected",
          description: "The action item has been successfully rejected.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error rejecting action item:', error);
      toast({
        title: "Error",
        description: "Failed to reject the action item. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingStates(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleAssign = (itemId, user) => {
    setAssignedUsers(prev => ({
      ...prev,
      [itemId]: {
        id: user.id,
        name: user.name
      }
    }));
  };

  const handleDateChange = (itemId, date) => {
    setEditableDates(prev => ({
      ...prev,
      [itemId]: date
    }));
  };

  const handleSendMessage = async () => {
    if (!chatInput.trim()) return;

    try {
      setIsSending(true);
      setChatMessages(prev => [...prev, { role: 'user', content: chatInput }]);
      setChatInput('');

      const response = await axios.post(
        `${serverUrl}/api/chat/ask`,
        {
          message: chatInput,
          context: {
            transcriptId: id,
            type: 'call'
          }
        },
        {
          headers: {
            'Authorization': `Bearer ${Cookies.get('jwtToken')}`
          }
        }
      );

      if (response.data.message) {
        setChatMessages(prev => [...prev, { role: 'assistant', content: response.data.message }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: 'Error',
        description: 'Failed to send message. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
    document.body.style.cursor = 'col-resize';
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.cursor = 'default';
  };

  const handleDrag = (e) => {
    if (!isDragging) return;
    
    const container = e.currentTarget.parentElement;
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const offsetX = e.clientX - containerRect.left;
    const newWidth = (offsetX / containerWidth) * 100;
    
    // Limit the resize range (20% to 80%)
    if (newWidth >= 20 && newWidth <= 80) {
      setLeftPanelWidth(newWidth);
    }
  };

  const handleAskAboutSubmit = async () => {
    if (!askAboutInput.trim()) return;

    const newMessage = {
      type: 'user',
      content: askAboutInput,
      timestamp: new Date().toISOString()
    };

    setAskAboutMessages(prev => [...prev, newMessage]);
    setAskAboutInput('');
    setIsAskAboutLoading(true);
    setStreamingAskAboutMessage('');

    try {
      const response = await fetch(`${serverUrl}/qa/transcript/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`,
        },
        body: JSON.stringify({
          question: askAboutInput,
          transcriptContent: callData.call.transcription,
          chatHistory: askAboutMessages.slice(-6) // Send last 6 messages for context
        })
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(5).trim();
            
            if (data === '[DONE]') {
              continue;
            }

            try {
              const parsed = JSON.parse(data);
              if (parsed.content) {
                fullResponse += parsed.content;
                setStreamingAskAboutMessage(fullResponse);
              }
            } catch (e) {
              if (data !== '[DONE]') {
                console.error('Error parsing SSE data:', e);
              }
            }
          }
        }
      }

      const aiResponse = {
        type: 'assistant',
        content: fullResponse,
        timestamp: new Date().toISOString()
      };

      setAskAboutMessages(prev => [...prev, aiResponse]);
      setStreamingAskAboutMessage('');

    } catch (error) {
      console.error('Error getting answer:', error);
      toast({
        title: 'Error',
        description: 'Failed to get answer. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsAskAboutLoading(false);
    }
  };

  return (
    <Box position="relative" height="100vh" overflow="hidden">
      <Grid 
        templateColumns={
          isLeftExpanded 
            ? "1fr" 
            : isRightExpanded 
              ? "1fr" 
              : `${leftPanelWidth}% 8px calc(${100 - leftPanelWidth}% - 8px)`
        }
        gap={0}
        height="calc(100vh - 80px)"
        maxHeight="calc(100vh - 80px)"
        bg={colors.background}
        onMouseMove={handleDrag}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragEnd}
      >
        {/* Left Panel */}
        {!isRightExpanded && (
          <Box 
            gridColumn={isLeftExpanded ? "1 / -1" : "1 / 2"}
            bg="white"
            position="relative"
            transition="width 0.1s ease"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <VStack spacing={0} width="100%">
              {/* Title Section */}
              <Box 
                w="100%" 
                bg="gray.50" 
                borderBottomWidth="1px" 
                borderColor="gray.200"
              >
                <VStack spacing={2} p={4}>
                  <HStack justify="space-between" width="100%">
                    <HStack spacing={3}>
                      <IconButton
                        aria-label="Go back"
                        icon={<ArrowBackIcon />}
                        onClick={() => window.history.back()}
                        variant="ghost"
                        color="gray.600"
                        _hover={{ bg: "gray.100" }}
                      />
                      <Heading size="md" color={colors.primary}>
                        {formatTitleWithDate(callData.call.title)}
                      </Heading>
                    </HStack>
                    <IconButton
                      icon={isLeftExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                      onClick={() => {
                        setIsLeftExpanded(!isLeftExpanded);
                        if (!isLeftExpanded) {
                          setIsRightExpanded(false);
                        }
                      }}
                      aria-label="Toggle fullscreen"
                    />
                  </HStack>

                  <HStack spacing={6} pl={12} color="gray.600" width="100%">
                    <HStack spacing={2}>
                      {clientName ? (
                        <Menu>
                          <MenuButton
                            as={Button}
                            size="sm"
                            variant="ghost"
                            _hover={{ bg: 'gray.100' }}
                          >
                            <HStack spacing={2}>
                              <Avatar
                                name={clientName}
                                size="xs"
                                bg="#00417D"
                                color="white"
                                fontSize="12px"
                                fontWeight="700"
                              />
                              <Text fontWeight="medium">{clientName}</Text>
                              <ChevronDownIcon />
                            </HStack>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              icon={<Icon as={FaEdit} />}
                              onClick={() => setIsClientAssignModalOpen(true)}
                            >
                              Reassign Client
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      ) : (
                        <Button
                          leftIcon={<Icon as={WarningIcon} color="red.500" />}
                          size="sm"
                          variant="outline"
                          colorScheme="red"
                          onClick={() => setIsClientAssignModalOpen(true)}
                        >
                          Assign Client
                        </Button>
                      )}
                    </HStack>

                    <HStack spacing={2}>
                      <Icon as={TimeIcon} />
                      <Text>
                        {callData.call.title ? 
                          (() => {
                            // First try to parse date from title format "Meeting M/D/YYYY HH:MM AM/PM"
                            const titleMatch = callData.call.title.match(/Meeting (\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2} [AP]M)/);
                            if (titleMatch) {
                              const date = new Date(titleMatch[1]);
                              return date.toLocaleString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              });
                            }
                            
                            // Then try timestamp between || markers
                            const markerMatches = callData.call.title.match(/\|\|(.*?)\|\|/);
                            if (markerMatches) {
                              const date = new Date(markerMatches[1]);
                              return date.toLocaleString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              });
                            }

                            // Finally fallback to startTime
                            if (callData.call.startTime) {
                              return new Date(callData.call.startTime).toLocaleString(undefined, {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              });
                            }
                            return 'Time not available';
                          })()
                          : 'Time not available'
                        }
                      </Text>
                    </HStack>
                    {callData.call.type !== 'email' && (
                      <HStack spacing={2}>
                        <Icon as={BsStopwatch} />
                        <Text>
                          {formatTime(transcriptionDuration)}
                        </Text>
                      </HStack>
                    )}
                  </HStack>
                </VStack>
              </Box>

              {/* Transcript Content */}
              <Box 
                p={4} 
                width="100%"
                maxHeight="calc(100vh - 250px)"
              >
                {/* Search Bar */}
                {callData.call.type !== 'email' && (
                  <HStack spacing={4} mb={4}>
                    <InputGroup flex={1}>
                      <InputLeftElement pointerEvents="none">
                        <SearchIcon color="gray.300" />
                      </InputLeftElement>
                      <Input
                        placeholder={`Search ${callData.call.type === 'email' ? 'email' : 'transcript'}`}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </InputGroup>
                    
                    {/* Add Edit Controls */}
                    {!isEditingTranscription ? (
                      <IconButton
                        aria-label="Edit Transcription"
                        icon={<FaEdit />}
                        onClick={handleEditTranscription}
                        variant="ghost"
                        colorScheme="blue"
                        _hover={{ bg: "blue.50", transform: "scale(1.05)" }}
                        transition="background-color 0.3s ease, transform 0.2s ease"
                      />
                    ) : (
                      <>
                        <IconButton
                          aria-label="Save Transcription"
                          icon={<FaSave />}
                          onClick={handleSaveTranscription}
                          colorScheme="green"
                          variant="ghost"
                        />
                        <IconButton
                          aria-label="Cancel Transcription Edit"
                          icon={<FaTimes />}
                          onClick={handleCancelEditTranscription}
                          colorScheme="red"
                          variant="ghost"
                        />
                      </>
                    )}
                  </HStack>
                )}
                
                <Box overflowY="auto" h={"100%"}>
                  {callData.call.type === 'email' ? (
                    // Email Content Display
                    <VStack align="stretch" spacing={4}>
                      {/* Email Subject */}
                      <Box p={4} bg="gray.50" borderRadius="md">
                        <Text fontWeight="bold" color="gray.700">Subject:</Text>
                        <Text mt={1}>{callData.call.title}</Text>
                      </Box>
                      
                      {/* Email Metadata */}
                      <Box p={4} bg="gray.50" borderRadius="md">
                        <HStack spacing={4}>
                          <Box>
                            <Text fontWeight="bold" color="gray.700">From:</Text>
                            <Text>{`${callData.call.from?.emailAddress?.name} (${callData.call.from?.emailAddress?.address})`}</Text>
                          </Box>
                          <Box>
                            <Text fontWeight="bold" color="gray.700">To:</Text>
                            <Text>{callData.call.toRecipients?.map(recipient => 
                              recipient
                            ).join(', ')}</Text>
                          </Box>
                        </HStack>
                      </Box>

                        <Text fontWeight="bold" color="gray.700">Body:</Text>
                      {/* Email Body */}
                      <Box 
                        p={4} 
                        bg="white" 
                        borderRadius="md"
                        borderWidth="1px"
                        borderColor="gray.200"
                        whiteSpace="pre-wrap"
                      >
                        <Text dangerouslySetInnerHTML={{ __html: callData.call.body }} />
                      </Box>
                    </VStack>
                  ) : (
                    // Updated Transcript Display
                    isEditingTranscription ? (
                      <VStack align="stretch" spacing={4}>
                        {editedTranscription.map((group, index) => (
                          <Flex 
                            key={index} 
                            align="start" 
                            p={3} 
                            borderRadius="md" 
                            boxShadow="sm" 
                            bg="gray.50"
                          >
                            <Box minWidth="150px">
                              {renderSpeakerName(group.speaker, index)}
                            </Box>
                            <Textarea
                              value={group.editedText}
                              onChange={(e) => handleTranscriptionChange(index, e.target.value)}
                              placeholder={`Edit transcription for ${group.speaker}`}
                              size="md"
                              borderColor="gray.300"
                              _focus={{ borderColor: "blue.500", boxShadow: 'outline' }}
                              resize="vertical"
                              fontSize="md"
                              lineHeight="1.5"
                              minHeight="100px"
                              backgroundColor="white"
                              flex="1"
                              ml={4}
                            />
                            <Text fontSize="sm" ml={4} color="blue.500" minWidth="60px">
                              {formatTime(group.entries[0].start, 'timestamp')}
                            </Text>
                          </Flex>
                        ))}
                      </VStack>
                    ) : (
                      // Existing transcript display code
                      filteredTranscription.map((group, groupIndex) => (
                        <Flex
                          key={groupIndex}
                          mt={2}
                          align="start"
                          p={3}
                          borderRadius="md"
                          _hover={{ bg: "gray.50" }}
                        >
                          <Box flex="1">
                            <Flex gap={2}>
                              <Box>
                                {renderSpeakerName(group.speaker, groupIndex)}
                              </Box>
                              <Box flex="1" mt={1}>
                                {group.entries.map((entry, entryIndex) => 
                                  getHighlightedAndLineBreakedText(entry, groupIndex, entryIndex)
                                )}
                              </Box>
                            </Flex>
                          </Box>
                          <Button 
                            size="xs" 
                            ml={2} 
                            variant="link"
                            color="blue.500"
                            onClick={() => jumpToTimestamp(group.entries[0].start)}
                          >
                            {formatTime(group.entries[0].start, 'timestamp')}
                          </Button>
                        </Flex>
                      ))
                    )
                  )}
                </Box>
              </Box>
            </VStack>
          </Box>
        )}

        {/* Resizer */}
        {!isLeftExpanded && !isRightExpanded && (
          <Box
            gridColumn="2 / 3"
            bg="gray.100"
            cursor="col-resize"
            onMouseDown={handleDragStart}
            position="relative"
            _hover={{ bg: "blue.100" }}
            transition="background 0.2s"
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width="4px"
              height="32px"
              borderRadius="full"
              bg={isDragging ? "blue.500" : "gray.300"}
              transition="background 0.2s"
            />
          </Box>
        )}

        {/* Right Panel */}
        {!isLeftExpanded && (
          <Box 
            gridColumn={isRightExpanded ? "1 / -1" : "3 / -1"}
            bg="white"
            position="relative"
            transition="width 0.1s ease"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <Box 
              p={4} 
              bg="gray.50"
              borderColor="gray.200"
            >
              <HStack justify="space-between">
                <Heading size="md">Meeting Details</Heading>
                <IconButton
                  icon={isRightExpanded ? <MdFullscreenExit /> : <MdFullscreen />}
                  onClick={() => {
                    setIsRightExpanded(!isRightExpanded);
                    if (!isRightExpanded) {
                      setIsLeftExpanded(false);
                    }
                  }}
                  aria-label="Toggle fullscreen"
                />
              </HStack>
            </Box>

            <Tabs 
              isLazy 
              display="flex" 
              flexDirection="column" 
              flex="1"
              height="calc(100% - 48px)"
              maxHeight="calc(100% - 48px)"
            >
              <TabList px={4} bg="gray.50" borderBottomWidth="1px" borderColor="gray.200">
                <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>Outline</Tab>
                {callData.call.type !== 'email' && (
                  <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>Ask About</Tab>
                )}
                <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>
                  <HStack spacing={2}>
                    <Text>Action Items</Text>
                    {pendingActionItems > 0 && (
                      <Badge 
                        colorScheme="red" 
                        borderRadius="full" 
                        px={2}
                        fontSize="xs"
                        fontWeight="bold"
                        variant="solid"
                      >
                        {pendingActionItems}
                      </Badge>
                    )}
                  </HStack>
                </Tab>
                {callData.call.type !== 'email' && (
                  <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>Key Info</Tab>
                )}
                <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>Follow Up</Tab>
              </TabList>

              <TabPanels 
                flex="1" 
                overflow="hidden"
                height="calc(100% - 48px)"
              >
                {/* Outline Panel */}
                <TabPanel 
                  height="100%" 
                  overflowY="auto"
                  padding={4}
                  display="flex"
                  flexDirection="column"
                >
                  <VStack 
                    align="stretch" 
                    spacing={6}
                    flex="1"
                    minHeight="min-content"
                  >
                    {/* Summary Section */}
                    <Box bg="gray.50" p={6} borderRadius="lg">
                      <HStack justify="space-between" mb={4}>
                        <Heading size="md">Summary</Heading>
                        {isEditingSummary ? (
                          <HStack>
                            <IconButton
                              icon={<CheckIcon />}
                              colorScheme="green"
                              size="sm"
                              onClick={handleSaveSummary}
                              aria-label="Save summary"
                            />
                            <IconButton
                              icon={<CloseIcon />}
                              colorScheme="red"
                              size="sm"
                              onClick={handleCancelEditSummary}
                              aria-label="Cancel edit"
                            />
                          </HStack>
                        ) : (
                          <IconButton
                            icon={<EditIcon />}
                            size="sm"
                            onClick={handleEditSummary}
                            aria-label="Edit summary"
                          />
                        )}
                      </HStack>
                      {isEditingSummary ? (
                        <Textarea
                          value={newSummary}
                          onChange={(e) => setNewSummary(e.target.value)}
                          minHeight="200px"
                          placeholder="Enter summary..."
                        />
                      ) : (
                        <Text whiteSpace="pre-wrap">
                          {callData.call.summary || 'No summary available'}
                        </Text>
                      )}
                    </Box>

                    {/* Topics Section */}
                    {callData.call.topics?.topics?.length > 0 && (
                      <Box bg="white" p={6} borderRadius="lg" borderWidth="1px" borderColor="gray.200">
                        <Heading size="md" mb={4}>Topics</Heading>
                        <VStack align="stretch" spacing={3}>
                          {callData.call.topics.topics.map((topic, index) => {
                            const timestampSeconds = parseTimestamp(topic.timestamp);
                            return (
                              <HStack 
                                key={index} 
                                p={3} 
                                bg="gray.50" 
                                borderRadius="md" 
                                spacing={3}
                                _hover={{ bg: "gray.100" }}
                              >
                                <Button
                                  variant="link"
                                  colorScheme="blue"
                                  fontWeight="medium"
                                  onClick={() => jumpToTimestamp(timestampSeconds)}
                                >
                                  {topic.timestamp}
                                </Button>
                                <Text>{topic.topic}</Text>
                              </HStack>
                            );
                          })}
                        </VStack>
                      </Box>
                    )}

                    {/* Call Details Section */}
                    {callData.call.type !== 'email' && (
                    <Box bg="gray.50" p={6} borderRadius="lg">
                      <Heading size="md" mb={4}>Speaker Statistics</Heading>
                      <VStack align="stretch" spacing={3}>
                        <HStack>
                          <Text fontWeight="medium" color="gray.600" minWidth="120px">Duration:</Text>
                          <Text>{formatTime(transcriptionDuration)}</Text>
                        </HStack>
                        <HStack>
                          <Text fontWeight="medium" color="gray.600" minWidth="120px">Date:</Text>
                          <Text>
                            {extractTimestampFromTitle(callData.call.title, callData.audioUrl)?.toLocaleDateString() || 'Not available'}
                          </Text>
                        </HStack>
                        {Object.entries(speakerStats).map(([speaker, stats]) => (
                          <HStack key={speaker}>
                            <Text fontWeight="medium" color="gray.600" minWidth="120px">{speaker}:</Text>
                            <Text>{stats.percentageTime}% of time, {stats.wpm} WPM</Text>
                          </HStack>
                        ))}
                      </VStack>
                    </Box>
                    )}
                  </VStack>
                </TabPanel>

                {/* Ask About Panel - Only show for non-emails */}
                {callData.call.type !== 'email' && (
                  <TabPanel 
                    height="100%" 
                    overflowY="auto"
                    padding={4}
                    display="flex"
                    flexDirection="column"
                  >
                    <VStack 
                      align="stretch" 
                      spacing={8}
                      flex="1"
                      minHeight="min-content"
                      justify="center"
                      h="100%"
                    >
                      {/* Only show title and questions if there are generated questions */}
                      {callData.call.questions?.questions?.length > 0 && askAboutMessages.length === 0 && (
                        <>
                          <VStack spacing={1} mt={4}>
                            <Text
                              fontSize="xl"
                              fontWeight="bold"
                              color={colors.primary}
                              textAlign="center"
                              lineHeight="1.2"
                            >
                              Ask Questions
                            </Text>
                            <Text
                              fontSize="xl"
                              fontWeight="bold"
                              color={colors.primary}
                              textAlign="center"
                              lineHeight="1.2"
                            >
                              About This Call
                            </Text>
                          </VStack>

                          <VStack align="center" spacing={6} flex="0 0 auto"> 
                            {callData.call.questions.questions.slice(0, 3).map((question, index) => (
                              <Box
                                key={`preset-${index}`}
                                p={6}
                                bg="white"
                                borderRadius="lg"
                                borderWidth="1px"
                                borderColor="gray.200"
                                transition="all 0.2s"
                                cursor="pointer"
                                width="50%"
                                mx="auto" 
                                onClick={() => {
                                  setAskAboutInput(question.question);
                                  handleAskAboutSubmit();
                                }}
                                _hover={{
                                  bg: 'gray.50',
                                  borderColor: 'blue.200',
                                  transform: 'translateY(-1px)',
                                  boxShadow: 'md'
                                }}
                              >
                                <HStack spacing={4}>
                                  <Icon 
                                    as={RiSparklingLine} 
                                    color="blue.500"
                                    boxSize={5}
                                    transform="rotate(-10deg)"
                                    _groupHover={{ transform: "rotate(-10deg) scale(1.1)" }}
                                    transition="transform 0.2s"
                                  />
                                  <Text color="gray.700" fontSize="16px">{question.question}</Text>
                                </HStack>
                              </Box>
                            ))}
                          </VStack>
                        </>
                      )}

                      {/* Chat Messages */}
                      <VStack flex={1} spacing={4} overflowY="auto" w="100%">
                        {askAboutMessages.map((message, index) => (
                          <Box
                            key={`${message.timestamp}-${index}`}
                            bg={message.type === 'user' ? 'blue.50' : 'gray.50'}
                            p={4}
                            borderRadius="md"
                            maxW="90%"
                            alignSelf={message.type === 'user' ? 'flex-end' : 'flex-start'}
                          >
                            <Text fontWeight="bold" mb={2}>
                              {message.type === 'user' ? 'You' : 'DataDasher'}
                            </Text>
                            <Text whiteSpace="pre-wrap">{message.content}</Text>
                          </Box>
                        ))}
                        {streamingAskAboutMessage && (
                          <Box
                            bg="gray.50"
                            p={4}
                            borderRadius="md"
                            maxW="90%"
                            alignSelf="flex-start"
                            key="streaming-message"
                          >
                            <Text fontWeight="bold" mb={2}>DataDasher</Text>
                            <Text whiteSpace="pre-wrap">{streamingAskAboutMessage}</Text>
                          </Box>
                        )}
                        {isAskAboutLoading && !streamingAskAboutMessage && (
                          <Flex justify="flex-start" p={4}>
                            <Spinner size="sm" color="blue.500" />
                          </Flex>
                        )}
                      </VStack>

                      {/* Input Box */}
                      <Box position="sticky" bottom={4} pt={4} pb={2} bg="white" mt="auto">
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleAskAboutSubmit();
                        }}>
                          <InputGroup size="lg">
                            <Input
                              value={askAboutInput}
                              onChange={(e) => setAskAboutInput(e.target.value)}
                              placeholder="Ask anything about this conversation..."
                              pr="4.5rem"
                              bg="white"
                              borderWidth="2px"
                              borderRadius="full"
                              _focus={{
                                borderColor: 'blue.400',
                                boxShadow: 'none'
                              }}
                            />
                            <InputRightElement width="3rem" pr={1}>
                              <IconButton
                                icon={<ArrowUpIcon />}
                                size="sm"
                                colorScheme="blue"
                                borderRadius="full"
                                aria-label="Send question"
                                onClick={handleAskAboutSubmit}
                                isLoading={isAskAboutLoading}
                                _hover={{
                                  transform: 'translateY(-1px)'
                                }}
                              />
                            </InputRightElement>
                          </InputGroup>
                        </form>
                      </Box>
                    </VStack>
                  </TabPanel>
                )}

                  <TabPanel 
                    height="100%" 
                    overflowY="auto"
                    padding={4}
                    display="flex"
                    flexDirection="column"
                  >
                    <VStack 
                      align="stretch" 
                      spacing={6}
                      flex="1"
                      minHeight="min-content"
                    >
                      {/* Action Items Section */}
                      <Box mt={0}>
                        {/* Advisor To-Dos Section */}
                        <Box mb={8}>
                          <Heading size="md" mb={4}>Advisor To-Dos</Heading>
                          {isLoadingActionItems ? (
                            <VStack py={4}>
                              <Spinner color="blue.500" />
                              <Text color="gray.600">Loading action items...</Text>
                            </VStack>
                          ) : actionItems && actionItems.length > 0 ? (
                            actionItems.map((item) => (
                              <Box
                                key={item.id}
                                p={4}
                                borderRadius="md"
                                bg="gray.50"
                                borderWidth="1px"
                                borderColor="gray.200"
                                _hover={{ bg: 'gray.100' }}
                                transition="all 0.2s"
                                mb={3}
                              >
                                <VStack align="stretch" spacing={3}>
                                  <HStack justify="space-between">
                                    <Text fontWeight="medium">{item.description}</Text>
                                    <Badge colorScheme={item.status === 'APPROVED' ? 'green' : 'blue'}>
                                      {item.status || 'PENDING'}
                                    </Badge>
                                  </HStack>
                                  
                                  {item.status !== 'APPROVED' && (
                                    <HStack spacing={4}>
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          size="sm"
                                          variant="outline"
                                          rightIcon={<ChevronDownIcon />}
                                          colorScheme="blue"
                                        >
                                          {assignedUsers[item.id]?.name || 'Assign To'}
                                        </MenuButton>
                                        <MenuList>
                                          {userCrms2?.map((crm) => (
                                            <MenuItem 
                                              key={crm.id}
                                              onClick={() => handleAssign(item.id, {
                                                id: crm.id,
                                                name: crm.name
                                              })}
                                            >
                                              {crm.name}
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </Menu>
                                      
                                      <Input
                                        type="date"
                                        size="sm"
                                        value={editableDates[item.id] || ''}
                                        onChange={(e) => handleDateChange(item.id, e.target.value)}
                                        min={new Date().toISOString().split('T')[0]}
                                        w="auto"
                                      />
                                      
                                      <Button
                                        size="sm"
                                        colorScheme="green"
                                        onClick={() => handleApprove(item)}
                                        isDisabled={!assignedUsers[item.id]}
                                        leftIcon={<CheckIcon />}
                                        isLoading={loadingStates[item.id]}
                                      >
                                        Approve
                                      </Button>
                                      
                                      <IconButton
                                        size="sm"
                                        icon={<CloseIcon />}
                                        colorScheme="red"
                                        variant="ghost"
                                        onClick={() => handleReject(item)}
                                        isLoading={loadingStates[item.id]}
                                      />
                                    </HStack>
                                  )}
                                </VStack>
                              </Box>
                            ))
                          ) : (
                            <VStack py={4} color="gray.600">
                              <Icon as={FaTasks} boxSize={6} />
                              <Text>No advisor to-dos available</Text>
                            </VStack>
                          )}
                        </Box>

                        {/* Client To-Dos Section */}
                        <Box mb={8}>
                          <Heading size="md" mb={4}>Client To-Dos</Heading>
                          {callData?.call?.clientTodos?.length > 0 ? (
                            <VStack align="stretch" spacing={3}>
                              {callData.call.clientTodos.map((todo, idx) => (
                                <Box
                                  key={idx}
                                  p={4}
                                  borderRadius="md"
                                  bg="gray.50"
                                  borderWidth="1px"
                                  borderColor="gray.200"
                                  _hover={{ bg: 'gray.100' }}
                                  transition="all 0.2s"
                                >
                                  <HStack justify="space-between">
                                    <Text color="gray.700">{todo}</Text>
                                    <Badge colorScheme="blue">Pending</Badge>
                                  </HStack>
                                </Box>
                              ))}
                            </VStack>
                          ) : (
                            <VStack py={4} color="gray.600">
                              <Icon as={FaTasks} boxSize={6} />
                              <Text>No client to-dos available</Text>
                            </VStack>
                          )}
                        </Box>

                        {/* AI Recommendations Section */}
                        {aiInsights.length > 0 && (
                          <Box>
                            <Heading size="md" mb={4}>Recommendations</Heading>
                            <VStack align="stretch" spacing={6}>
                              {aiInsights.map((insight) => (
                                <Box 
                                  key={insight.id}
                                  p={6}
                                  bg="white"
                                  borderRadius="lg"
                                  borderWidth="1px"
                                  borderColor="blue.200"
                                  boxShadow="sm"
                                >
                                  <VStack align="stretch" spacing={4}>
                                    <Text fontSize="lg" fontWeight="bold" color="gray.800">
                                      {formatCategoryName(insight.category)} | {insight.subcategory}
                                    </Text>
                                    
                                    <Text fontSize="md" color="gray.700" lineHeight="tall">
                                      {insight.insight}
                                    </Text>

                                    {/* Linked Action Items */}
                                    {insight.actionItems?.length > 0 && (
                                      <Box mt={4} pt={4} borderTopWidth="1px" borderColor="gray.200">
                                        <Text fontSize="sm" fontWeight="semibold" color="gray.600" mb={3}>
                                          Recommended Actions:
                                        </Text>
                                        <VStack align="stretch" spacing={3}>
                                          {insight.actionItems.map((action, idx) => (
                                            <Box
                                              key={idx}
                                              p={3}
                                              bg="blue.50"
                                              borderRadius="md"
                                              borderWidth="1px"
                                              borderColor="blue.200"
                                              _hover={{ bg: 'blue.100' }}
                                              transition="background 0.2s"
                                            >
                                              <Text fontSize="sm" color="gray.700">
                                                {action.description}
                                              </Text>
                                              {action.rationale && (
                                                <Text fontSize="xs" color="gray.500" mt={1}>
                                                  {action.rationale}
                                                </Text>
                                              )}
                                            </Box>
                                          ))}
                                        </VStack>
                                      </Box>
                                    )}
                                  </VStack>
                                </Box>
                              ))}
                            </VStack>
                          </Box>
                        )}
                      </Box>

                      {/* AI Insights Section - Only show when beta features are enabled */}
                      {callData?.call?.user?.betaFeaturesEnabled && (
                        <>
                          {/* Meeting AI Insights */}
                          <Box mt={8}>
                            <Heading size="md" mb={4} color="blue.700">Meeting AI Insights</Heading>
                            {isLoadingInsights ? (
                              <VStack py={4}>
                                <Spinner color="blue.500" />
                                <Text color="gray.600">Loading AI insights...</Text>
                              </VStack>
                            ) : aiInsights.length > 0 ? (
                              <VStack align="stretch" spacing={6}>
                                {aiInsights.map((insight) => (
                                  <Box 
                                    key={insight.id}
                                    p={6}
                                    bg="white"
                                    borderRadius="lg"
                                    borderWidth="1px"
                                    borderColor="blue.200"
                                    boxShadow="sm"
                                  >
                                    <VStack align="stretch" spacing={4}>
                                      <Text fontSize="lg" fontWeight="bold" color="gray.800">
                                        {formatCategoryName(insight.category)} | {insight.subcategory}
                                      </Text>
                                      
                                      <Text fontSize="md" color="gray.700" lineHeight="tall">
                                        {insight.insight}
                                      </Text>

                                      {/* Linked Action Items */}
                                      {insight.actionItems?.length > 0 && (
                                        <Box mt={4} pt={4} borderTopWidth="1px" borderColor="gray.200">
                                          <Text fontSize="sm" fontWeight="semibold" color="gray.600" mb={3}>
                                            Related Action Items:
                                          </Text>
                                          <VStack align="stretch" spacing={3}>
                                            {insight.actionItems.map((action, idx) => (
                                              <Box
                                                key={idx}
                                                p={3}
                                                bg="white"
                                                borderRadius="md"
                                                borderWidth="1px"
                                                borderColor="blue.200"
                                                _hover={{ bg: 'blue.100' }}
                                                transition="background 0.2s"
                                              >
                                                <Text fontSize="sm" color="gray.700">
                                                  {action.description}
                                                </Text>
                                                {action.rationale && (
                                                  <Text fontSize="xs" color="gray.500" mt={1}>
                                                    {action.rationale}
                                                  </Text>
                                                )}
                                              </Box>
                                            ))}
                                          </VStack>
                                        </Box>
                                      )}
                                    </VStack>
                                  </Box>
                                ))}
                              </VStack>
                            ) : (
                              <Text color="gray.600">No meeting-specific AI insights available</Text>
                            )}
                          </Box>

                          {/* Aggregate AI Insights */}
                          <Box mt={8}>
                            <Heading size="md" mb={4} color="purple.700">Aggregate AI Insights</Heading>
                            {isLoadingInsights ? (
                              <VStack py={4}>
                                <Spinner color="purple.500" />
                                <Text color="gray.600">Loading aggregate insights...</Text>
                              </VStack>
                            ) : aggregateInsights.length > 0 ? (
                              <VStack align="stretch" spacing={6}>
                                {aggregateInsights.map((insight) => (
                                  <Box 
                                    key={insight.id}
                                    p={6}
                                    bg="white"
                                    borderRadius="lg"
                                    borderWidth="1px"
                                    borderColor="purple.200"
                                    boxShadow="sm"
                                  >
                                    <VStack align="stretch" spacing={4}>
                                      <Text fontSize="lg" fontWeight="bold" color="purple.700">
                                        {formatCategoryName(insight.category)} | {insight.subcategory}
                                      </Text>
                                      
                                      <Text fontSize="md" color="gray.700" lineHeight="tall">
                                        {insight.insight}
                                      </Text>

                                      {/* Linked Action Items */}
                                      {insight.actionItems?.length > 0 && (
                                        <Box mt={4} pt={4} borderTopWidth="1px" borderColor="gray.200">
                                          <Text fontSize="sm" fontWeight="semibold" color="gray.600" mb={3}>
                                            Related Action Items:
                                          </Text>
                                          <VStack align="stretch" spacing={3}>
                                            {insight.actionItems.map((action, idx) => (
                                              <Box
                                                key={idx}
                                                p={3}
                                                bg="white"
                                                borderRadius="md"
                                                borderWidth="1px"
                                                borderColor="purple.200"
                                                _hover={{ bg: 'purple.100' }}
                                                transition="background 0.2s"
                                              >
                                                <Text fontSize="sm" color="gray.700">
                                                  {action.description}
                                                </Text>
                                                {action.rationale && (
                                                  <Text fontSize="xs" color="gray.500" mt={1}>
                                                    {action.rationale}
                                                  </Text>
                                                )}
                                              </Box>
                                            ))}
                                          </VStack>
                                        </Box>
                                      )}
                                    </VStack>
                                  </Box>
                                ))}
                              </VStack>
                            ) : (
                              <Text color="gray.600">No aggregate AI insights available</Text>
                            )}
                          </Box>
                        </>
                      )}
                    </VStack>
                  </TabPanel>

                {/* Key Info Panel - Only show for non-emails */}
                {callData.call.type !== 'email' && (
                  <TabPanel 
                    height="100%" 
                    overflowY="auto"
                    padding={4}
                    display="flex"
                    flexDirection="column"
                  >
                    <VStack 
                      align="stretch" 
                      spacing={8}
                      flex="1"
                      minHeight="min-content"
                    >
                      {/* Q&A Section */}
                      <Box bg="gray.50" p={6} borderRadius="lg">
                        <Heading size="md" mb={4}>Q&A Highlights</Heading>
                        <VStack align="stretch" spacing={4}>
                            {callData.call.transcriptQA?.questions?.length > 0 ? (
                                callData.call.transcriptQA.questions.map((question, idx) => (
                                    <Box 
                                        key={idx} 
                                        bg="white" 
                                        p={4} 
                                        borderRadius="md"
                                        borderWidth="1px"
                                        borderColor="gray.200"
                                    >
                                        <Text fontWeight="bold" color="blue.600">Q: {question}</Text>
                                        <Text mt={2}>A: {callData.call.transcriptQA.answers[idx]}</Text>
                                    </Box>
                                ))
                            ) : (
                                <VStack py={4} color="gray.600">
                                    <Icon as={FaLightbulb} boxSize={6} />
                                    <Text>No direct questions and answers were found in this conversation</Text>
                                </VStack>
                            )}
                        </VStack>
                      </Box>

                      {/* Goals & Values Section */}
                      {keyInfo?.goalsValues?.length > 0 && (
                        <Box bg="purple.50" p={6} borderRadius="lg" borderWidth="1px" borderColor="purple.100">
                          <Heading size="md" mb={4} color="purple.700">Goals & Values</Heading>
                          <VStack align="stretch" spacing={3}>
                            {keyInfo.goalsValues.map((goal, idx) => (
                              <Box 
                                key={idx}
                                p={4}
                                bg="white"
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="purple.100"
                              >
                                <Text fontWeight="medium" color="purple.700">{goal.goal}</Text>
                                {(goal.amount || goal.deadline) && (
                                  <HStack mt={2} color="gray.600" fontSize="sm">
                                    {goal.amount && <Text>${goal.amount.toLocaleString()}</Text>}
                                    {goal.deadline && <Text>by {goal.deadline}</Text>}
                                  </HStack>
                                )}
                                {goal.notes && (
                                  <Text mt={2} color="gray.600" fontSize="sm">{goal.notes}</Text>
                                )}
                              </Box>
                            ))}
                          </VStack>
                        </Box>
                      )}

                      {/* Life Events Section */}
                      {keyInfo?.lifeEvents?.length > 0 && (
                        <Box bg="blue.50" p={6} borderRadius="lg" borderWidth="1px" borderColor="blue.100">
                          <Heading size="md" mb={4} color="blue.700">Life Events</Heading>
                          <VStack align="stretch" spacing={3}>
                            {keyInfo.lifeEvents.map((event, idx) => (
                              <Box 
                                key={idx}
                                p={4}
                                bg="white"
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="blue.100"
                              >
                                <Text fontWeight="medium" color="blue.700">{event.event}</Text>
                                {event.date && (
                                  <Text mt={1} color="gray.600" fontSize="sm">{event.date}</Text>
                                )}
                                {event.details && (
                                  <Text mt={2} color="gray.600">{event.details}</Text>
                                )}
                              </Box>
                            ))}
                          </VStack>
                        </Box>
                      )}

                      {/* Family Information Section */}
                      {keyInfo?.family?.length > 0 && (
                        <Box bg="green.50" p={6} borderRadius="lg" borderWidth="1px" borderColor="green.100">
                          <Heading size="md" mb={4} color="green.700">Family Information</Heading>
                          <VStack align="stretch" spacing={4}>
                            {keyInfo.family.map((member, idx) => (
                              <Box 
                                key={idx}
                                p={4}
                                bg="white"
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="green.100"
                              >
                                <HStack justify="space-between">
                                  <VStack align="start" spacing={1}>
                                    <Text fontWeight="medium" color="green.700">
                                      {member.name}
                                      {member.nickname && ` (prefers ${member.nickname})`}
                                    </Text>
                                    <HStack spacing={2} color="gray.600" fontSize="sm">
                                      <Text>{member.relationship}</Text>
                                      {member.age && <Text>• {member.age} years old</Text>}
                                      {member.occupation && <Text>• {member.occupation}</Text>}
                                    </HStack>
                                  </VStack>
                                </HStack>
                                {member.notes && (
                                  <Text mt={2} color="gray.600" fontSize="sm">{member.notes}</Text>
                                )}
                              </Box>
                            ))}
                          </VStack>
                        </Box>
                      )}

                      {/* Financial Data Section */}
                      {keyInfo?.financialData && Object.keys(keyInfo.financialData).length > 0 && (
                        <Box bg="blue.50" p={6} borderRadius="lg" borderWidth="1px" borderColor="blue.100">
                          <Heading size="md" mb={4} color="blue.700">Financial Data</Heading>
                          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                            {keyInfo.financialData.netWorth && (
                              <HStack 
                                p={4} 
                                bg="white" 
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="blue.100"
                              >
                                <Text fontWeight="medium" color="gray.600">Net Worth:</Text>
                                <Text fontWeight="bold" color="blue.600">
                                  ${keyInfo.financialData.netWorth.toLocaleString()}
                                </Text>
                              </HStack>
                            )}
                            {keyInfo.financialData.liquidNetWorth && (
                              <HStack 
                                p={4} 
                                bg="white" 
                                borderRadius="md"
                                borderWidth="1px"
                                borderColor="blue.100"
                              >
                                <Text fontWeight="medium" color="gray.600">Liquid Net Worth:</Text>
                                <Text fontWeight="bold" color="blue.600">
                                  ${keyInfo.financialData.liquidNetWorth.toLocaleString()}
                                </Text>
                              </HStack>
                            )}
                          </SimpleGrid>

                          {keyInfo.financialData.investmentAccounts?.length > 0 && (
                            <VStack align="stretch" mt={4} spacing={3}>
                              <Text fontWeight="medium" color="gray.700">Investment Accounts</Text>
                              {keyInfo.financialData.investmentAccounts.map((account, idx) => (
                                <Box
                                  key={idx}
                                  p={4}
                                  bg="white"
                                  borderRadius="md"
                                  borderWidth="1px"
                                  borderColor="blue.100"
                                >
                                  <HStack justify="space-between">
                                    <Text color="gray.700">{account.type}</Text>
                                    <Text fontWeight="medium" color="blue.600">
                                      ${account.balance.toLocaleString()}
                                    </Text>
                                  </HStack>
                                  {account.institution && (
                                    <Text fontSize="sm" color="gray.500" mt={1}>
                                      at {account.institution}
                                    </Text>
                                  )}
                                </Box>
                              ))}
                            </VStack>
                          )}

                          {keyInfo.financialData.notes && (
                            <Text mt={4} color="gray.600">{keyInfo.financialData.notes}</Text>
                          )}
                        </Box>
                      )}
                    </VStack>
                  </TabPanel>
                )}

                {/* Follow Up Panel */}
                <TabPanel>
                  <FollowUpEmail
                    callData={callData}
                    summary={newSummary}
                    clientTodos={MOCK_DATA.clientTodos}
                    advisorTodos={MOCK_DATA.advisorTodos}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Grid>

      {callData.call.type !== 'email' && (
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          bg={colors.background}
          color={colors.primary}
          p={4}
          boxShadow="0 -2px 10px rgba(0, 0, 0, 0.1)"
          zIndex={999}
        >
          <Flex align="center" justify="space-between">
            <HStack spacing={4}>
              <IconButton
                icon={isPlaying ? <FaPause /> : <FaPlay />}
                onClick={handlePlayPause}
                aria-label={isPlaying ? "Pause" : "Play"}
                colorScheme="blue"
                variant="ghost"
                size="lg"
              />
              <IconButton
                icon={<ChevronLeftIcon />}
                onClick={() => handleSkip(-10)}
                aria-label="Rewind 10 seconds"
                variant="ghost"
              />
              <IconButton
                icon={<ChevronRightIcon />}
                onClick={() => handleSkip(10)}
                aria-label="Forward 10 seconds"
                variant="ghost"
              />
              <IconButton
                icon={<RepeatIcon />}
                onClick={() => {
                  if (audioRef.current) {
                    audioRef.current.currentTime = 0;
                    audioRef.current.play();
                  }
                }}
                aria-label="Restart"
                variant="ghost"
              />
            </HStack>
            <Progress
              value={progress}
              flex={1}
              size="sm"
              colorScheme="blue"
              onClick={handleSeekClick}
              sx={{ cursor: 'pointer', mx: 4 }}
              borderRadius="md"
            />
            <HStack spacing={4}>
              <Text fontSize="sm">
                {formatTime(currentTime, 'timestamp')} / {formatTime(
                  isFinite(duration) ? duration : transcriptionDuration, 
                  'timestamp'
                )}
              </Text>
            </HStack>
          </Flex>
          <audio
            ref={audioRef}
            src={callData.audioUrl}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={() => {
              if (audioRef.current) {
                setDuration(audioRef.current.duration);
              }
            }}
            onEnded={() => setIsPlaying(false)}
            style={{ display: 'none' }}
          />
        </Box>
      )}

      {/* Follow-up Modal */}
      <Modal 
        isOpen={isFollowUpModalOpen} 
        onClose={() => setIsFollowUpModalOpen(false)}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader>
            <HStack justify="space-between">
              <Text>Generate Follow-up Email</Text>
              <Button
                leftIcon={<EmailIcon />}
                colorScheme="blue"
                size="sm"
              >
                Send Email
              </Button>
            </HStack>
          </ModalHeader>
          <ModalBody overflowY="auto" pb={6}>
            <VStack align="stretch" spacing={6}>
              <FormControl>
                <FormLabel>Subject</FormLabel>
                <Input defaultValue={`Follow-up: ${callData.call.title}`} />
              </FormControl>

              <FormControl>
                <FormLabel>Email Body</FormLabel>
                <Textarea
                  minHeight="400px"
                  defaultValue={`Dear ${callData.call.clients?.[0]?.firstName || '[Client Name]'},

Thank you for our meeting today. Here's a summary of what we discussed:

Summary:
${newSummary}

Key Action Items:
For You:
${(callData.call.clientTodos || []).map(todo => `- ${todo}`).join('\n')}

For Us:
${(callData.call.advisorTodos || []).map(todo => `- ${todo.description}`).join('\n')}

Next Steps:
- Schedule follow-up meeting
- Review and sign updated documents
- Complete assigned action items

Please let me know if you have any questions or need clarification on any of these items.

Best regards,
[Advisor Name]`}
                />
              </FormControl>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Tasks Modal */}
      <Modal 
        isOpen={isTasksModalOpen} 
        onClose={() => setIsTasksModalOpen(false)}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent maxH="90vh">
          <ModalHeader>
            <HStack justify="space-between">
              <Text>Meeting Tasks</Text>
              <Button
                leftIcon={<FaTasks />}
                colorScheme="green"
                size="sm"
                onClick={() => setIsTasksModalOpen(false)}
              >
                Close
              </Button>
            </HStack>
          </ModalHeader>
          <ModalBody overflowY="auto" pb={6}>
            <VStack align="stretch" spacing={6}>
              {/* Advisor Tasks */}
              <Box>
                <Heading size="md" mb={4}>Advisor Tasks</Heading>
                <VStack align="stretch" spacing={3}>
                  {isLoadingActionItems ? (
                    <VStack py={4}>
                      <Spinner color="blue.500" />
                      <Text color="gray.600">Loading action items...</Text>
                    </VStack>
                  ) : actionItems && actionItems.length > 0 ? (
                    actionItems.map((item) => (
                      <Box
                        key={item.id}
                        p={4}
                        borderRadius="md"
                        bg="gray.50"
                        borderWidth="1px"
                        borderColor="gray.200"
                        _hover={{ bg: 'gray.100' }}
                        transition="all 0.2s"
                      >
                        <VStack align="stretch" spacing={3}>
                          <HStack justify="space-between">
                            <Text fontWeight="medium">{item.description}</Text>
                            <Badge colorScheme={item.status === 'APPROVED' ? 'green' : 'blue'}>
                              {item.status || 'PENDING'}
                            </Badge>
                          </HStack>
                          
                          {item.status !== 'APPROVED' && (
                            <HStack spacing={4}>
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  size="sm"
                                  variant="outline"
                                  rightIcon={<ChevronDownIcon />}
                                  colorScheme="blue"
                                >
                                  {assignedUsers[item.id]?.name || 'Assign To'}
                                </MenuButton>
                                <MenuList>
                                  {userCrms2?.map((crm) => (
                                    <MenuItem 
                                      key={crm.id}
                                      onClick={() => handleAssign(item.id, {
                                        id: crm.id,
                                        name: crm.name
                                      })}
                                    >
                                      {crm.name}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Menu>
                              
                              <Input
                                type="date"
                                size="sm"
                                value={editableDates[item.id] || ''}
                                onChange={(e) => handleDateChange(item.id, e.target.value)}
                                min={new Date().toISOString().split('T')[0]}
                                w="auto"
                              />
                              
                              <Button
                                size="sm"
                                colorScheme="green"
                                onClick={() => handleApprove(item)}
                                isDisabled={!assignedUsers[item.id]}
                                leftIcon={<CheckIcon />}
                                isLoading={loadingStates[item.id]}
                              >
                                Approve
                              </Button>
                              
                              <IconButton
                                size="sm"
                                icon={<CloseIcon />}
                                colorScheme="red"
                                variant="ghost"
                                onClick={() => handleReject(item)}
                                isLoading={loadingStates[item.id]}
                              />
                            </HStack>
                          )}
                        </VStack>
                      </Box>
                    ))
                  ) : (
                    <VStack py={4} color="gray.600">
                      <Icon as={FaTasks} boxSize={6} />
                      <Text>No action items available</Text>
                    </VStack>
                  )}
                </VStack>
              </Box>

              {/* Client Tasks */}
              <Box>
                <Heading size="md" mb={4}>Client To-Dos</Heading>
                {callData.call.clientTodos?.length > 0 ? (
                  <VStack align="stretch" spacing={2} pl={4}>
                    {callData.call.clientTodos.map((todo, idx) => (
                      <HStack key={idx} spacing={3} align="start">
                        <Text as="span" color="green.700" mt={1}>•</Text>
                        <VStack align="start" spacing={1}>
                          <Text>{todo}</Text>
                          <Text fontSize="sm" color="gray.600">Status: Pending</Text>
                        </VStack>
                      </HStack>
                    ))}
                  </VStack>
                ) : (
                  <VStack py={4} color="gray.600">
                    <Icon as={FaTasks} boxSize={6} />
                    <Text>No client to-dos from this call</Text>
                  </VStack>
                )}
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Client Assignment Modal */}
      <Modal
        isOpen={isClientAssignModalOpen}
        onClose={() => setIsClientAssignModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Client to Call</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack align="stretch" spacing={4}>
              {clients.map(client => (
                <Button
                  key={client.id}
                  onClick={() => {
                    setClientName(formatPersonName(client));
                    setIsClientAssignModalOpen(false);
                  }}
                  variant="outline"
                  justifyContent="start"
                >
                  {formatPersonName(client)}
                </Button>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

// Helper function to determine grid template
const getGridTemplate = (isLeftExpanded, isRightExpanded) => {
  if (isLeftExpanded || isRightExpanded) return "1fr";
  return "repeat(2, 1fr)";
};

export default Correspondence;