import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Select,
    Text,
    Center,
    VStack,
    HStack,
    Spinner,
    Alert,
    AlertIcon,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Heading,
} from '@chakra-ui/react';
import { FaUser, FaSave, FaEdit, FaInfo, FaCircle } from 'react-icons/fa';
import { continuousVisualizer } from 'sound-visualizer'; // Import the continuous visualizer
import { useToast } from '@chakra-ui/react';
import { useOrganizationClients } from '../../../contexts/OrganizationClientsContext';
import { FeatureStats } from '../../../enums/UsageStats';
import Cookies from 'js-cookie';
const serverUrl = process.env.REACT_APP_API_URL;

// Mock people data for "Identify Client" selection
const mockPeople = [
    { id: 1, name: 'Alice Johnson' },
    { id: 2, name: 'Bob Smith' },
    { id: 3, name: 'Charlie Davis' },
    // Add more mock people as needed
];

function LiveMeeting() {
    const toast = useToast();
    const [permission, setPermission] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false); // Pause functionality
    const [timer, setTimer] = useState('00:00:00');
    const [error, setError] = useState('');
    const [isDoneClicked, setIsDoneClicked] = useState(false);
    const [meetingName, setMeetingName] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [showQuitModal, setShowQuitModal] = useState(false);
    const [showDoneModal, setShowDoneModal] = useState(false); // State for Done Modal
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false); // State for Confirm Cancel Modal
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const audioChunksRef = useRef([]);
    const canvasRef = useRef(null); 
    const visualizerRef = useRef(null); 
    const audioStreamRef = useRef(null); 
    const [audioBlob, setAudioBlob] = useState(null);
    const { clients } = useOrganizationClients();
    const [selectedClientId, setSelectedClientId] = useState(null);

    useEffect(() => {
        // Function to request microphone permissions and initialize audio stream
        const initializeAudioStream = async () => {
            try {
                const constraints = { audio: true };
                if (selectedDevice) {
                    constraints.audio = {
                        deviceId: selectedDevice
                    };
                }
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                console.log('Microphone access granted');
                setPermission(true);
                audioStreamRef.current = stream; // Store the audio stream

                // Enumerate audio input devices
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
                setDevices(audioDevices);
                if (audioDevices.length > 0 && !selectedDevice) {
                    setSelectedDevice(audioDevices[0].deviceId);
                }

                // Initialize and start the visualizer immediately
                if (canvasRef.current) {
                    visualizerRef.current = continuousVisualizer(stream, canvasRef.current, {
                        strokeColor: '#00417D',
                        lineWidth: 2,
                        slices: 80,
                    });
                    visualizerRef.current.start(); // Start the visualizer immediately
                    console.log('Visualizer initialized and started');
                }
            } catch (err) {
                console.error('Microphone permission denied or error:', err);
                setPermission(false);
                setError('Microphone permission is required to use this feature. Allow microphone access in your browser, then refresh this page.');
            }
        };

        initializeAudioStream();

        // Cleanup function when component unmounts
        return () => {
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach(track => track.stop());
            }
            if (visualizerRef.current) {
                visualizerRef.current.stop();
            }
        };
    }, [selectedDevice]); // Re-run if selectedDevice changes

    useEffect(() => {
        let interval = null;
        if (recording && !paused) { // Consider paused state
            interval = setInterval(() => {
                setTimer(prev => {
                    const [hours, minutes, seconds] = prev.split(':').map(Number);
                    const totalSeconds = hours * 3600 + minutes * 60 + seconds + 1;
                    const newHours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
                    const newMinutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
                    const newSeconds = String(totalSeconds % 60).padStart(2, '0');
                    return `${newHours}:${newMinutes}:${newSeconds}`;
                });
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording, paused]); // Include paused in dependencies

    const startRecording = async () => {
        // Log usage first
        await axios.post(`${serverUrl}/api/usage/log`, {
            statPath: FeatureStats.MEETINGS_WEB_APP,
            value: 1
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtToken')}`
            }
        });

        if (audioStreamRef.current) {
            const mediaRecorder = new MediaRecorder(audioStreamRef.current);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                // Do not upload immediately; wait for "Save" action
                setAudioBlob(audioBlob); // Store the audioBlob in state
                console.log('Recording stopped. Audio blob created.');
            };

            mediaRecorder.start();
            setRecording(true);
            setPaused(false);
            setTimer('00:00:00');
            console.log('MediaRecorder started.');

            // Start the visualizer
            if (visualizerRef.current) {
                visualizerRef.current.start();
                console.log('Visualizer started');
            }
        } else {
            console.error('Audio stream is not available.');
            setError('Audio stream is not available. Please check your microphone settings.');
        }
    };

    const pauseRecording = () => { 
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            setPaused(true);
            // Removed stopping the visualizer to keep it active
            // if (visualizerRef.current) {
            //     visualizerRef.current.stop();
            // }
        }
    };

    const resumeRecording = () => { 
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.resume();
            setPaused(false);
            // Removed starting the visualizer to prevent it from being cut off
            // if (visualizerRef.current) {
            //     visualizerRef.current.start();
            // }
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            console.log('MediaRecorder stopped.');
        }
        setRecording(false);
        setPaused(false); // Reset paused state
        // Stop the visualizer
        // if (visualizerRef.current) {
        //     visualizerRef.current.stop();
        //     console.log('Visualizer stopped');
        // }
        // Open Done Modal instead of setting isDoneClicked
        setShowDoneModal(true);
    };

    const uploadAudio = async (audioBlob) => {
        try {
            // Log web app meeting usage first
            await axios.post(`${serverUrl}/api/usage/log`, {
                statPath: FeatureStats.MEETINGS_WEB_APP,
                value: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                }
            });

            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.mp3');
            formData.append('meetingName', meetingName || `Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`);
            formData.append('selectedClientId', selectedClientId); 
            formData.append('clientName', selectedClient);
            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            toast({
                title: 'Uploading...',
                description: 'Your meeting has been uploaded and will be processed shortly. Please check the home page after a few minutes.',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });

            const response = await axios.post(`${serverUrl}/api/upload-audio`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Audio uploaded successfully:', response.data);

            // toast({
            //     title: 'Upload Successful',
            //     description: 'Your meeting has been successfully recorded. It will take a few minutes to process. Please check the home page.',
            //     status: 'success',
            //     duration: 5000,
            //     isClosable: true,
            // });


        } catch (err) {
            console.error('Error uploading audio:', err);
            setError('Failed to upload audio.');
            toast({
                title: 'Upload Failed',
                description: 'There was an error processing your meeting. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleSave = () => {
        // Handle saving meeting details
        setShowDoneModal(false);

        // Check if audioBlob is available
        if (audioBlob) {
            uploadAudio(audioBlob);
        } else {
            console.error('No audio blob available to upload.');
            setError('No audio recording found.');
        }

    };

    const handleCancel = () => {
        setShowQuitModal(true);
    };

    const quitRecording = () => {
        setShowQuitModal(false);
        // Stop the media recorder if it's recording
        if (mediaRecorderRef.current && recording) {
            mediaRecorderRef.current.stop();
            console.log('MediaRecorder stopped.');
        }
        // Stop the visualizer
        // if (visualizerRef.current) {
        //     visualizerRef.current.stop();
        //     console.log('Visualizer stopped');
        // }
        // Reset state variables to initial values
        setRecording(false);
        setPaused(false);
        setIsDoneClicked(false);
        setTimer('00:00:00');
        setMeetingName('');
        setSelectedClient('');
        setError('');
    };

    const keepRecording = () => {
        setShowQuitModal(false);
    };

    const resetRecordingState = () => {
        setIsDoneClicked(false);
        setMeetingName('');
        setSelectedClient('');
        setTimer('00:00:00');
        audioChunksRef.current = [];
        // Reset visualizer if exists
        if (visualizerRef.current) {
            visualizerRef.current.reset();
            visualizerRef.current = null;
            console.log('Visualizer reset during state reset.');
        }
    };

    const handleMeetingNameChange = (e) => {
        setMeetingName(e.target.value);
    };

    const handleClientChange = (e) => {
        const value = e.target.value;
        setSelectedClientId(value === 'untitled' ? undefined : value);

        const client = clients.find(client => client.id === value);
        setSelectedClient(client ? `${client.firstName} ${client.lastName}` : '');
    };

    const handleDoneModalClose = () => {
        // Instead of closing the modal directly, open the confirm cancel modal
        setShowDoneModal(false);
        setShowConfirmCancelModal(true);
    };

    const handleKeepRecording = () => {
        // Close the confirm cancel modal and reopen the Done modal
        setShowConfirmCancelModal(false);
        setShowDoneModal(true);
    };

    const handleQuitRecording = () => {
        // Close all modals and reset states as intended previously
        setShowConfirmCancelModal(false);
        setShowDoneModal(false);
        // Reset states
        setRecording(false);
        setPaused(false);
        setIsDoneClicked(false);
        setTimer('00:00:00');
        setMeetingName('');
        setSelectedClient('');
        setError('');
        // Optionally, navigate away or reset the component
    };

    return (
        <Box bg="white" p={8} overflowY="auto" height="95vh">
            <Heading color="#00417D" pb={4}>Live Meeting</Heading>
            <Box flex="1" display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={40}>
                {error && (
                    <Alert status="error" mb={4}>
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                <Center>
                    <Text fontSize="8xl">{timer}</Text>
                </Center>
                
                {/* Audio Visualizer */}
                <Box mt={4}>
                    <canvas
                        ref={canvasRef}
                        width={600}
                        height={100}
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            opacity: recording ? (paused ? 0.05 : 1) : 0, // Set opacity to 0 when not recording
                            transition: 'opacity 0.1s ease'
                        }}
                    ></canvas>
                </Box>

                {permission && (
                    <VStack spacing={4} mt={8}>
                        <Select
                            value={selectedDevice}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                            width="300px"
                            placeholder="Select Microphone"
                        >
                            {devices.map(device => (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label || `Microphone ${device.deviceId}`}
                                </option>
                            ))}
                        </Select>
                        <Text fontSize="lg" fontWeight="bold">    
                            {recording ? (paused ? 'Paused' : 'Recording') : 'Not Recording'}
                        </Text> 
                        <Center>
                            {!recording && !isDoneClicked && (
                                <Button
                                    bg="#00417D"
                                    color="white"
                                    size="lg"
                                    borderRadius="full"
                                    onClick={startRecording} // Start Recording on button click
                                    leftIcon={<FaCircle />}
                                >
                                    Record
                                </Button>
                            )}
                            {recording && !isDoneClicked && (
                                <HStack spacing={4}>
                                    {!paused ? (
                                        <Button bg="#00417D" color="white" onClick={pauseRecording}>
                                            Pause
                                        </Button>
                                    ) : (
                                        <Button bg="#00417D" color="white" onClick={resumeRecording}>
                                            Resume
                                        </Button>
                                    )}
                                    <Button bg="#00417D" color="white" onClick={stopRecording}>
                                        Done
                                    </Button>
                                </HStack>
                            )}
                        </Center>
                    </VStack>
                )}
                {permission === null && (
                    <Center mt={8}>
                        <Spinner size="xl" />
                        <Text ml={4}>Requesting microphone permissions...</Text>
                    </Center>
                )}

                {/* Quit Recording Modal */}
                <Modal isOpen={showQuitModal} onClose={keepRecording}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Quit Recording</ModalHeader>
                        <ModalBody>
                            <Text>This recording will be deleted.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={keepRecording}>
                                Keep Recording
                            </Button>
                            <Button colorScheme="red" onClick={quitRecording}>
                                Quit Recording
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Done Modal */}
                <Modal isOpen={showDoneModal} onClose={handleDoneModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Save Recording</ModalHeader>
                        <ModalBody>
                            <VStack spacing={4} width="100%" mt={4}>
                                <Text fontSize="lg">Give your meeting a name:</Text>
                                <Input
                                    placeholder={`Meeting ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
                                    value={meetingName}
                                    onChange={handleMeetingNameChange}
                                    width="300px"
                                />
                                <Text fontSize="lg">Identify Client:</Text>
                                <Select
                                    value={selectedClientId || 'untitled'}
                                    onChange={handleClientChange}
                                    width="300px"
                                    placeholder="Untitled Client"
                                >
                                    {clients.map(client => (
                                        <option key={client.id} value={client.id}>
                                            {client.firstName} {client.metadata?.preferredName ? `(${client.metadata?.preferredName})` : ''} {client.lastName}
                                        </option>
                                    ))}
                                </Select>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={handleDoneModalClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="blue" onClick={handleSave}>
                                Save
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Confirm Cancel Modal */}
                <Modal isOpen={showConfirmCancelModal} onClose={handleKeepRecording}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Quit Recording</ModalHeader>
                        <ModalBody>
                            <Text>This recording will be deleted.</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="ghost" mr={3} onClick={handleKeepRecording}>
                                Keep Recording
                            </Button>
                            <Button colorScheme="red" onClick={handleQuitRecording}>
                                Quit Recording
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </Box>
    );
}

export default LiveMeeting;
